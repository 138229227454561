import React from 'react';
import {
  Account, AccountCardDetails, AccountGroup, AccountKey, AccountQuestion, Bell,
  CalendarMultipleCheck, Car, CarConnected, Cash, ClipboardArrowLeft, Cogs, CommentTextMultiple,
  Database, FileSearch, FileTable, Forum, Gamepad, Link as IconLink, NewspaperVariant,
  PackageVariant, TicketPercent, NotebookMultiple, CalendarClock, Hospital, ViewDashboard,
  CommentQuestion, BulletinBoard,
} from 'mdi-material-ui';
import {
  ROUTE_NAME_DASHBOARD,
  ROUTE_NAME_AUTHENTICATION, ROUTE_NAME_SUMMARY, ROUTE_NAME_SERVICE, ROUTE_NAME_SCOPE,
  ROUTE_NAME_API_KEY, ROUTE_NAME_TOKEN, ROUTE_NAME_PERMISSION, ROUTE_NAME_ROLE, ROUTE_NAME_USER,
  ROUTE_NAME_PERSONALIZATION, ROUTE_NAME_APPLICATION, ROUTE_NAME_BANNER, ROUTE_NAME_PROFILE,
  ROUTE_NAME_MASTER_DATA, ROUTE_NAME_PROVINCE, ROUTE_NAME_CITY, ROUTE_NAME_COMPANY,
  ROUTE_NAME_BRANCH, ROUTE_NAME_DISTRICT, ROUTE_NAME_SUBDISTRICT,
  MENUID_ROOT, MENUID_DASHBOARD, MENUID_AUTHENTICATION,
  MENUID_MASTERDATA, MENUID_PERSONALIZATION,
  MENUID_AUTHENTICATION_APIKEY, MENUID_AUTHENTICATION_PERMISSION, MENUID_AUTHENTICATION_ROLE,
  MENUID_AUTHENTICATION_SCOPE, MENUID_AUTHENTICATION_SERVICE, MENUID_AUTHENTICATION_SUMMARY,
  MENUID_AUTHENTICATION_TOKEN, MENUID_AUTHENTICATION_USER,
  MENUID_MASTERDATA_BRANCH, MENUID_MASTERDATA_CITY, MENUID_MASTERDATA_COMPANY,
  MENUID_MASTERDATA_DISTRICT, MENUID_MASTERDATA_PROVINCE, MENUID_MASTERDATA_SUBDISTRICT,
  MENUID_PERSONALIZATION_APPLICATION, MENUID_PERSONALIZATION_BANNER,
  MENUID_PERSONALIZATION_PROFILE, PERMISSION_OPERATOR_AND,
} from './constant';
import {
  MENUID_CONFIGURATION, MENUID_CONFIGURATION_CONFIG_ACTION, MENUID_CONFIGURATION_CONFIGGROUP,
  MENUID_CONFIGURATION_CONFIGITEM, MENUID_CONFIGURATION_CONFIG_VALUE,
  ROUTE_NAME_CONFIG_ACTION, ROUTE_NAME_CONFIGURATION, ROUTE_NAME_CONFIG_GROUP,
  ROUTE_NAME_CONFIG_ITEM, ROUTE_NAME_CONFIG_VALUE,
  LocalizedStringConfiguration,
} from './module/configuration';
import {
  MENUID_ATTENDANCE_MANAGEMENT, MENUID_ATTENDANCE_MANAGEMENT_SUMMARY,
  MENUID_ATTENDANCE_MANAGEMENT_HISTORY, MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD,
  ROUTE_NAME_ATTENDANCE_SUMMARY, ROUTE_NAME_ATTENDANCE_MANAGEMENT, ROUTE_NAME_HISTORY,
  ROUTE_NAME_ADDITIONAL_FIELD,
  LocalizedStringAttendance,
} from './module/attendance';
import {
  MENUID_FAQ_MANAGEMENT, MENUID_FAQ_MANAGEMENT_FAQ, MENUID_FAQ_MANAGEMENT_FAQ_CATEGORY,
  ROUTE_NAME_FAQ, ROUTE_NAME_FAQ_CATEGORY, ROUTE_NAME_FAQ_MANAGEMENT,
  LocalizedStringFAQ,
} from './module/faq';
import LocalizedString from './localization';
import {
  MENUID_NOTIFICATION, MENUID_NOTIFICATION_EMAIL, MENUID_NOTIFICATION_NOTIFICATION_TASK,
  MENUID_NOTIFICATION_PUSH_NOTIFICATION, MENUID_NOTIFICATION_SMS, MENUID_NOTIFICATION_WHATSAPP,
  ROUTE_NAME_EMAIL, ROUTE_NAME_NOTIFICATION, ROUTE_NAME_NOTIFICATION_TASK,
  ROUTE_NAME_PUSH_NOTIFICATION, ROUTE_NAME_SMS, ROUTE_NAME_WHATSAPP,
  LocalizedStringNotification,
} from './module/notification';
import {
  MENUID_PACKET_MANAGEMENT, MENUID_PACKET_MANAGEMENT_PACKET, MENUID_PACKET_MANAGEMENT_PACKET_TYPE,
  MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION, MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY,
  ROUTE_NAME_PACKET, ROUTE_NAME_PACKET_MANAGEMENT, ROUTE_NAME_PACKET_TYPE,
  ROUTE_NAME_PICKUP_LOCATION, ROUTE_NAME_PACKET_SUMMARY,
  LocalizedStringPacket,
} from './module/packet';
import {
  MENUID_ACTIVITY_MANAGEMENT, MENUID_ACTIVITY_MANAGEMENT_ACTIVITY,
  MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE, MENUID_ACTIVITY_MANAGEMENT_CUSTOMER,
  MENUID_ACTIVITY_MANAGEMENT_INDUSTRY,
  ROUTE_NAME_ACTIVITY_MANAGEMENT, ROUTE_NAME_ACTIVITY, ROUTE_NAME_ACTIVITY_TYPE,
  ROUTE_NAME_CUSTOMER, ROUTE_NAME_INDUSTRY,
  LocalizedStringActivityManagement,
} from './module/activity';
import {
  MENUID_BCARD, MENUID_BCARD_COMPANY_MAPPING, MENUID_BCARD_INFO_MAPPING,
  ROUTE_NAME_BCARD, ROUTE_NAME_COMPANY_MAPPING, ROUTE_NAME_INFO_MAPPING,
  LocalizedStringBcard,
} from './module/bcard';
import {
  MENUID_SHORTLINK, MENUID_SHORTLINK_SHORTLINK_SCREEN,
  ROUTE_NAME_SHORTLINK_MENU, ROUTE_NAME_SHORTLINK_SCREEN,
  LocalizedStringShortlink,
} from './module/shortlink';
import {
  MENUID_AUDIT_MANAGEMENT, MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL,
  ROUTE_NAME_AUDIT_MANAGEMENT, ROUTE_NAME_AUDIT_TRAIL,
  LocalizedStringAudit,
} from './module/audit';
import {
  MENUID_POINT, MENUID_POINT_PURCHASE_REQUEST, MENUID_POINT_ACHIEVEMENT, MENUID_POINT_LEADER_BOARD,
  MENUID_POINT_POINT, MENUID_POINT_POINT_SUBMISSION, MENUID_POINT_POINT_TRANSACTION,
  MENUID_POINT_VOID_POINT_REQUEST,
  ROUTE_NAME_POINT, ROUTE_NAME_PURCHASE_REQUEST, ROUTE_NAME_POINT_ACHIEVEMENT,
  ROUTE_NAME_POINT_LEADER_BOARD, ROUTE_NAME_POINT_POINT, ROUTE_NAME_POINT_POINT_SUBMISSION,
  ROUTE_NAME_POINT_POINT_TRANSACTION, ROUTE_NAME_VOID_POINT_REQUEST,
  LocalizedStringPoint,
} from './module/point';
import {
  MENUID_VOUCHER, MENUID_VOUCHER_CATEGORY, MENUID_VOUCHER_TOKEN, MENUID_VOUCHER_TOKEN_REQUEST,
  MENUID_VOUCHER_VOUCHER,
  ROUTE_NAME_VOUCHER, ROUTE_NAME_VOUCHER_CATEGORY, ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
  ROUTE_NAME_VOUCHER_TOKEN, ROUTE_NAME_VOUCHER_VOUCHER,
  MENUID_VOUCHER_TOKEN_IMPORT, ROUTE_NAME_VOUCHER_TOKEN_IMPORT,
  LocalizedStringVoucher,
  MENUID_VOUCHER_PURCHASE_REQUEST,
  ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
  MENUID_VOUCHER_PROMOTED_MERCHANT, ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT,
} from './module/voucher';
import {
  MENUID_DISCUSSION, MENUID_DISCUSSION_DISCUSSION_COMMENT, MENUID_DISCUSSION_REPORTED_COMMENT,
  ROUTE_NAME_DISCUSSION_MENU, ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT,
  ROUTE_NAME_DISCUSSION_REPORTED_COMMENT, LocalizedStringDiscussion,
} from './module/discussion';
import {
  MENUID_FORUM, MENUID_FORUM_CATEGORY, MENUID_FORUM_POST,
  ROUTE_NAME_FORUM, ROUTE_NAME_FORUM_CATEGORY, ROUTE_NAME_FORUM_POST,
  LocalizedStringForum,
} from './module/forum';
import {
  MENUID_GAMEQR, MENUID_GAMEQR_CATEGORY, MENUID_GAMEQR_CHALLENGE,
  ROUTE_NAME_GAMEQR, ROUTE_NAME_GAMEQR_CATEGORY, ROUTE_NAME_GAMEQR_CHALLENGE,
  LocalizedStringGameQR,
} from './module/game-qr';
import {
  MENUID_CAR_CATALOG, MENUID_CAR_CATALOG_CATALOG, MENUID_CAR_CATALOG_MODEL,
  ROUTE_NAME_CAR_CATALOG, ROUTE_NAME_CATALOG, ROUTE_NAME_MODEL,
  LocalizedStringCarCatalog,
} from './module/car-catalog';
import {
  MENUID_CAR_SERVICE, MENUID_CAR_SERVICE_SERVICE_REQUEST,
  ROUTE_NAME_CAR_SERVICE, ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST,
  LocalizedStringCarService,
} from './module/car-service';
import {
  MENUID_CAR_REGISTRATION, MENUID_CAR_REGISTRATION_REGISTRATION,
  ROUTE_NAME_CAR_REGISTRATION, ROUTE_NAME_REGISTRATION,
  LocalizedStringCarRegistration,
} from './module/car-registration';
import {
  ROUTE_NAME_NEWS_MANAGEMENT, ROUTE_NAME_NEWS, ROUTE_NAME_NEWS_CATEGORY,
  MENUID_NEWS_MANAGEMENT, MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY,
  MENUID_NEWS_MANAGEMENT_NEWS, LocalizedStringNews,
  MENUID_NEWS_MANAGEMENT_NEWS_DASHBOARD, ROUTE_NAME_NEWS_DASHBOARD,
} from './module/news';
import {
  MENUID_PARTNER, MENUID_PARTNER_MERCHANT,
  ROUTE_NAME_PARTNER, ROUTE_NAME_PARTNER_MERCHANT,
  LocalizedStringPartner,
} from './module/partner';
import {
  MENUID_KNOWLEDGE_MANAGEMENT,
  MENUID_KNOWLEDGE_MANAGEMENT_ARTICLES, MENUID_KNOWLEDGE_MANAGEMENT_ARTICLE_CATEGORY,
  ROUTE_NAME_ARTICLE, ROUTE_NAME_ARTICLE_CATEGORY, ROUTE_NAME_KNOWLEDGE_MANAGEMENT,
  LocalizedStringKnowledgeManagement,
} from './module/knowledge-management';
import {
  MENUID_AGENDA, MENUID_AGENDA_LOCATION,
  ROUTE_NAME_AGENDA, ROUTE_NAME_AGENDA_LOCATION,
  ROUTE_NAME_AGENDA_EVENT, MENUID_AGENDA_EVENT,
  LocalizedStringAgenda,
} from './module/agenda';
import {
  MENUID_COVID_DATA, MENUID_COVID, ROUTE_NAME_COVID_DATA,
  ROUTE_NAME_COVID, LocalizedStringCovid,
  MENUID_COVID_DASHBOARD, ROUTE_NAME_COVID_DASHBOARD,
} from './module/covid';
import {
  MENUID_SURVEY, MENUID_SURVEY_SURVEY_CATEGORY, MENUID_QUIZ, MENUID_QUIZ_QUIZ,
  MENUID_QUIZ_QUIZ_CATEGORY, MENUID_SURVEY_SURVEY, MENUID_SURVEY_SURVEY_QUESTION,
  MENUID_SURVEY_SURVEY_RESPONSE, MENUID_QUIZ_QUIZ_QUESTION,
  ROUTE_NAME_SURVEY_MENU, ROUTE_NAME_SURVEY_CATEGORY, ROUTE_NAME_QUIZ_MENU,
  ROUTE_NAME_QUIZ_CATEGORY, ROUTE_NAME_QUIZ_QUIZ, ROUTE_NAME_SURVEY_SURVEY,
  ROUTE_NAME_SURVEY_QUESTION, ROUTE_NAME_SURVEY_RESPONSE, ROUTE_NAME_QUIZ_QUESTION,
  ROUTE_NAME_QUIZ_RESPONSE, MENUID_QUIZ_QUIZ_RESPONSE,
  LocalizedStringSurveyQuiz,
} from './module/survey-and-quiz';

const menuList = [
  {
    id: MENUID_DASHBOARD,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelWelcome,
    value: ROUTE_NAME_DASHBOARD,
    icon: <ViewDashboard />,
    selected: false,
  },
  {
    id: MENUID_AUTHENTICATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelAuthentication,
    value: ROUTE_NAME_AUTHENTICATION,
    icon: <AccountKey />,
    selected: false,
  },
  {
    id: MENUID_PERSONALIZATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelPersonalization,
    value: ROUTE_NAME_PERSONALIZATION,
    icon: <Account />,
    selected: false,
  },
  {
    id: MENUID_MASTERDATA,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelMasterData,
    value: ROUTE_NAME_MASTER_DATA,
    icon: <Database />,
    selected: false,
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringAttendance.attendanceManagementMenu.title,
    value: ROUTE_NAME_ATTENDANCE_MANAGEMENT,
    icon: <CalendarMultipleCheck />,
    selected: false,
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringActivityManagement.activityManagementMenu.title,
    value: ROUTE_NAME_ACTIVITY_MANAGEMENT,
    icon: <FileTable />,
    selected: false,
  },
  {
    id: MENUID_NEWS_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelNewsManagement,
    value: ROUTE_NAME_NEWS_MANAGEMENT,
    icon: <NewspaperVariant />,
    selected: false,
  },
  {
    id: MENUID_KNOWLEDGE_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelKnowledeManagement,
    value: ROUTE_NAME_KNOWLEDGE_MANAGEMENT,
    icon: <NotebookMultiple />,
    selected: false,
  },
  {
    id: MENUID_AGENDA,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelAgenda,
    value: ROUTE_NAME_AGENDA,
    icon: <CalendarClock />,
    selected: false,
  },
  {
    id: MENUID_COVID,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedString.common.labelCovid,
    value: ROUTE_NAME_COVID,
    icon: <Hospital />,
    selected: false,
  },
  {
    id: MENUID_PACKET_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPacket.packetMenu.title,
    value: ROUTE_NAME_PACKET_MANAGEMENT,
    icon: <PackageVariant />,
    selected: false,
  },
  {
    id: MENUID_BCARD,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringBcard.businessCard.title,
    value: ROUTE_NAME_BCARD,
    icon: <AccountCardDetails />,
    selected: false,
  },
  {
    id: MENUID_CAR_CATALOG,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringCarCatalog.carCatalogMenu.title,
    value: ROUTE_NAME_CAR_CATALOG,
    icon: <Car />,
    selected: false,
  },
  {
    id: MENUID_CAR_REGISTRATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringCarRegistration.carRegistrationMenu.title,
    value: ROUTE_NAME_CAR_REGISTRATION,
    icon: <ClipboardArrowLeft />,
    selected: false,
  },
  {
    id: MENUID_CAR_SERVICE,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringCarService.carServiceMenu.title,
    value: ROUTE_NAME_CAR_SERVICE,
    icon: <CarConnected />,
    selected: false,
  },
  {
    id: MENUID_FORUM,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringForum.forumMenu.title,
    value: ROUTE_NAME_FORUM,
    icon: <Forum />,
    selected: false,
  },
  {
    id: MENUID_GAMEQR,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringGameQR.gameQRMenu.title,
    value: ROUTE_NAME_GAMEQR,
    icon: <Gamepad />,
    selected: false,
  },
  {
    id: MENUID_POINT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPoint.pointMenu.title,
    value: ROUTE_NAME_POINT,
    icon: <Cash />,
    selected: false,
  },
  {
    id: MENUID_VOUCHER,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringVoucher.voucherMenu.title,
    value: ROUTE_NAME_VOUCHER,
    icon: <TicketPercent />,
    selected: false,
  },
  {
    id: MENUID_PARTNER,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringPartner.partnerMenu.title,
    value: ROUTE_NAME_PARTNER,
    icon: <AccountGroup />,
    selected: false,
  },
  {
    id: MENUID_FAQ_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringFAQ.faqManagementMenu.title,
    value: ROUTE_NAME_FAQ_MANAGEMENT,
    icon: <AccountQuestion />,
    selected: false,
  },
  {
    id: MENUID_SURVEY,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringSurveyQuiz.surveyMenu.title,
    value: ROUTE_NAME_SURVEY_MENU,
    icon: <BulletinBoard />,
    selected: false,
  },
  {
    id: MENUID_QUIZ,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringSurveyQuiz.quizMenu.title,
    value: ROUTE_NAME_QUIZ_MENU,
    icon: <CommentQuestion />,
    selected: false,
  },
  {
    id: MENUID_CONFIGURATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringConfiguration.configurationMenu.title,
    value: ROUTE_NAME_CONFIGURATION,
    icon: <Cogs />,
    selected: false,
  },
  {
    id: MENUID_AUDIT_MANAGEMENT,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringAudit.auditManagementMenu.title,
    value: ROUTE_NAME_AUDIT_MANAGEMENT,
    icon: <FileSearch />,
    selected: false,
  },
  {
    id: MENUID_DISCUSSION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringDiscussion.discussionMenu.title,
    value: ROUTE_NAME_DISCUSSION_MENU,
    icon: <CommentTextMultiple />,
    selected: false,
  },
  {
    id: MENUID_SHORTLINK,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringShortlink.shortlinkMenu.title,
    value: ROUTE_NAME_SHORTLINK_MENU,
    icon: <IconLink />,
    selected: false,
  },
  {
    id: MENUID_NOTIFICATION,
    level: 1,
    parentId: MENUID_ROOT,
    label: LocalizedStringNotification.notificationMenu.title,
    value: ROUTE_NAME_NOTIFICATION,
    icon: <Bell />,
    selected: false,
  },
  {
    id: MENUID_AUTHENTICATION_SUMMARY,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.summaryScreen.title,
    value: ROUTE_NAME_SUMMARY,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_SUMMARY_ACCESS_USING'],
  },
  {
    id: MENUID_AUTHENTICATION_SERVICE,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.serviceScreen.title,
    value: ROUTE_NAME_SERVICE,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_CREATE_SERVICE'],
  },
  {
    id: MENUID_AUTHENTICATION_SCOPE,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.scopeScreen.title,
    value: ROUTE_NAME_SCOPE,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_CREATE_SCOPE'],
  },
  {
    id: MENUID_AUTHENTICATION_APIKEY,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.apiKeyScreen.title,
    value: ROUTE_NAME_API_KEY,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_CREATE_APIKEY'],
  },
  {
    id: MENUID_AUTHENTICATION_TOKEN,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.tokenScreen.title,
    value: ROUTE_NAME_TOKEN,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_GET_TOKEN'],
  },
  {
    id: MENUID_AUTHENTICATION_PERMISSION,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.permissionScreen.title,
    value: ROUTE_NAME_PERMISSION,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_CREATE_PERMISSION'],
  },
  {
    id: MENUID_AUTHENTICATION_ROLE,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.roleScreen.title,
    value: ROUTE_NAME_ROLE,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_CREATE_ROLE'],
  },
  {
    id: MENUID_AUTHENTICATION_USER,
    level: 2,
    parentId: MENUID_AUTHENTICATION,
    label: LocalizedString.userScreen.title,
    value: ROUTE_NAME_USER,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_EDIT_USER'],
  },
  {
    id: MENUID_PERSONALIZATION_APPLICATION,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.applicationScreen.title,
    value: ROUTE_NAME_APPLICATION,
    icon: <div />,
    selected: false,
    permissions: ['CORE_CREATE_APPLICATION'],
  },
  {
    id: MENUID_PERSONALIZATION_BANNER,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.bannerScreen.title,
    value: ROUTE_NAME_BANNER,
    icon: <div />,
    selected: false,
    permissions: ['CORE_CREATE_BANNER'],
  },
  {
    id: MENUID_PERSONALIZATION_PROFILE,
    level: 2,
    parentId: MENUID_PERSONALIZATION,
    label: LocalizedString.profileScreen.title,
    value: ROUTE_NAME_PROFILE,
    icon: <div />,
    selected: false,
    permissions: ['MDATA_EDIT_PROFILE'],
  },
  {
    id: MENUID_MASTERDATA_PROVINCE,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.provinceScreen.title,
    value: ROUTE_NAME_PROVINCE,
    icon: <div />,
    selected: false,
    permissions: ['MDATA_CREATE_PROVINCE'],
  },
  {
    id: MENUID_MASTERDATA_CITY,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.cityScreen.title,
    value: ROUTE_NAME_CITY,
    icon: <div />,
    selected: false,
    permissions: ['MDATA_CREATE_CITY'],
  },
  {
    id: MENUID_MASTERDATA_DISTRICT,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.districtScreen.title,
    value: ROUTE_NAME_DISTRICT,
    icon: <div />,
    selected: false,
    permissions: ['MDATA_CREATE_DISTRICT'],
  },
  {
    id: MENUID_MASTERDATA_SUBDISTRICT,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.subdistrictScreen.title,
    value: ROUTE_NAME_SUBDISTRICT,
    icon: <div />,
    selected: false,
    permissions: ['MDATA_CREATE_SUBDISTRICT'],
  },
  {
    id: MENUID_MASTERDATA_COMPANY,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.companyScreen.title,
    value: ROUTE_NAME_COMPANY,
    icon: <div />,
    selected: false,
    permissions: [
      'MDATA_CREATE_COMPANY', 'MDATA_DELETE_COMPANY',
      'MDATA_EDIT_COMPANY', 'MDATA_GET_COMPANY',
    ],
  },
  {
    id: MENUID_MASTERDATA_BRANCH,
    level: 2,
    parentId: MENUID_MASTERDATA,
    label: LocalizedString.branchScreen.title,
    value: ROUTE_NAME_BRANCH,
    icon: <div />,
    selected: false,
    permissions: ['MDATA_CREATE_BRANCH'],
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT_SUMMARY,
    level: 2,
    parentId: MENUID_ATTENDANCE_MANAGEMENT,
    label: LocalizedStringAttendance.attendanceSummaryScreen.title,
    value: ROUTE_NAME_ATTENDANCE_SUMMARY,
    icon: <div />,
    selected: false,
    permissions: [
      'ATTENDANCE_SUMMARY_CLOCKIN', 'ATTENDANCE_SUMMARY_CLOCKIN_LOCATION',
      'ATTENDANCE_SUMMARY_CLOCKOUT', 'ATTENDANCE_SUMMARY_CLOCKOUT_LOCATION',
      'ATTENDANCE_SUMMARY_TOTAL_CLOCKIN', 'ATTENDANCE_SUMMARY_TOTAL_CLOCKOUT',
    ],
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT_HISTORY,
    level: 2,
    parentId: MENUID_ATTENDANCE_MANAGEMENT,
    label: LocalizedStringAttendance.historyScreen.title,
    value: ROUTE_NAME_HISTORY,
    icon: <div />,
    selected: false,
    permissions: ['ATTENDANCE_GET_HISTORY'],
  },
  {
    id: MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD,
    level: 2,
    parentId: MENUID_ATTENDANCE_MANAGEMENT,
    label: LocalizedStringAttendance.additionalFieldScreen.title,
    value: ROUTE_NAME_ADDITIONAL_FIELD,
    icon: <div />,
    selected: false,
    permissions: ['ATTENDANCE_CREATE_ADDITIONAL_FIELD'],
  },
  {
    id: MENUID_NEWS_MANAGEMENT_NEWS_DASHBOARD,
    level: 2,
    parentId: MENUID_NEWS_MANAGEMENT,
    label: LocalizedStringNews.newsDashboardScreen.title,
    value: ROUTE_NAME_NEWS_DASHBOARD,
    icon: <div />,
    selected: false,
    permissions: ['NEWS_CREATE_NEWS'],
  },
  {
    id: MENUID_NEWS_MANAGEMENT_NEWS_CATEGORY,
    level: 2,
    parentId: MENUID_NEWS_MANAGEMENT,
    label: LocalizedStringNews.newsCategoryScreen.title,
    value: ROUTE_NAME_NEWS_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['NEWS_CREATE_NEWS_CATEGORY'],
  },
  {
    id: MENUID_NEWS_MANAGEMENT_NEWS,
    level: 2,
    parentId: MENUID_NEWS_MANAGEMENT,
    label: LocalizedStringNews.newsScreen.title,
    value: ROUTE_NAME_NEWS,
    icon: <div />,
    selected: false,
    permissions: ['NEWS_CREATE_NEWS'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIG_ACTION,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configActionScreen.title,
    value: ROUTE_NAME_CONFIG_ACTION,
    icon: <div />,
    selected: false,
    permissions: ['CONFIG_CREATE_CONFIG_GROUP'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIGGROUP,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configGroupScreen.title,
    value: ROUTE_NAME_CONFIG_GROUP,
    icon: <div />,
    selected: false,
    permissions: ['CONFIG_CREATE_CONFIG_GROUP'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIGITEM,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configItemScreen.title,
    value: ROUTE_NAME_CONFIG_ITEM,
    icon: <div />,
    selected: false,
    permissions: ['CONFIG_CREATE_CONFIG_ITEM'],
  },
  {
    id: MENUID_CONFIGURATION_CONFIG_VALUE,
    level: 2,
    parentId: MENUID_CONFIGURATION,
    label: LocalizedStringConfiguration.configValueScreen.title,
    value: ROUTE_NAME_CONFIG_VALUE,
    icon: <div />,
    selected: false,
    permissions: ['CONFIG_EDIT_CONFIG_ITEM'],
  },
  {
    id: MENUID_AUDIT_MANAGEMENT_AUDIT_TRAIL,
    level: 2,
    parentId: MENUID_AUDIT_MANAGEMENT,
    label: LocalizedStringAudit.auditTrailPage.title,
    value: ROUTE_NAME_AUDIT_TRAIL,
    icon: <div />,
    selected: false,
    permissions: ['AUDITTRAIL_GET_AUDITTRAIL'],
  },
  {
    id: MENUID_SHORTLINK_SHORTLINK_SCREEN,
    level: 2,
    parentId: MENUID_SHORTLINK,
    label: LocalizedStringShortlink.shortlinkScreen.title,
    value: ROUTE_NAME_SHORTLINK_SCREEN,
    icon: <div />,
    selected: false,
    permissions: ['SHORTLINK_CREATE_LINK'],
  },
  {
    id: MENUID_FAQ_MANAGEMENT_FAQ_CATEGORY,
    level: 2,
    parentId: MENUID_FAQ_MANAGEMENT,
    label: LocalizedStringFAQ.faqCategoryPage.title,
    value: ROUTE_NAME_FAQ_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['NEWS_CREATE_FAQ_CATEGORY'],
  },
  {
    id: MENUID_FAQ_MANAGEMENT_FAQ,
    level: 2,
    parentId: MENUID_FAQ_MANAGEMENT,
    label: LocalizedStringFAQ.faqPage.title,
    value: ROUTE_NAME_FAQ,
    icon: <div />,
    selected: false,
    permissions: ['NEWS_CREATE_FAQ'],
  },
  {
    id: MENUID_SURVEY_SURVEY_CATEGORY,
    level: 2,
    parentId: MENUID_SURVEY,
    label: LocalizedStringSurveyQuiz.surveyCategoryScreen.title,
    value: ROUTE_NAME_SURVEY_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_CREATE_SURVEY_CATEGORY'],
  },
  {
    id: MENUID_SURVEY_SURVEY,
    level: 2,
    parentId: MENUID_SURVEY,
    label: LocalizedStringSurveyQuiz.surveyScreen.title,
    value: ROUTE_NAME_SURVEY_SURVEY,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_CREATE_SURVEY_QUESTION'],
  },
  {
    id: MENUID_SURVEY_SURVEY_QUESTION,
    level: 2,
    parentId: MENUID_SURVEY,
    label: LocalizedStringSurveyQuiz.surveyQuestionScreen.title,
    value: ROUTE_NAME_SURVEY_QUESTION,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_GET_SURVEY_QUESTION'],
  },
  {
    id: MENUID_SURVEY_SURVEY_RESPONSE,
    level: 2,
    parentId: MENUID_SURVEY,
    label: LocalizedStringSurveyQuiz.surveyResponseScreen.title,
    value: ROUTE_NAME_SURVEY_RESPONSE,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_GET_SURVEY_RESPONSE'],
  },
  {
    id: MENUID_QUIZ_QUIZ_CATEGORY,
    level: 2,
    parentId: MENUID_QUIZ,
    label: LocalizedStringSurveyQuiz.quizCategoryScreen.title,
    value: ROUTE_NAME_QUIZ_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_CREATE_QUIZ_CATEGORY'],
  },
  {
    id: MENUID_QUIZ_QUIZ,
    level: 2,
    parentId: MENUID_QUIZ,
    label: LocalizedStringSurveyQuiz.quizScreen.title,
    value: ROUTE_NAME_QUIZ_QUIZ,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_CREATE_QUIZ'],
  },
  {
    id: MENUID_QUIZ_QUIZ_QUESTION,
    level: 2,
    parentId: MENUID_QUIZ,
    label: LocalizedStringSurveyQuiz.quizQuestionScreen.title,
    value: ROUTE_NAME_QUIZ_QUESTION,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_GET_QUIZ_QUESTION'],
  },
  {
    id: MENUID_QUIZ_QUIZ_RESPONSE,
    level: 2,
    parentId: MENUID_QUIZ,
    label: LocalizedStringSurveyQuiz.quizResponseScreen.title,
    value: ROUTE_NAME_QUIZ_RESPONSE,
    icon: <div />,
    selected: false,
    permissions: ['SURVEY_GET_QUIZ_RESPONSE'],
  },
  {
    id: MENUID_NOTIFICATION_NOTIFICATION_TASK,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.notificationTaskScreen.title,
    value: ROUTE_NAME_NOTIFICATION_TASK,
    icon: <div />,
    selected: false,
    permissions: ['CORE_SEND_SIMPLE_NOTIFICATION'],
  },
  {
    id: MENUID_NOTIFICATION_EMAIL,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.emailScreen.title,
    value: ROUTE_NAME_EMAIL,
    icon: <div />,
    selected: false,
    permissions: ['MESSAGING_GET_EMAIL'],
  },
  {
    id: MENUID_NOTIFICATION_SMS,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.smsScreen.title,
    value: ROUTE_NAME_SMS,
    icon: <div />,
    selected: false,
    permissions: ['MESSAGING_GET_SMS'],
  },
  {
    id: MENUID_NOTIFICATION_WHATSAPP,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.whatsappScreen.title,
    value: ROUTE_NAME_WHATSAPP,
    icon: <div />,
    selected: false,
    permissions: ['MESSAGING_GET_WHATSAPP'],
  },
  {
    id: MENUID_NOTIFICATION_PUSH_NOTIFICATION,
    level: 2,
    parentId: MENUID_NOTIFICATION,
    label: LocalizedStringNotification.pushNotificationScreen.title,
    value: ROUTE_NAME_PUSH_NOTIFICATION,
    icon: <div />,
    selected: false,
    permissions: ['MESSAGING_GET_PUSH_NOTIF'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PACKET_SUMMARY,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.packetSummaryScreen.title,
    value: ROUTE_NAME_PACKET_SUMMARY,
    icon: <div />,
    selected: false,
    permissions: ['PACKET_SUMMARY'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PICKUP_LOCATION,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.pickupLocationScreen.title,
    value: ROUTE_NAME_PICKUP_LOCATION,
    icon: <div />,
    selected: false,
    permissions: ['PACKET_CREATE_LOCATION'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PACKET_TYPE,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.packetTypeScreen.title,
    value: ROUTE_NAME_PACKET_TYPE,
    icon: <div />,
    selected: false,
    permissions: ['PACKET_CREATE_PACKET_TYPE'],
  },
  {
    id: MENUID_PACKET_MANAGEMENT_PACKET,
    level: 2,
    parentId: MENUID_PACKET_MANAGEMENT,
    label: LocalizedStringPacket.packetScreen.title,
    value: ROUTE_NAME_PACKET,
    icon: <div />,
    selected: false,
    permissions: ['PACKET_CREATE_PACKET'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_INDUSTRY,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.industryScreen.title,
    value: ROUTE_NAME_INDUSTRY,
    icon: <div />,
    selected: false,
    permissions: ['ACTIVITY_CREATE_INDUSTRY'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_CUSTOMER,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.customerScreen.title,
    value: ROUTE_NAME_CUSTOMER,
    icon: <div />,
    selected: false,
    permissions: ['ACTIVITY_CREATE_CUSTOMER'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_ACTIVITY_TYPE,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.activityTypeScreen.title,
    value: ROUTE_NAME_ACTIVITY_TYPE,
    icon: <div />,
    selected: false,
    permissions: ['ACTIVITY_CREATE_ACTIVITY_TYPE'],
  },
  {
    id: MENUID_ACTIVITY_MANAGEMENT_ACTIVITY,
    level: 2,
    parentId: MENUID_ACTIVITY_MANAGEMENT,
    label: LocalizedStringActivityManagement.activityScreen.title,
    value: ROUTE_NAME_ACTIVITY,
    icon: <div />,
    selected: false,
    permissions: ['ACTIVITY_CREATE_ACTIVITY'],
  },
  {
    id: MENUID_BCARD_COMPANY_MAPPING,
    level: 2,
    parentId: MENUID_BCARD,
    label: LocalizedStringBcard.companyMappingScreen.title,
    value: ROUTE_NAME_COMPANY_MAPPING,
    icon: <div />,
    selected: false,
    permissions: ['BCARD_CREATE_COMPANY_MAPPING'],
  },
  {
    id: MENUID_BCARD_INFO_MAPPING,
    level: 2,
    parentId: MENUID_BCARD,
    label: LocalizedStringBcard.infoMappingScreen.title,
    value: ROUTE_NAME_INFO_MAPPING,
    icon: <div />,
    selected: false,
    permissions: ['BCARD_CREATE_INFO_MAPPING'],
  },
  {
    id: MENUID_POINT_POINT_SUBMISSION,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.pointSubmissionPage.title,
    value: ROUTE_NAME_POINT_POINT_SUBMISSION,
    icon: <div />,
    selected: false,
    permissions: ['POINT_GET_POINT_SUBMISSION'],
  },
  {
    id: MENUID_POINT_POINT,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.pointPage.title,
    value: ROUTE_NAME_POINT_POINT,
    icon: <div />,
    selected: false,
    permissions: ['POINT_GET_POINT'],
  },
  {
    id: MENUID_POINT_PURCHASE_REQUEST,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.purchaseRequestPage.title,
    value: ROUTE_NAME_PURCHASE_REQUEST,
    icon: <div />,
    selected: false,
    permissions: ['POINT_GET_PURCHASE_REQUEST'],
  },
  {
    id: MENUID_POINT_POINT_TRANSACTION,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.pointTransactionPage.title,
    value: ROUTE_NAME_POINT_POINT_TRANSACTION,
    icon: <div />,
    selected: false,
    permissions: ['POINT_GET_TRANSACTION'],
  },
  {
    id: MENUID_POINT_ACHIEVEMENT,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.achievementPage.title,
    value: ROUTE_NAME_POINT_ACHIEVEMENT,
    icon: <div />,
    selected: false,
    permissions: ['AUTH_GET_USER', 'POINT_GET_BALANCE', 'POINT_GET_ACHIEVEMENT', 'POINT_GET_TRANSACTION'],
    permissionsOperator: PERMISSION_OPERATOR_AND,
  },
  {
    id: MENUID_POINT_LEADER_BOARD,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.leaderBoardPage.title,
    value: ROUTE_NAME_POINT_LEADER_BOARD,
    icon: <div />,
    selected: false,
    permissions: ['POINT_GET_LEADER_BOARD'],
  },
  {
    id: MENUID_VOUCHER_CATEGORY,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherCategoryScreen.title,
    value: ROUTE_NAME_VOUCHER_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_GET_CATEGORY'],
  },
  {
    id: MENUID_DISCUSSION_DISCUSSION_COMMENT,
    level: 2,
    parentId: MENUID_DISCUSSION,
    label: LocalizedStringDiscussion.discussionCommentPage.title,
    value: ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT,
    icon: <div />,
    selected: false,
    permissions: ['DISCUS_GET_ALL_COMMENTS', 'DISCUS_GET_COMMENT'],
  },
  {
    id: MENUID_DISCUSSION_REPORTED_COMMENT,
    level: 2,
    parentId: MENUID_DISCUSSION,
    label: LocalizedStringDiscussion.reportedCommentPage.title,
    value: ROUTE_NAME_DISCUSSION_REPORTED_COMMENT,
    icon: <div />,
    selected: false,
    permissions: ['DISCUS_GET_REPORTED_COMMENT', 'DISCUS_HANDLE_REPORTED_COMMENT'],
  },
  {
    id: MENUID_CAR_SERVICE_SERVICE_REQUEST,
    level: 2,
    parentId: MENUID_CAR_SERVICE,
    label: LocalizedStringCarService.serviceRequestPage.title,
    value: ROUTE_NAME_CAR_SERVICE_SERVICE_REQUEST,
    icon: <div />,
    selected: false,
    permissions: ['CARSERVICE_GET_SERVICE_BOOKING'],
  },
  {
    id: MENUID_FORUM_CATEGORY,
    level: 2,
    parentId: MENUID_FORUM,
    label: LocalizedStringForum.forumCategoryPage.title,
    value: ROUTE_NAME_FORUM_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['FORUM_CREATE_FORUM_CATEGORY'],
  },
  {
    id: MENUID_FORUM_POST,
    level: 2,
    parentId: MENUID_FORUM,
    label: LocalizedStringForum.forumPostPage.title,
    value: ROUTE_NAME_FORUM_POST,
    icon: <div />,
    selected: false,
    permissions: ['FORUM_CREATE_POST'],
  },
  {
    id: MENUID_GAMEQR_CATEGORY,
    level: 2,
    parentId: MENUID_GAMEQR,
    label: LocalizedStringGameQR.gameQRCategoryPage.title,
    value: ROUTE_NAME_GAMEQR_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['GAMEQR_CREATE_CATEGORY'],
  },
  {
    id: MENUID_GAMEQR_CHALLENGE,
    level: 2,
    parentId: MENUID_GAMEQR,
    label: LocalizedStringGameQR.challengePage.title,
    value: ROUTE_NAME_GAMEQR_CHALLENGE,
    icon: <div />,
    selected: false,
    permissions: ['GAMEQR_GET_CHALLENGE'],
  },
  {
    id: MENUID_VOUCHER_VOUCHER,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherPage.title,
    value: ROUTE_NAME_VOUCHER_VOUCHER,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER'],
  },
  {
    id: MENUID_VOUCHER_PROMOTED_MERCHANT,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.promotedMerchantPage.title,
    value: ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER'],
  },
  {
    id: MENUID_POINT_VOID_POINT_REQUEST,
    level: 2,
    parentId: MENUID_POINT,
    label: LocalizedStringPoint.voidPointRequestPage.title,
    value: ROUTE_NAME_VOID_POINT_REQUEST,
    icon: <div />,
    selected: false,
    permissions: ['POINT_GET_VOID_POINT_REQUEST'],
  },
  {
    id: MENUID_VOUCHER_TOKEN_IMPORT,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.tokenImportPage.title,
    value: ROUTE_NAME_VOUCHER_TOKEN_IMPORT,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_GET_VOUCHER_IMPORT'],
  },
  {
    id: MENUID_VOUCHER_TOKEN_REQUEST,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.tokenRequestPage.title,
    value: ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_CREATE_TOKEN_REQUEST'],
  },
  {
    id: MENUID_VOUCHER_TOKEN,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherTokenPage.title,
    value: ROUTE_NAME_VOUCHER_TOKEN,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_GET_TOKEN'],
  },
  {
    id: MENUID_VOUCHER_PURCHASE_REQUEST,
    level: 2,
    parentId: MENUID_VOUCHER,
    label: LocalizedStringVoucher.voucherPurchaseRequestPage.title,
    value: ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
    icon: <div />,
    selected: false,
    permissions: ['VOUCHER_GET_PURCHASE_REQUEST'],
  },
  {
    id: MENUID_CAR_CATALOG_MODEL,
    level: 2,
    parentId: MENUID_CAR_CATALOG,
    label: LocalizedStringCarCatalog.modelPage.title,
    value: ROUTE_NAME_MODEL,
    icon: <div />,
    selected: false,
    permissions: ['CARCATALOG_GET_MODEL'],
  },
  {
    id: MENUID_CAR_CATALOG_CATALOG,
    level: 2,
    parentId: MENUID_CAR_CATALOG,
    label: LocalizedStringCarCatalog.catalogPage.title,
    value: ROUTE_NAME_CATALOG,
    icon: <div />,
    selected: false,
    permissions: ['CARCATALOG_GET_CAR'],
  },
  {
    id: MENUID_CAR_REGISTRATION_REGISTRATION,
    level: 2,
    parentId: MENUID_CAR_REGISTRATION,
    label: LocalizedStringCarRegistration.registrationPage.title,
    value: ROUTE_NAME_REGISTRATION,
    icon: <div />,
    selected: false,
    permissions: ['MYCAR_GET_CAR_REGISTRATION'],
  },
  {
    id: MENUID_PARTNER_MERCHANT,
    level: 2,
    parentId: MENUID_PARTNER,
    label: LocalizedStringPartner.partnerMerchantPage.title,
    value: ROUTE_NAME_PARTNER_MERCHANT,
    icon: <div />,
    selected: false,
    permissions: ['MERCHANT_CREATE_MERCHANT'],
  },
  {
    id: MENUID_KNOWLEDGE_MANAGEMENT_ARTICLE_CATEGORY,
    level: 2,
    parentId: MENUID_KNOWLEDGE_MANAGEMENT,
    label: LocalizedStringKnowledgeManagement.articleCategoryScreen.title,
    value: ROUTE_NAME_ARTICLE_CATEGORY,
    icon: <div />,
    selected: false,
    permissions: ['KNOWLEDGEMANAGEMENT_CREATE_ARTICLE_CATEGORY'],
  },
  {
    id: MENUID_KNOWLEDGE_MANAGEMENT_ARTICLES,
    level: 2,
    parentId: MENUID_KNOWLEDGE_MANAGEMENT,
    label: LocalizedStringKnowledgeManagement.articlesScreen.title,
    value: ROUTE_NAME_ARTICLE,
    icon: <div />,
    selected: false,
    permissions: ['KNOWLEDGEMANAGEMENT_CREATE_ARTICLE'],
  },
  {
    id: MENUID_AGENDA_LOCATION,
    level: 2,
    parentId: MENUID_AGENDA,
    label: LocalizedStringAgenda.locationScreen.title,
    value: ROUTE_NAME_AGENDA_LOCATION,
    icon: <div />,
    selected: false,
    permissions: ['AGENDA_CREATE_LOCATION'],
  },
  {
    id: MENUID_AGENDA_EVENT,
    level: 2,
    parentId: MENUID_AGENDA,
    label: LocalizedStringAgenda.eventScreen.title,
    value: ROUTE_NAME_AGENDA_EVENT,
    icon: <div />,
    selected: false,
    permissions: ['AGENDA_CREATE_EVENT'],
  },
  {
    id: MENUID_COVID_DASHBOARD,
    level: 2,
    parentId: MENUID_COVID,
    label: LocalizedStringCovid.covidDashboardPage.title,
    value: ROUTE_NAME_COVID_DASHBOARD,
    icon: <div />,
    selected: false,
    permissions: ['COVID_GET_COVIDDATA'],
  },
  {
    id: MENUID_COVID_DATA,
    level: 2,
    parentId: MENUID_COVID,
    label: LocalizedStringCovid.covidDataPage.title,
    value: ROUTE_NAME_COVID_DATA,
    icon: <div />,
    selected: false,
    permissions: ['COVID_CREATE_COVIDDATA'],
  },
];

export default menuList;
