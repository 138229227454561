import { savingQuizResult } from '../simple-action';
import { saveQuizResult } from '../../../helper';
import '../../../../../component/functional-page/download-file.css';

export default () => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { tappedId } = getState().uiFunctionalPage;

  try {
    dispatch(savingQuizResult(true));

    const response = await saveQuizResult(tappedId, token);

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'quiz-result.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(savingQuizResult(false));
  }
};
