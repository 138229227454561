import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  AppBar, Avatar, Box, CssBaseline, Divider, Drawer, ExpansionPanel, ExpansionPanelSummary,
  IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography, useTheme,
} from '@material-ui/core';
import {
  ChevronLeft, ChevronRight, ExitToApp, ExpandMore, Menu,
} from '@material-ui/icons';
import {
  COLOR_BACKGROUND, COLOR_BACKGROUND_SECONDARY, COLOR_PRIMARY, COLOR_SECONDARY,
} from '../../constant';
import { MenuItemShape } from '../../type';
import LocalizedString from '../../localization';
import AlertBox from '../alert-box';
import ErrorBoundary from '../error-boundary';

const DRAWER_WIDTH = 259;
const APP_BAR_HEIGHT = 107;

const logo = require('../../asset/logo.png');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    background: COLOR_BACKGROUND_SECONDARY,
    display: 'flex',
    justifyContent: 'space-between',
    height: APP_BAR_HEIGHT,
  },
  openedToolbar: {
    justifyContent: 'flex-end',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    background: COLOR_BACKGROUND,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  hide: {
    display: 'none',
  },
  welcomeField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  welcomeText: {
    color: COLOR_SECONDARY,
  },
  logoutIcon: {
    color: COLOR_PRIMARY,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: APP_BAR_HEIGHT,
  },
  list: {
    paddingTop: 0,
  },
  menuBox: {
    borderColor: COLOR_PRIMARY,
  },
  activeMenu: {
    color: COLOR_SECONDARY,
  },
  avatarContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  square: {
    width: 80,
    height: 61,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const onDrawerMenuPressed = (item, history, onMenuPressed, onSideMenuPressed) => {
  if (!onSideMenuPressed) {
    onMenuPressed(item, history);
  } else {
    onSideMenuPressed(item, history);
  }
};

const renderExpansionPanelSummary = (
  item, classes, onMenuPressed, history, onSideMenuPressed,
) => (
  <Box borderRight={item.selected ? 5 : 0} className={classes.menuBox} key={`${item.id}${item.label}`}>
    <ExpansionPanelSummary
      expandIcon={item.children && item.children.length > 0 ? <ExpandMore /> : null}
    >
      <ListItem
        button
        disableGutters
        key={`${item.id}${item.label}`}
        onClick={item.children && item.children.length > 0
          ? () => {}
          : () => onDrawerMenuPressed(item, history, onMenuPressed, onSideMenuPressed)}
      >
        <ListItemIcon className={item.selected ? classes.activeMenu : ''}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} className={item.selected ? classes.activeMenu : ''} />
      </ListItem>
    </ExpansionPanelSummary>
  </Box>
);

const renderExpansionMenuPanel = (item, classes, onMenuPressed,
  history, expanded, onExpansionPanelChanged, expandedMenuId,
  onSideMenuPressed) => (
    <ExpansionPanel
      expanded={item.children && item.children.length > 0
        && item.id === expandedMenuId ? expanded : false}
      onChange={item.children && item.children.length > 0
        ? () => onExpansionPanelChanged(expanded, item.id, expandedMenuId) : () => {}}
      key={`${item.id}${item.label}`}
    >
      {renderExpansionPanelSummary(item, classes, onMenuPressed, history, onSideMenuPressed)}
      {item.children && item.children.length > 0
        ? item.children.map((child) => (renderExpansionPanelSummary(child,
          classes, onMenuPressed, history, onSideMenuPressed)))
        : null }
    </ExpansionPanel>
);

const BasePage = ({
  menuItems, expanded, visibility,
  onDrawerPressed, onConfirmLogoutPressed, onConfirmPressed,
  onExpansionPanelChanged, onLogoutPressed, onMenuPressed,
  expandedMenuId, children, history,
  currentUserName, onSideMenuPressed,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: visibility,
        })}
      >
        <Toolbar className={clsx(classes.toolbar, visibility && classes.openedToolbar)}>
          <IconButton
            aria-label="open drawer"
            onClick={() => onDrawerPressed(visibility)}
            edge="start"
            className={clsx(classes.menuButton, visibility && classes.hide)}
          >
            <Menu />
          </IconButton>

          <span className={classes.welcomeField}>
            <Typography variant="body1" className={classes.welcomeText}>
              {LocalizedString.common.labelHi.replace(/\{name\}/, currentUserName)}
            </Typography>
            <IconButton
              aria-label="logout"
              onClick={() => onLogoutPressed()}
              edge="end"
            >
              <ExitToApp className={classes.logoutIcon} />
            </IconButton>
            <AlertBox
              onConfirmPressed={(message, reason) => onConfirmLogoutPressed(message, reason,
                history, onConfirmPressed)}
            />
          </span>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={visibility}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.avatarContainer}>
            <Avatar variant="square" className={classes.square} src={logo} />
          </div>
          <IconButton onClick={() => onDrawerPressed(visibility)}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List className={classes.list}>
          {menuItems.map((item) => {
            if (item.level === 1) {
              return renderExpansionMenuPanel(item, classes, onMenuPressed, history,
                expanded, onExpansionPanelChanged, expandedMenuId, onSideMenuPressed);
            }
            return null;
          })}
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: visibility,
        })}
      >
        <div className={classes.drawerHeader} />
        <ErrorBoundary>
          {children}
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default withRouter(BasePage);

BasePage.propTypes = {
  menuItems: PropTypes.arrayOf(MenuItemShape).isRequired,
  expanded: PropTypes.bool.isRequired,
  visibility: PropTypes.bool.isRequired,
  onConfirmLogoutPressed: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func,
  onDrawerPressed: PropTypes.func.isRequired,
  onExpansionPanelChanged: PropTypes.func.isRequired,
  onMenuPressed: PropTypes.func.isRequired,
  onLogoutPressed: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  currentUserName: PropTypes.string.isRequired,
  expandedMenuId: PropTypes.string.isRequired,
  onSideMenuPressed: PropTypes.func,
};

BasePage.defaultProps = {
  onConfirmPressed: () => {},
  onSideMenuPressed: null,
};
