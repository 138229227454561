import PropTypes from 'prop-types';

export const MenuItemShape = PropTypes.shape({
  id: PropTypes.string,
  level: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  selected: PropTypes.bool,
  parentId: PropTypes.string,
  icon: PropTypes.node,
});

export const TableColumnShape = PropTypes.shape({
  title: PropTypes.string,
  field: PropTypes.string,
  sorting: PropTypes.bool,
});

export const FormInitialValueShape = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
  ]),
);

export const SimpleDataShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const SummaryCountShape = PropTypes.shape({
  name: PropTypes.string,
  count: PropTypes.number,
});

export const TotalCountShape = PropTypes.shape({
  downloading: PropTypes.bool,
  icon: PropTypes.node,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const PointShape = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

export const MoreMenuShape = PropTypes.shape({
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
});
