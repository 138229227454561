import { connect } from 'react-redux';
import {
  CONFIG_ACTION_RESYNC_CONFIGS, CONFIG_ACTION_RESYNC_DB_USERS, CONFIG_ACTION_RESYNC_MASTER_DATA,
  CONFIG_ACTION_RESYNC_USERS, MENUID_CONFIGURATION_CONFIG_ACTION, RXFIELD_CONFIG_ACTION_MASTER_DATA,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage, setSelectedResyncAction,
  resyncConfigsAsync, resyncDBUsersAsync, resyncMasterDataAsync, resyncUsersAsync,
  setSelectedMasterDataResyncAction, clearSelectedMasterDataResyncAction,
} from '../../redux/action';
import ConfigActionPage from './config-action.presentation';

const mapStateToProps = (state) => ({
  loadingCongfigs: state.uiConfigAction.loadingCongfigs,
  loadingDBUsers: state.uiConfigAction.loadingDBUsers,
  loadingMasterData: state.uiConfigAction.loadingMasterData,
  loadingUsers: state.uiConfigAction.loadingUsers,
  selectedResyncAction: state.uiConfigAction.selectedResyncAction,
  selectedMasterData: state.uiConfigAction.selectedMasterData,
  initialValues: {
    [RXFIELD_CONFIG_ACTION_MASTER_DATA]: state.uiConfigAction.selectedMasterData,
  },
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CONFIGURATION_CONFIG_ACTION));
    dispatch(setSelectedResyncAction(''));
    dispatch(clearSelectedMasterDataResyncAction());
  },
  onConfirmResyncPressed: (option) => {
    switch (option) {
      case CONFIG_ACTION_RESYNC_CONFIGS: {
        dispatch(resyncConfigsAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      }
      case CONFIG_ACTION_RESYNC_DB_USERS: {
        dispatch(resyncDBUsersAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      }
      case CONFIG_ACTION_RESYNC_MASTER_DATA: {
        dispatch(resyncMasterDataAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      }
      case CONFIG_ACTION_RESYNC_USERS: {
        dispatch(resyncUsersAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
        break;
      }
      default: {
        dispatch(setSelectedResyncAction(''));
        break;
      }
    }
  },
  onResyncPressed: (option) => {
    dispatch(setSelectedResyncAction(option));
    dispatch(setAlertConfirmationMessage(LocalizedString.configActionScreen.msgResyncConfirmation));
  },
  onSelectedMasterData: (option) => {
    dispatch(setSelectedMasterDataResyncAction(option));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigActionPage);
