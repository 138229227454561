import moment from 'moment';
import {
  TIME_FORMAT,
  RXFIELD_USERNAME, RXFIELD_PASSWORD,
  RXFIELD_PROVINCE_NAME, RXFIELD_PROVINCE_TIME_ZONE,
  RXFIELD_CITY_NAME,
  RXFIELD_BRANCH_ADDRESS, RXFIELD_BRANCH_AREA, RXFIELD_BRANCH_CITY,
  RXFIELD_BRANCH_END_AFTER_MIN, RXFIELD_BRANCH_END_BEFORE_MIN, RXFIELD_BRANCH_START_WORKING_HOUR,
  RXFIELD_BRANCH_END_WORKING_HOUR, RXFIELD_BRANCH_GOOGLE_MAP_URL, RXFIELD_BRANCH_LATITUDE,
  RXFIELD_BRANCH_LONGITUDE, RXFIELD_BRANCH_NAME,
  RXFIELD_BRANCH_START_AFTER_MIN, RXFIELD_BRANCH_START_BEFORE_MIN,
  RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES, RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_PERMISSION_NAME, RXFIELD_USER_USERNAME,
  RXFIELD_USER_FULLNAME, RXFIELD_USER_ROLES,
  RXFIELD_ROLE_DESCRIPTION, RXFIELD_ROLE_NAME, RXFIELD_ROLE_PERMISSION,
  RXFIELD_API_KEY_SCOPE, RXFIELD_API_KEY_CONSUMER, RXFIELD_API_KEY_OWNER,
  RXFIELD_APPLICATION_ICON, RXFIELD_APPLICATION_NAME,
  RXFIELD_APPLICATION_ORDER, RXFIELD_APPLICATION_PERMISSION, RXFIELD_APPLICATION_URL,
  RXFIELD_PROFILE_BRANCH, RXFIELD_PROFILE_COMPANY_NAME, RXFIELD_PROFILE_DEPARTMENT,
  RXFIELD_PROFILE_EMPLOYEE_ID, RXFIELD_PROFILE_GENDER,
  RXFIELD_PROFILE_JOB_TITLE,
  RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE, RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY,
  RXFIELD_SERVICE_NAME, RXFIELD_SERVICE_DESCRIPTION,
  RXFIELD_SCOPE_NAME, RXFIELD_SCOPE_DESCRIPTION,
  RXFIELD_BANNER_CLICK_ACTION, RXFIELD_BANNER_MEDIA, RXFIELD_BANNER_ORDER, RXFIELD_BANNER_TITLE,
  RXFIELD_BANNER_VALID_FROM, RXFIELD_BANNER_VALID_UNTIL,
  RXFIELD_COMPANY_CITY, RXFIELD_COMPANY_NAME,
  RXFIELD_DISTRICT_NAME, RXFIELD_SUBDISTRICT_NAME,
  RXFIELD_DISTRICT_CITY, RXFIELD_SUBDISTRICT_DISTRICT,
} from './constant';
import LocalizedString from './localization';

export const rxFormValidateRequiredFields = (values, requiredFields) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if ((values[field] instanceof Array && values[field].length === 0)
    || (!values[field] && typeof values[field] !== 'number')
    || (((values[field] instanceof Array) === false) && typeof values[field] === 'object' && typeof values[field].value !== 'boolean' && !values[field].value)
    || (typeof values[field] === 'string' && values[field].match('<p>(.*?)</p>')
    && !values[field].match('<p>(.*?)</p>')[1])) {
      errors[field] = LocalizedString.common.errMsgRequired;
    }
  });
  return errors;
};

export const rxformValidateLogin = (values) => {
  const requiredFields = [
    RXFIELD_USERNAME,
    RXFIELD_PASSWORD,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProvince = (values) => {
  const requiredFields = [
    RXFIELD_PROVINCE_NAME,
    RXFIELD_PROVINCE_TIME_ZONE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCity = (values) => {
  const requiredFields = [
    RXFIELD_CITY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

const rxformValidateWorkingHourFields = (values, requiredErrors) => {
  const errors = requiredErrors;

  const startHourOfDay = moment().startOf('day').format(TIME_FORMAT); // 00.00
  const endHourOfDay = moment().endOf('day').format(TIME_FORMAT); // 23.59
  const NIGHT_START_TIME = '20:00';
  const NIGHT_END_TIME = '03:59';

  if (moment(values.endWorkingHour, TIME_FORMAT)
    .isSame(moment(values.startWorkingHour, TIME_FORMAT))) {
    errors.endWorkingHour = LocalizedString.common.errMsgStartEndWorkingHour;
  }
  if (!(moment(values.startWorkingHour, TIME_FORMAT)
    .isBetween(moment(startHourOfDay, TIME_FORMAT), moment(NIGHT_END_TIME, TIME_FORMAT)))
  && moment(values.earliestClockInDriftTimeMinutes, TIME_FORMAT)
    .isAfter(moment(values.startWorkingHour, TIME_FORMAT))) {
    errors.earliestClockInDriftTimeMinutes = LocalizedString.common.errMsgEarliestClockInTime;
  }
  if (!(moment(values.endWorkingHour, TIME_FORMAT)
    .isBetween(moment(NIGHT_START_TIME, TIME_FORMAT), moment(endHourOfDay, TIME_FORMAT)))
    && moment(values.autoClockOutAfterMinutes, TIME_FORMAT)
      .isBefore(moment(values.endWorkingHour, TIME_FORMAT))) {
    errors.autoClockOutAfterMinutes = LocalizedString.common.errMsgAutoClockOut;
  }
  return errors;
};

export const rxformValidateBranch = (values) => {
  const requiredFields = [
    RXFIELD_BRANCH_ADDRESS,
    RXFIELD_BRANCH_AREA,
    RXFIELD_BRANCH_CITY,
    RXFIELD_BRANCH_END_AFTER_MIN,
    RXFIELD_BRANCH_END_BEFORE_MIN,
    RXFIELD_BRANCH_END_WORKING_HOUR,
    RXFIELD_BRANCH_GOOGLE_MAP_URL,
    RXFIELD_BRANCH_LATITUDE,
    RXFIELD_BRANCH_LONGITUDE,
    RXFIELD_BRANCH_NAME,
    RXFIELD_BRANCH_START_AFTER_MIN,
    RXFIELD_BRANCH_START_BEFORE_MIN,
    RXFIELD_BRANCH_START_WORKING_HOUR,
    RXFIELD_BRANCH_AUTO_CLOCK_OUT_AFTER_MINUTES,
    RXFIELD_BRANCH_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidateCompanyMdata = (values) => {
  const requiredFields = [
    RXFIELD_COMPANY_CITY,
    RXFIELD_COMPANY_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidatePermission = (values) => {
  const requiredFields = [
    RXFIELD_PERMISSION_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateUser = (values) => {
  const requiredFields = [
    RXFIELD_USER_USERNAME,
    RXFIELD_USER_FULLNAME,
    RXFIELD_USER_ROLES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateRole = (values) => {
  const requiredFields = [
    RXFIELD_ROLE_NAME,
    RXFIELD_ROLE_PERMISSION,
    RXFIELD_ROLE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApiKey = (values) => {
  const requiredFields = [
    RXFIELD_API_KEY_SCOPE,
    RXFIELD_API_KEY_CONSUMER,
    RXFIELD_API_KEY_OWNER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateApplication = (values) => {
  const requiredFields = [
    RXFIELD_APPLICATION_ICON,
    RXFIELD_APPLICATION_NAME,
    RXFIELD_APPLICATION_ORDER,
    RXFIELD_APPLICATION_PERMISSION,
    RXFIELD_APPLICATION_URL,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateProfile = (values) => {
  const requiredFields = [
    RXFIELD_PROFILE_EMPLOYEE_ID,
    RXFIELD_PROFILE_GENDER,
    RXFIELD_PROFILE_JOB_TITLE,
    RXFIELD_PROFILE_DEPARTMENT,
    RXFIELD_PROFILE_COMPANY_NAME,
    RXFIELD_PROFILE_BRANCH,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.startWorkingHour && !values.endWorkingHour) {
    errors.endWorkingHour = LocalizedString.common.errMsgRequired;
  }
  if (!values.startWorkingHour && values.endWorkingHour) {
    errors.startWorkingHour = LocalizedString.common.errMsgRequired;
  }
  return rxformValidateWorkingHourFields(values, errors);
};

export const rxformValidateSendPersonalNotification = (values) => {
  const requiredFields = [
    RXFIELD_SEND_PERSONAL_NOTIFICATION_TITLE,
    RXFIELD_SEND_PERSONAL_NOTIFICATION_BODY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateService = (values) => {
  const requiredFields = [
    RXFIELD_SERVICE_NAME,
    RXFIELD_SERVICE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateScope = (values) => {
  const requiredFields = [
    RXFIELD_SCOPE_NAME,
    RXFIELD_SCOPE_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateBanner = (values) => {
  const requiredFields = [
    RXFIELD_BANNER_TITLE,
    RXFIELD_BANNER_CLICK_ACTION,
    RXFIELD_BANNER_VALID_FROM,
    RXFIELD_BANNER_VALID_UNTIL,
    RXFIELD_BANNER_ORDER,
    RXFIELD_BANNER_MEDIA,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!moment(values.validUntil).isAfter(moment(values.validFrom))) {
    errors.validUntil = LocalizedString.common.errMsgStartEndDate;
  }
  return errors;
};

export const rxformValidateDistrict = (values) => {
  const requiredFields = [
    RXFIELD_DISTRICT_NAME,
    RXFIELD_DISTRICT_CITY,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSubdistrict = (values) => {
  const requiredFields = [
    RXFIELD_SUBDISTRICT_NAME,
    RXFIELD_SUBDISTRICT_DISTRICT,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
