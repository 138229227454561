const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};

export const ADD_TOKEN = 'ADD_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_OUT = 'LOGGING_OUT';

export const CLEAR_ALL_STATES = 'CLEAR_ALL_STATES';

export const ADD_CURRENT_USER = 'ADD_CURRENT_USER';

export const addCurrentUser = (user) => ({
  type: ADD_CURRENT_USER,
  user,
});

export const addToken = (token) => ({
  type: ADD_TOKEN,
  token,
});

export const removeToken = () => ({
  type: REMOVE_TOKEN,
});

export const loggingIn = (status) => ({ type: LOGGING_IN, status });
export const loggingOut = (status) => ({ type: LOGGING_OUT, status });

export const clearAllStates = () => ({
  type: CLEAR_ALL_STATES,
});

export const SET_ALERT_ERROR_MESSAGE = 'SET_ALERT_ERROR_MESSAGE';
export const setAlertErrorMessage = makeActionCreator(SET_ALERT_ERROR_MESSAGE, 'text');

export const SET_ALERT_INFO_MESSAGE = 'SET_ALERT_INFO_MESSAGE';
export const setAlertInfoMessage = makeActionCreator(SET_ALERT_INFO_MESSAGE, 'text');

export const SET_ALERT_WARNING_MESSAGE = 'SET_ALERT_WARNING_MESSAGE';
export const setAlertWarningMessage = makeActionCreator(SET_ALERT_WARNING_MESSAGE, 'text');

export const SET_ALERT_CONFIRMATION_MESSAGE = 'SET_ALERT_CONFIRMATION_MESSAGE';
export const setAlertConfirmationMessage = makeActionCreator(SET_ALERT_CONFIRMATION_MESSAGE, 'text');

export const SET_ALERT_INPUT_MESSAGE = 'SET_ALERT_INPUT_MESSAGE';
export const setAlertInputMessage = makeActionCreator(SET_ALERT_INPUT_MESSAGE, 'text', 'placeholder');

export const SET_ALERT_FORCE_POPUP = 'SET_ALERT_FORCE_POPUP';
export const setAlertForcePopUp = makeActionCreator(SET_ALERT_FORCE_POPUP, 'status');


export const SET_ACTIVE_SIDEMENU_ITEM = 'SET_ACTIVE_SIDEMENU_ITEM';
export const setActiveSideMenuItem = makeActionCreator(SET_ACTIVE_SIDEMENU_ITEM, 'id');

export const RESET_SIDEMENU_STATE = 'RESET_SIDEMENU_STATE';
export const resetSideMenuState = makeActionCreator(RESET_SIDEMENU_STATE);


export const DOWNLOADING_PROVINCES = 'DOWNLOADING_PROVINCES';
export const downloadingProvinces = makeActionCreator(DOWNLOADING_PROVINCES, 'status');

export const SET_PROVINCES = 'SET_PROVINCES';
export const setProvince = makeActionCreator(SET_PROVINCES, 'data');

export const ADD_PROVINCE = 'ADD_PROVINCE';
export const addProvince = makeActionCreator(ADD_PROVINCE, 'data');

export const CLEAR_PROVINCES = 'CLEAR_PROVINCES';
export const clearProvinces = makeActionCreator(CLEAR_PROVINCES);

export const ADDING_EDITING_PROVINCE = 'ADDING_EDITING_PROVINCE';
export const addingEditingProvince = makeActionCreator(ADDING_EDITING_PROVINCE, 'status');

export const DOWNLOADING_DELETING_PROVINCE = 'DOWNLOADING_DELETING_PROVINCE';
export const downloadingDeletingProvince = makeActionCreator(DOWNLOADING_DELETING_PROVINCE, 'status');

export const ENABLING_DISABLING_PROVINCE = 'ENABLING_DISABLING_PROVINCE';
export const enablingDisablingProvince = makeActionCreator(ENABLING_DISABLING_PROVINCE, 'status');

export const SAVING_PROVINCES = 'SAVING_PROVINCES';
export const savingProvinces = makeActionCreator(SAVING_PROVINCES, 'status');

export const RESYNCING_PROVINCE = 'RESYNCING_PROVINCE';
export const resyncingProvince = makeActionCreator(RESYNCING_PROVINCE, 'status');


export const SET_PROVINCE_SELECTED_PAGE_SIZE = 'SET_PROVINCE_SELECTED_PAGE_SIZE';
export const setProvinceSelectedPageSize = makeActionCreator(SET_PROVINCE_SELECTED_PAGE_SIZE, 'size');

export const SET_PROVINCE_SELECTED_ORDER_BY = 'SET_PROVINCE_SELECTED_ORDER_BY';
export const setProvinceSelectedOrderBy = makeActionCreator(SET_PROVINCE_SELECTED_ORDER_BY, 'order');

export const SET_PROVINCE_TAPPED_ID = 'SET_PROVINCE_TAPPED_ID';
export const setProvinceTappedId = makeActionCreator(SET_PROVINCE_TAPPED_ID, 'id');

export const SET_PROVINCE_SEARCH_TEXT = 'SET_PROVINCE_SEARCH_TEXT';
export const setProvinceSearchText = makeActionCreator(SET_PROVINCE_SEARCH_TEXT, 'text');

export const SET_PROVINCE_SELECTED_TIMEZONE = 'SET_PROVINCE_SELECTED_TIMEZONE';
export const setProvinceSelectedTimezone = makeActionCreator(SET_PROVINCE_SELECTED_TIMEZONE, 'text');

export const SET_PROVINCE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_PROVINCE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setProvinceAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_PROVINCE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_CITIES = 'DOWNLOADING_CITIES';
export const downloadingCities = makeActionCreator(DOWNLOADING_CITIES, 'status');

export const SET_CITIES = 'SET_CITIES';
export const setCities = makeActionCreator(SET_CITIES, 'data');

export const ADD_CITY = 'ADD_CITY';
export const addCity = makeActionCreator(ADD_CITY, 'data');

export const CLEAR_CITIES = 'CLEAR_CITIES';
export const clearCities = makeActionCreator(CLEAR_CITIES);

export const ADDING_EDITING_CITY = 'ADDING_EDITING_CITY';
export const addingEditingCity = makeActionCreator(ADDING_EDITING_CITY, 'status');

export const DOWNLOADING_DELETING_CITY = 'DOWNLOADING_DELETING_CITY';
export const downloadingDeletingCity = makeActionCreator(DOWNLOADING_DELETING_CITY, 'status');

export const ENABLING_DISABLING_CITY = 'ENABLING_DISABLING_CITY';
export const enablingDisablingCity = makeActionCreator(ENABLING_DISABLING_CITY, 'status');

export const SAVING_CITIES = 'SAVING_CITIES';
export const savingCities = makeActionCreator(SAVING_CITIES, 'status');

export const RESYNCING_CITY = 'RESYNCING_CITY';
export const resyncingCity = makeActionCreator(RESYNCING_CITY, 'status');


export const SET_CITY_SELECTED_PAGE_SIZE = 'SET_CITY_SELECTED_PAGE_SIZE';
export const setCitySelectedPageSize = makeActionCreator(SET_CITY_SELECTED_PAGE_SIZE, 'size');

export const SET_CITY_SELECTED_ORDER_BY = 'SET_CITY_SELECTED_ORDER_BY';
export const setCitySelectedOrderBy = makeActionCreator(SET_CITY_SELECTED_ORDER_BY, 'order');

export const SET_CITY_TAPPED_ID = 'SET_CITY_TAPPED_ID';
export const setCityTappedId = makeActionCreator(SET_CITY_TAPPED_ID, 'id');

export const SET_CITY_SEARCH_TEXT = 'SET_CITY_SEARCH_TEXT';
export const setCitySearchText = makeActionCreator(SET_CITY_SEARCH_TEXT, 'text');

export const SET_CITY_SELECTED_PROVINCE_ID = 'SET_CITY_SELECTED_PROVINCE_ID';
export const setCitySelectedProvinceId = makeActionCreator(SET_CITY_SELECTED_PROVINCE_ID, 'id');

export const SET_CITY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CITY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCityAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CITY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const SET_DRAWER_VISIBILITY = 'SET_DRAWER_VISIBILITY';
export const setDrawerVisibility = makeActionCreator(SET_DRAWER_VISIBILITY, 'status');

export const SET_DRAWER_MENU_EXPANSION = 'SET_DRAWER_MENU_EXPANSION';
export const setDrawerMenuExpansion = makeActionCreator(SET_DRAWER_MENU_EXPANSION, 'status', 'id');


export const SET_DIALOG_VISIBILITY = 'SET_DIALOG_VISIBILITY';
export const setDialogVisibility = makeActionCreator(SET_DIALOG_VISIBILITY, 'status', 'dialogType', 'title');

export const SET_ADVANCED_FILTER_DIALOG_VISIBILITY = 'SET_ADVANCED_FILTER_DIALOG_VISIBILITY';
export const setAdvancedFilterDialogVisibility = makeActionCreator(SET_ADVANCED_FILTER_DIALOG_VISIBILITY, 'status');

export const SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_FUNCTIONAL_PAGE_TAPPED_ID = 'SET_FUNCTIONAL_PAGE_TAPPED_ID';
export const setFunctionalPageTappedId = makeActionCreator(SET_FUNCTIONAL_PAGE_TAPPED_ID, 'id');

export const SET_FUNCTIONAL_PAGE_MODE = 'SET_FUNCTIONAL_PAGE_MODE';
export const setFunctionalPageMode = makeActionCreator(SET_FUNCTIONAL_PAGE_MODE, 'mode');

export const ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM = 'ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM';
export const addFunctionalPageAdvancedFilterForm = makeActionCreator(ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM, 'field', 'value');

export const CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM = 'CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM';
export const clearFunctionalPageAdvancedFilterForm = makeActionCreator(
  CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM,
);


export const DOWNLOADING_BRANCHES = 'DOWNLOADING_BRANCHES';
export const downloadingBranches = makeActionCreator(DOWNLOADING_BRANCHES, 'status');

export const SET_BRANCHES = 'SET_BRANCHES';
export const setBranches = makeActionCreator(SET_BRANCHES, 'data');

export const ADD_BRANCH = 'ADD_BRANCH';
export const addBranch = makeActionCreator(ADD_BRANCH, 'data');

export const CLEAR_BRANCHES = 'CLEAR_BRANCHES';
export const clearBranches = makeActionCreator(CLEAR_BRANCHES);

export const ADDING_EDITING_BRANCH = 'ADDING_EDITING_BRANCH';
export const addingEditingBranch = makeActionCreator(ADDING_EDITING_BRANCH, 'status');

export const DOWNLOADING_DELETING_BRANCH = 'DOWNLOADING_DELETING_BRANCH';
export const downloadingDeletingBranch = makeActionCreator(DOWNLOADING_DELETING_BRANCH, 'status');

export const ENABLING_DISABLING_BRANCH = 'ENABLING_DISABLING_BRANCH';
export const enablingDisablingBranch = makeActionCreator(ENABLING_DISABLING_BRANCH, 'status');

export const SAVING_BRANCHES = 'SAVING_BRANCHES';
export const savingBranches = makeActionCreator(SAVING_BRANCHES, 'status');

export const RESYNCING_BRANCH = 'RESYNCING_BRANCH';
export const resyncingBranch = makeActionCreator(RESYNCING_BRANCH, 'status');


export const SET_BRANCH_SELECTED_PAGE_SIZE = 'SET_BRANCH_SELECTED_PAGE_SIZE';
export const setBranchSelectedPageSize = makeActionCreator(SET_BRANCH_SELECTED_PAGE_SIZE, 'size');

export const SET_BRANCH_SELECTED_ORDER_BY = 'SET_BRANCH_SELECTED_ORDER_BY';
export const setBranchSelectedOrderBy = makeActionCreator(SET_BRANCH_SELECTED_ORDER_BY, 'order');

export const SET_BRANCH_TAPPED_ID = 'SET_BRANCH_TAPPED_ID';
export const setBranchTappedId = makeActionCreator(SET_BRANCH_TAPPED_ID, 'id');

export const SET_BRANCH_SEARCH_TEXT = 'SET_BRANCH_SEARCH_TEXT';
export const setBranchSearchText = makeActionCreator(SET_BRANCH_SEARCH_TEXT, 'text');

export const SET_BRANCH_SELECTED_CITY_ID = 'SET_BRANCH_SELECTED_CITY_ID';
export const setBranchSelectedCityId = makeActionCreator(SET_BRANCH_SELECTED_CITY_ID, 'id');

export const SET_BRANCH_MAP_CENTER = 'SET_BRANCH_MAP_CENTER';
export const setBranchMapCenter = makeActionCreator(SET_BRANCH_MAP_CENTER, 'data');

export const SET_BRANCH_MAP_MARKER = 'SET_BRANCH_MAP_MARKER';
export const setBranchMapMarker = makeActionCreator(SET_BRANCH_MAP_MARKER, 'data');

export const SET_BRANCH_MAP_POLYGON = 'SET_BRANCH_MAP_POLYGON';
export const setBranchMapPolygon = makeActionCreator(SET_BRANCH_MAP_POLYGON, 'data');

export const SET_BRANCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_BRANCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setBranchAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_BRANCH_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_BRANCH_SELECTED_COMPANY_ID = 'SET_BRANCH_SELECTED_COMPANY_ID';
export const setBranchSelectedCompanyId = makeActionCreator(SET_BRANCH_SELECTED_COMPANY_ID, 'id');


export const DOWNLOADING_PERMISSIONS = 'DOWNLOADING_PERMISSIONS';
export const downloadingPermissions = makeActionCreator(DOWNLOADING_PERMISSIONS, 'status');

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const setPermissions = makeActionCreator(SET_PERMISSIONS, 'data');

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const addPermission = makeActionCreator(ADD_PERMISSION, 'data');

export const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS';
export const clearPermissions = makeActionCreator(CLEAR_PERMISSIONS);

export const ADDING_EDITING_PERMISSION = 'ADDING_EDITING_PERMISSION';
export const addingEditingPermission = makeActionCreator(ADDING_EDITING_PERMISSION, 'status');

export const DOWNLOADING_DELETING_PERMISSION = 'DOWNLOADING_DELETING_PERMISSION';
export const downloadingDeletingPermission = makeActionCreator(DOWNLOADING_DELETING_PERMISSION, 'status');


export const SET_PERMISSION_SELECTED_PAGE_SIZE = 'SET_PERMISSION_SELECTED_PAGE_SIZE';
export const setPermissionSelectedPageSize = makeActionCreator(SET_PERMISSION_SELECTED_PAGE_SIZE, 'size');

export const SET_PERMISSION_SELECTED_ORDER_BY = 'SET_PERMISSION_SELECTED_ORDER_BY';
export const setPermissionSelectedOrderBy = makeActionCreator(SET_PERMISSION_SELECTED_ORDER_BY, 'order');

export const SET_PERMISSION_TAPPED_ID = 'SET_PERMISSION_TAPPED_ID';
export const setPermissionTappedId = makeActionCreator(SET_PERMISSION_TAPPED_ID, 'id');

export const SET_PERMISSION_SEARCH_TEXT = 'SET_PERMISSION_SEARCH_TEXT';
export const setPermissionSearchText = makeActionCreator(SET_PERMISSION_SEARCH_TEXT, 'text');


export const DOWNLOADING_USERS = 'DOWNLOADING_USERS';
export const downloadingUsers = makeActionCreator(DOWNLOADING_USERS, 'status');

export const SET_USERS = 'SET_USERS';
export const setUsers = makeActionCreator(SET_USERS, 'data');

export const ADD_USER = 'ADD_USER';
export const addUser = makeActionCreator(ADD_USER, 'data');

export const CLEAR_USERS = 'CLEAR_USERS';
export const clearUsers = makeActionCreator(CLEAR_USERS);

export const ADDING_EDITING_USER = 'ADDING_EDITING_USER';
export const addingEditingUser = makeActionCreator(ADDING_EDITING_USER, 'status');

export const DOWNLOADING_DELETING_USER = 'DOWNLOADING_DELETING_USER';
export const downloadingDeletingUser = makeActionCreator(DOWNLOADING_DELETING_USER, 'status');

export const RESYNCING_USER = 'RESYNCING_USER';
export const resyncingUser = makeActionCreator(RESYNCING_USER, 'status');


export const SET_USER_SELECTED_PAGE_SIZE = 'SET_USER_SELECTED_PAGE_SIZE';
export const setUserSelectedPageSize = makeActionCreator(SET_USER_SELECTED_PAGE_SIZE, 'size');

export const SET_USER_SELECTED_ORDER_BY = 'SET_USER_SELECTED_ORDER_BY';
export const setUserSelectedOrderBy = makeActionCreator(SET_USER_SELECTED_ORDER_BY, 'order');

export const SET_USER_TAPPED_ID = 'SET_USER_TAPPED_ID';
export const setUserTappedId = makeActionCreator(SET_USER_TAPPED_ID, 'id');

export const SET_USER_SEARCH_TEXT = 'SET_USER_SEARCH_TEXT';
export const setUserSearchText = makeActionCreator(SET_USER_SEARCH_TEXT, 'text');

export const SET_USER_SELECTED_ROLE = 'SET_USER_SELECTED_ROLE';
export const setUserSelectedRole = makeActionCreator(SET_USER_SELECTED_ROLE, 'option');

export const SET_USER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_USER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setUserAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_USER_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_ROLES = 'DOWNLOADING_ROLES';
export const downloadingRoles = makeActionCreator(DOWNLOADING_ROLES, 'status');

export const SET_ROLES = 'SET_ROLES';
export const setRoles = makeActionCreator(SET_ROLES, 'data');

export const ADD_ROLE = 'ADD_ROLE';
export const addRole = makeActionCreator(ADD_ROLE, 'data');

export const CLEAR_ROLES = 'CLEAR_ROLES';
export const clearRoles = makeActionCreator(CLEAR_ROLES);

export const ADDING_EDITING_ROLE = 'ADDING_EDITING_ROLE';
export const addingEditingRole = makeActionCreator(ADDING_EDITING_ROLE, 'status');

export const DOWNLOADING_DELETING_ROLE = 'DOWNLOADING_DELETING_ROLE';
export const downloadingDeletingRole = makeActionCreator(DOWNLOADING_DELETING_ROLE, 'status');


export const SET_ROLE_SELECTED_PAGE_SIZE = 'SET_ROLE_SELECTED_PAGE_SIZE';
export const setRoleSelectedPageSize = makeActionCreator(SET_ROLE_SELECTED_PAGE_SIZE, 'size');

export const SET_ROLE_SELECTED_ORDER_BY = 'SET_ROLE_SELECTED_ORDER_BY';
export const setRoleSelectedOrderBy = makeActionCreator(SET_ROLE_SELECTED_ORDER_BY, 'order');

export const SET_ROLE_TAPPED_ID = 'SET_ROLE_TAPPED_ID';
export const setRoleTappedId = makeActionCreator(SET_ROLE_TAPPED_ID, 'id');

export const SET_ROLE_SEARCH_TEXT = 'SET_ROLE_SEARCH_TEXT';
export const setRoleSearchText = makeActionCreator(SET_ROLE_SEARCH_TEXT, 'text');

export const SET_ROLE_SELECTED_PERMISSION = 'SET_ROLE_SELECTED_PERMISSION';
export const setRoleSelectedPermission = makeActionCreator(SET_ROLE_SELECTED_PERMISSION, 'option');


export const DOWNLOADING_API_KEYS = 'DOWNLOADING_API_KEYS';
export const downloadingApiKeys = makeActionCreator(DOWNLOADING_API_KEYS, 'status');

export const SET_API_KEYS = 'SET_API_KEYS';
export const setApiKeys = makeActionCreator(SET_API_KEYS, 'data');

export const ADD_API_KEY = 'ADD_API_KEY';
export const addApiKey = makeActionCreator(ADD_API_KEY, 'data');

export const CLEAR_API_KEYS = 'CLEAR_API_KEYS';
export const clearApiKeys = makeActionCreator(CLEAR_API_KEYS);

export const ADDING_EDITING_API_KEY = 'ADDING_EDITING_API_KEY';
export const addingEditingApiKey = makeActionCreator(ADDING_EDITING_API_KEY, 'status');

export const DOWNLOADING_DELETING_API_KEY = 'DOWNLOADING_DELETING_API_KEY';
export const downloadingDeletingApiKey = makeActionCreator(DOWNLOADING_DELETING_API_KEY, 'status');


export const SET_API_KEY_SELECTED_PAGE_SIZE = 'SET_API_KEY_SELECTED_PAGE_SIZE';
export const setApiKeySelectedPageSize = makeActionCreator(SET_API_KEY_SELECTED_PAGE_SIZE, 'size');

export const SET_API_KEY_SELECTED_ORDER_BY = 'SET_API_KEY_SELECTED_ORDER_BY';
export const setApiKeySelectedOrderBy = makeActionCreator(SET_API_KEY_SELECTED_ORDER_BY, 'order');

export const SET_API_KEY_TAPPED_ID = 'SET_API_KEY_TAPPED_ID';
export const setApiKeyTappedId = makeActionCreator(SET_API_KEY_TAPPED_ID, 'id');

export const SET_API_KEY_SEARCH_TEXT = 'SET_API_KEY_SEARCH_TEXT';
export const setApiKeySearchText = makeActionCreator(SET_API_KEY_SEARCH_TEXT, 'text');

export const SET_API_KEY_SELECTED_OWNER_APP_NAME = 'SET_API_KEY_SELECTED_OWNER_APP_NAME';
export const setApiKeySelectedOwnerAppName = makeActionCreator(SET_API_KEY_SELECTED_OWNER_APP_NAME, 'option');

export const SET_API_KEY_SELECTED_CONSUMER_APP_NAME = 'SET_API_KEY_SELECTED_CONSUMER_APP_NAME';
export const setApiKeySelectedConsumerAppName = makeActionCreator(SET_API_KEY_SELECTED_CONSUMER_APP_NAME, 'option');

export const SET_API_KEY_SELECTED_SCOPE = 'SET_API_KEY_SELECTED_SCOPE';
export const setApiKeySelectedScope = makeActionCreator(SET_API_KEY_SELECTED_SCOPE, 'option');

export const SET_API_KEY_SELECTED_ADVANCED_FILTER_OWNER_APP_NAME = 'SET_API_KEY_SELECTED_ADVANCED_FILTER_OWNER_APP_NAME';
export const setApiKeySelectedAdvancedFilterOwnerAppName = makeActionCreator(SET_API_KEY_SELECTED_ADVANCED_FILTER_OWNER_APP_NAME, 'option');

export const SET_API_KEY_SELECTED_ADVANCED_FILTER_CONSUMER_APP_NAME = 'SET_API_KEY_SELECTED_ADVANCED_FILTER_CONSUMER_APP_NAME';
export const setApiKeySelectedAdvancedFilterConsumerAppName = makeActionCreator(SET_API_KEY_SELECTED_ADVANCED_FILTER_CONSUMER_APP_NAME, 'option');

export const SET_API_KEY_SELECTED_ADVANCED_FILTER_SCOPE = 'SET_API_KEY_SELECTED_ADVANCED_FILTER_SCOPE';
export const setApiKeySelectedAdvancedFilterScope = makeActionCreator(SET_API_KEY_SELECTED_ADVANCED_FILTER_SCOPE, 'option');


export const DOWNLOADING_TOKENS = 'DOWNLOADING_TOKENS';
export const downloadingTokens = makeActionCreator(DOWNLOADING_TOKENS, 'status');

export const SET_TOKENS = 'SET_TOKENS';
export const setTokens = makeActionCreator(SET_TOKENS, 'data');

export const CLEAR_TOKENS = 'CLEAR_TOKENS';
export const clearTokens = makeActionCreator(CLEAR_TOKENS);

export const DOWNLOADING_DELETING_TOKEN = 'DOWNLOADING_DELETING_TOKEN';
export const downloadingDeletingToken = makeActionCreator(DOWNLOADING_DELETING_TOKEN, 'status');

export const DISABLING_TOKEN = 'DISABLING_TOKEN';
export const disablingToken = makeActionCreator(DISABLING_TOKEN, 'status');

export const SENDING_TOKEN_NOTIFICATION = 'SENDING_TOKEN_NOTIFICATION';
export const sendingTokenNotification = makeActionCreator(SENDING_TOKEN_NOTIFICATION, 'status');


export const SET_TOKEN_SELECTED_PAGE_SIZE = 'SET_TOKEN_SELECTED_PAGE_SIZE';
export const setTokenSelectedPageSize = makeActionCreator(SET_TOKEN_SELECTED_PAGE_SIZE, 'size');

export const SET_TOKEN_SELECTED_ORDER_BY = 'SET_TOKEN_SELECTED_ORDER_BY';
export const setTokenSelectedOrderBy = makeActionCreator(SET_TOKEN_SELECTED_ORDER_BY, 'order');

export const SET_TOKEN_TAPPED_ID = 'SET_TOKEN_TAPPED_ID';
export const setTokenTappedId = makeActionCreator(SET_TOKEN_TAPPED_ID, 'id');

export const SET_TOKEN_SEARCH_TEXT = 'SET_TOKEN_SEARCH_TEXT';
export const setTokenSearchText = makeActionCreator(SET_TOKEN_SEARCH_TEXT, 'text');


export const DOWNLOADING_APPLICATIONS = 'DOWNLOADING_APPLICATIONS';
export const downloadingApplications = makeActionCreator(DOWNLOADING_APPLICATIONS, 'status');

export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const setApplications = makeActionCreator(SET_APPLICATIONS, 'data');

export const ADD_APPLICATION = 'ADD_APPLICATION';
export const addApplication = makeActionCreator(ADD_APPLICATION, 'data');

export const CLEAR_APPLICATIONS = 'CLEAR_APPLICATIONS';
export const clearApplications = makeActionCreator(CLEAR_APPLICATIONS);

export const ADDING_EDITING_APPLICATION = 'ADDING_EDITING_APPLICATION';
export const addingEditingApplication = makeActionCreator(ADDING_EDITING_APPLICATION, 'status');

export const DOWNLOADING_DELETING_APPLICATION = 'DOWNLOADING_DELETING_APPLICATION';
export const downloadingDeletingApplication = makeActionCreator(DOWNLOADING_DELETING_APPLICATION, 'status');


export const SET_APPLICATION_SELECTED_PAGE_SIZE = 'SET_APPLICATION_SELECTED_PAGE_SIZE';
export const setApplicationSelectedPageSize = makeActionCreator(SET_APPLICATION_SELECTED_PAGE_SIZE, 'size');

export const SET_APPLICATION_SELECTED_ORDER_BY = 'SET_APPLICATION_SELECTED_ORDER_BY';
export const setApplicationSelectedOrderBy = makeActionCreator(SET_APPLICATION_SELECTED_ORDER_BY, 'order');

export const SET_APPLICATION_TAPPED_ID = 'SET_APPLICATION_TAPPED_ID';
export const setApplicationTappedId = makeActionCreator(SET_APPLICATION_TAPPED_ID, 'id');

export const SET_APPLICATION_SEARCH_TEXT = 'SET_APPLICATION_SEARCH_TEXT';
export const setApplicationSearchText = makeActionCreator(SET_APPLICATION_SEARCH_TEXT, 'text');

export const SET_APPLICATION_SELECTED_PERMISSION_NAME = 'SET_APPLICATION_SELECTED_PERMISSION_NAME';
export const setApplicationSelectedPermissionName = makeActionCreator(SET_APPLICATION_SELECTED_PERMISSION_NAME, 'id');


export const CROPPING_IMAGE = 'CROPPING_IMAGE';
export const cropppingImage = makeActionCreator(CROPPING_IMAGE, 'status');

export const SET_CROP_IMAGE_POSITION = 'SET_CROP_IMAGE_POSITION';
export const setCropImagePosition = makeActionCreator(SET_CROP_IMAGE_POSITION, 'position');

export const SET_CROP_IMAGE_AREA_PIXELS = 'SET_CROP_IMAGE_AREA_PIXELS';
export const setCropImageAreaPixels = makeActionCreator(SET_CROP_IMAGE_AREA_PIXELS, 'area');

export const SET_CROP_IMAGE_RESULT = 'SET_CROP_IMAGE_RESULT';
export const setCropImageResult = makeActionCreator(SET_CROP_IMAGE_RESULT, 'text');

export const SET_CROP_IMAGE_SOURCE = 'SET_CROP_IMAGE_SOURCE';
export const setCropImageSource = makeActionCreator(SET_CROP_IMAGE_SOURCE, 'image', 'name');

export const SET_CROP_IMAGE_ZOOM = 'SET_CROP_IMAGE_ZOOM';
export const setCropImageZoom = makeActionCreator(SET_CROP_IMAGE_ZOOM, 'number');

export const RESET_CROP_IMAGE = 'RESET_CROP_IMAGE';
export const resetCropImage = makeActionCreator(RESET_CROP_IMAGE);


export const DOWNLOADING_PROFILES = 'DOWNLOADING_PROFILES';
export const downloadingProfiles = makeActionCreator(DOWNLOADING_PROFILES, 'status');

export const SET_PROFILES = 'SET_PROFILES';
export const setProfiles = makeActionCreator(SET_PROFILES, 'data');

export const ADD_PROFILE = 'ADD_PROFILE';
export const addProfile = makeActionCreator(ADD_PROFILE, 'data');

export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const clearProfiles = makeActionCreator(CLEAR_PROFILES);

export const ADDING_EDITING_PROFILE = 'ADDING_EDITING_PROFILE';
export const addingEditingProfile = makeActionCreator(ADDING_EDITING_PROFILE, 'status');

export const DOWNLOADING_DELETING_PROFILE = 'DOWNLOADING_DELETING_PROFILE';
export const downloadingDeletingProfile = makeActionCreator(DOWNLOADING_DELETING_PROFILE, 'status');

export const ENABLING_DISABLING_PROFILE = 'ENABLING_DISABLING_PROFILE';
export const enablingDisablingProfile = makeActionCreator(ENABLING_DISABLING_PROFILE, 'status');

export const SENDING_PROFILE_NOTIFICATION = 'SENDING_PROFILE_NOTIFICATION';
export const sendingProfileNotification = makeActionCreator(SENDING_PROFILE_NOTIFICATION, 'status');

export const DOWNLOADING_MANAGERS = 'DOWNLOADING_MANAGERS';
export const downloadingManagers = makeActionCreator(DOWNLOADING_MANAGERS, 'status');

export const SAVING_PROFILES = 'SAVING_PROFILES';
export const savingProfiles = makeActionCreator(SAVING_PROFILES, 'status');

export const SET_MANAGERS = 'SET_MANAGERS';
export const setManagers = makeActionCreator(SET_MANAGERS, 'data');

export const CLEAR_MANAGERS = 'CLEAR_MANAGERS';
export const clearManagers = makeActionCreator(CLEAR_MANAGERS);


export const SET_PROFILE_SELECTED_PAGE_SIZE = 'SET_PROFILE_SELECTED_PAGE_SIZE';
export const setProfileSelectedPageSize = makeActionCreator(SET_PROFILE_SELECTED_PAGE_SIZE, 'size');

export const SET_PROFILE_SELECTED_ORDER_BY = 'SET_PROFILE_SELECTED_ORDER_BY';
export const setProfileSelectedOrderBy = makeActionCreator(SET_PROFILE_SELECTED_ORDER_BY, 'order');

export const SET_PROFILE_TAPPED_ID = 'SET_PROFILE_TAPPED_ID';
export const setProfileTappedId = makeActionCreator(SET_PROFILE_TAPPED_ID, 'id');

export const SET_PROFILE_SEARCH_TEXT = 'SET_PROFILE_SEARCH_TEXT';
export const setProfileSearchText = makeActionCreator(SET_PROFILE_SEARCH_TEXT, 'text');

export const SET_PROFILE_SELECTED_BLOOD_TYPE = 'SET_PROFILE_SELECTED_BLOOD_TYPE';
export const setProfileSelectedBloodType = makeActionCreator(SET_PROFILE_SELECTED_BLOOD_TYPE, 'name');

export const SET_PROFILE_SELECTED_BRANCH_ID = 'SET_PROFILE_SELECTED_BRANCH_ID';
export const setProfileSelectedBranchId = makeActionCreator(SET_PROFILE_SELECTED_BRANCH_ID, 'id');

export const SET_PROFILE_SELECTED_GENDER = 'SET_PROFILE_SELECTED_GENDER';
export const setProfileSelectedGender = makeActionCreator(SET_PROFILE_SELECTED_GENDER, 'name');

export const SET_PROFILE_SELECTED_TIMEZONE = 'SET_PROFILE_SELECTED_TIMEZONE';
export const setProfileSelectedTimezone = makeActionCreator(SET_PROFILE_SELECTED_TIMEZONE, 'option');

export const SET_PROFILE_SELECTED_MANAGER = 'SET_PROFILE_SELECTED_MANAGER';
export const setProfileSelectedManager = makeActionCreator(SET_PROFILE_SELECTED_MANAGER, 'option');

export const SET_PROFILE_SELECTED_COMPANY = 'SET_PROFILE_SELECTED_COMPANY';
export const setProfileSelectedCompany = makeActionCreator(SET_PROFILE_SELECTED_COMPANY, 'option');

export const SET_PROFILE_SELECTED_DIVISION = 'SET_PROFILE_SELECTED_DIVISION';
export const setProfileSelectedDivision = makeActionCreator(SET_PROFILE_SELECTED_DIVISION, 'option');

export const SET_PROFILE_SELECTED_DEPARTMENT = 'SET_PROFILE_SELECTED_DEPARTMENT';
export const setProfileSelectedDepartment = makeActionCreator(SET_PROFILE_SELECTED_DEPARTMENT, 'option');

export const SET_PROFILE_MANAGER_SEARCH_TEXT = 'SET_PROFILE_MANAGER_SEARCH_TEXT';
export const setProfileManagerSearchText = makeActionCreator(SET_PROFILE_MANAGER_SEARCH_TEXT, 'text');

export const SET_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setProfileAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_PROFILE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const COPY_API_TOKEN = 'COPY_API_TOKEN';
export const copyApiToken = makeActionCreator(COPY_API_TOKEN, 'status');


export const DOWNLOADING_SUMMARY_TOTAL_USERS = 'DOWNLOADING_SUMMARY_TOTAL_USERS';
export const downloadingSummaryTotalUsers = makeActionCreator(DOWNLOADING_SUMMARY_TOTAL_USERS, 'status');

export const SET_SUMMARY_TOTAL_USERS = 'SET_SUMMARY_TOTAL_USERS';
export const setSummaryTotalUsers = makeActionCreator(SET_SUMMARY_TOTAL_USERS, 'data');

export const CLEAR_SUMMARY_TOTAL_USERS = 'CLEAR_SUMMARY_TOTAL_USERS';
export const clearSummaryTotalUsers = makeActionCreator(CLEAR_SUMMARY_TOTAL_USERS);


export const DOWNLOADING_SUMMARY_ACTIVE_USERS = 'DOWNLOADING_SUMMARY_ACTIVE_USERS';
export const downloadingSummaryActiveUsers = makeActionCreator(DOWNLOADING_SUMMARY_ACTIVE_USERS, 'status');

export const SET_SUMMARY_ACTIVE_USERS = 'SET_SUMMARY_ACTIVE_USERS';
export const setSummaryActiveUsers = makeActionCreator(SET_SUMMARY_ACTIVE_USERS, 'data');

export const CLEAR_SUMMARY_ACTIVE_USERS = 'CLEAR_SUMMARY_ACTIVE_USERS';
export const clearSummaryActiveUsers = makeActionCreator(CLEAR_SUMMARY_ACTIVE_USERS);


export const DOWNLOADING_SUMMARY_ACTIVE_DEVICES = 'DOWNLOADING_SUMMARY_ACTIVE_DEVICES';
export const downloadingSummaryActiveDevices = makeActionCreator(DOWNLOADING_SUMMARY_ACTIVE_DEVICES, 'status');

export const SET_SUMMARY_ACTIVE_DEVICES = 'SET_SUMMARY_ACTIVE_DEVICES';
export const setSummaryActiveDevices = makeActionCreator(SET_SUMMARY_ACTIVE_DEVICES, 'data');

export const CLEAR_SUMMARY_ACTIVE_DEVICES = 'CLEAR_SUMMARY_ACTIVE_DEVICES';
export const clearSummaryActiveDevices = makeActionCreator(CLEAR_SUMMARY_ACTIVE_DEVICES);


export const DOWNLOADING_SUMMARY_OS_DISTRIBUTION = 'DOWNLOADING_SUMMARY_OS_DISTRIBUTION';
export const downloadingSummaryOsDistribution = makeActionCreator(DOWNLOADING_SUMMARY_OS_DISTRIBUTION, 'status');

export const SET_SUMMARY_OS_DISTRIBUTION = 'SET_SUMMARY_OS_DISTRIBUTION';
export const setSummaryOsDistribution = makeActionCreator(SET_SUMMARY_OS_DISTRIBUTION, 'data');

export const CLEAR_SUMMARY_OS_DISTRIBUTION = 'CLEAR_SUMMARY_OS_DISTRIBUTION';
export const clearSummaryOsDistribution = makeActionCreator(CLEAR_SUMMARY_OS_DISTRIBUTION);


export const DOWNLOADING_SUMMARY_LOGIN_USING = 'DOWNLOADING_SUMMARY_LOGIN_USING';
export const downloadingSummaryLoginUsing = makeActionCreator(DOWNLOADING_SUMMARY_LOGIN_USING, 'status');

export const SET_SUMMARY_LOGIN_USING = 'SET_SUMMARY_LOGIN_USING';
export const setSummaryLoginUsing = makeActionCreator(SET_SUMMARY_LOGIN_USING, 'data');

export const CLEAR_SUMMARY_LOGIN_USING = 'CLEAR_SUMMARY_LOGIN_USING';
export const clearSummaryLoginUsing = makeActionCreator(CLEAR_SUMMARY_LOGIN_USING);


export const DOWNLOADING_SUMMARY_ANDROID_VERSION_DISTRIBUTION = 'DOWNLOADING_SUMMARY_ANDROID_VERSION_DISTRIBUTION';
export const downloadingSummaryAndroidVersionDistribution = makeActionCreator(DOWNLOADING_SUMMARY_ANDROID_VERSION_DISTRIBUTION, 'status');

export const SET_SUMMARY_ANDROID_VERSION_DISTRIBUTION = 'SET_SUMMARY_ANDROID_VERSION_DISTRIBUTION';
export const setSummaryAndroidVersionDistribution = makeActionCreator(SET_SUMMARY_ANDROID_VERSION_DISTRIBUTION, 'data');

export const CLEAR_SUMMARY_ANDROID_VERSION_DISTRIBUTION = 'CLEAR_SUMMARY_ANDROID_VERSION_DISTRIBUTION';
export const clearSummaryAndroidVersionDistribution = makeActionCreator(
  CLEAR_SUMMARY_ANDROID_VERSION_DISTRIBUTION,
);


export const DOWNLOADING_SUMMARY_IOS_VERSION_DISTRIBUTION = 'DOWNLOADING_SUMMARY_IOS_VERSION_DISTRIBUTION';
export const downloadingSummaryIosVersionDistribution = makeActionCreator(DOWNLOADING_SUMMARY_IOS_VERSION_DISTRIBUTION, 'status');

export const SET_SUMMARY_IOS_VERSION_DISTRIBUTION = 'SET_SUMMARY_IOS_VERSION_DISTRIBUTION';
export const setSummaryIosVersionDistribution = makeActionCreator(SET_SUMMARY_IOS_VERSION_DISTRIBUTION, 'data');

export const CLEAR_SUMMARY_IOS_VERSION_DISTRIBUTION = 'CLEAR_SUMMARY_IOS_VERSION_DISTRIBUTION';
export const clearSummaryIosVersionDistribution = makeActionCreator(
  CLEAR_SUMMARY_IOS_VERSION_DISTRIBUTION,
);


export const DOWNLOADING_SUMMARY_BROWSER_DISTRIBUTION = 'DOWNLOADING_SUMMARY_BROWSER_DISTRIBUTION';
export const downloadingSummaryBrowserDistribution = makeActionCreator(DOWNLOADING_SUMMARY_BROWSER_DISTRIBUTION, 'status');

export const SET_SUMMARY_BROWSER_DISTRIBUTION = 'SET_SUMMARY_BROWSER_DISTRIBUTION';
export const setSummaryBrowserDistribution = makeActionCreator(SET_SUMMARY_BROWSER_DISTRIBUTION, 'data');

export const CLEAR_SUMMARY_BROWSER_DISTRIBUTION = 'CLEAR_SUMMARY_BROWSER_DISTRIBUTION';
export const clearSummaryBrowserDistribution = makeActionCreator(
  CLEAR_SUMMARY_BROWSER_DISTRIBUTION,
);

export const DOWNLOADING_SUMMARY_TIMEZONE_DISTRIBUTION = 'DOWNLOADING_SUMMARY_TIMEZONE_DISTRIBUTION';
export const downloadingSummaryTimezoneDistribution = makeActionCreator(DOWNLOADING_SUMMARY_TIMEZONE_DISTRIBUTION, 'status');

export const SET_SUMMARY_TIMEZONE_DISTRIBUTION = 'SET_SUMMARY_TIMEZONE_DISTRIBUTION';
export const setSummaryTimezoneDistribution = makeActionCreator(SET_SUMMARY_TIMEZONE_DISTRIBUTION, 'data');

export const CLEAR_SUMMARY_TIMEZONE_DISTRIBUTION = 'CLEAR_SUMMARY_TIMEZONE_DISTRIBUTION';
export const clearSummaryTimezoneDistribution = makeActionCreator(
  CLEAR_SUMMARY_TIMEZONE_DISTRIBUTION,
);

export const DOWNLOADING_SUMMARY_ONLINE_USERS = 'DOWNLOADING_SUMMARY_ONLINE_USERS';
export const downloadingSummaryOnlineUsers = makeActionCreator(DOWNLOADING_SUMMARY_ONLINE_USERS, 'status');

export const SET_SUMMARY_ONLINE_USERS = 'SET_SUMMARY_ONLINE_USERS';
export const setSummaryOnlineUsers = makeActionCreator(SET_SUMMARY_ONLINE_USERS, 'data');

export const CLEAR_SUMMARY_ONLINE_USERS = 'CLEAR_SUMMARY_ONLINE_USERS';
export const clearSummaryOnlineUsers = makeActionCreator(CLEAR_SUMMARY_ONLINE_USERS);


export const SENDING_NOTIFICATIONS = 'SENDING_NOTIFICATIONS';
export const sendingNotifications = makeActionCreator(SENDING_NOTIFICATIONS, 'status');


export const SET_NOTIFICATION_DIALOG_VISIBILITY = 'SET_NOTIFICATION_DIALOG_VISIBILITY';
export const setNotificationDialogVisibility = makeActionCreator(SET_NOTIFICATION_DIALOG_VISIBILITY, 'status');

export const ADD_NOTIFICATION_DIALOG_FILTER_FORM = 'ADD_NOTIFICATION_DIALOG_FILTER_FORM';
export const addNotificationDialogFilterForm = makeActionCreator(ADD_NOTIFICATION_DIALOG_FILTER_FORM, 'field', 'value');

export const CLEAR_NOTIFICATION_DIALOG_FILTER_FORM = 'CLEAR_NOTIFICATION_DIALOG_FILTER_FORM';
export const clearNotificationDialogFilterForm = makeActionCreator(
  CLEAR_NOTIFICATION_DIALOG_FILTER_FORM,
);

export const SET_NOTIFICATION_SELECTED_BRANCH = 'SET_NOTIFICATION_SELECTED_BRANCH';
export const setNotificationSelectedBranch = makeActionCreator(SET_NOTIFICATION_SELECTED_BRANCH, 'option');

export const SET_NOTIFICATION_SELECTED_COMPANY = 'SET_NOTIFICATION_SELECTED_COMPANY';
export const setNotificationSelectedCompany = makeActionCreator(SET_NOTIFICATION_SELECTED_COMPANY, 'option');

export const SET_NOTIFICATION_SELECTED_DIVISION = 'SET_NOTIFICATION_SELECTED_DIVISION';
export const setNotificationSelectedDivision = makeActionCreator(SET_NOTIFICATION_SELECTED_DIVISION, 'option');

export const SET_NOTIFICATION_SELECTED_DEPARTMENTS = 'SET_NOTIFICATION_SELECTED_DEPARTMENTS';
export const setNotificationSelectedDepartment = makeActionCreator(SET_NOTIFICATION_SELECTED_DEPARTMENTS, 'option');


export const DOWNLOADING_COMPANIES = 'DOWNLOADING_COMPANIES';
export const downloadingCompanies = makeActionCreator(DOWNLOADING_COMPANIES, 'status');

export const SET_COMPANIES = 'SET_COMPANIES';
export const setCompanies = makeActionCreator(SET_COMPANIES, 'data');

export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const clearCompanies = makeActionCreator(CLEAR_COMPANIES);

export const SET_COMPANY_SEARCH_TEXT = 'SET_COMPANY_SEARCH_TEXT';
export const setCompanySearchText = makeActionCreator(SET_COMPANY_SEARCH_TEXT, 'text');


export const DOWNLOADING_COMPANIES_MDATA = 'DOWNLOADING_COMPANIES_MDATA';
export const downloadingCompaniesMData = makeActionCreator(DOWNLOADING_COMPANIES_MDATA, 'status');

export const SET_COMPANIES_MDATA = 'SET_COMPANIES_MDATA';
export const setCompaniesMData = makeActionCreator(SET_COMPANIES_MDATA, 'data');

export const ADD_COMPANY_MDATA = 'ADD_COMPANY_MDATA';
export const addCompanyMData = makeActionCreator(ADD_COMPANY_MDATA, 'data');

export const CLEAR_COMPANIES_MDATA = 'CLEAR_COMPANIES_MDATA';
export const clearCompaniesMData = makeActionCreator(CLEAR_COMPANIES_MDATA);

export const ADDING_EDITING_COMPANY_MDATA = 'ADDING_EDITING_COMPANY_MDATA';
export const addingEditingCompanyMData = makeActionCreator(ADDING_EDITING_COMPANY_MDATA, 'status');

export const DOWNLOADING_COMPANY_MDATA = 'DOWNLOADING_COMPANY_MDATA';
export const downloadingCompanyMData = makeActionCreator(DOWNLOADING_COMPANY_MDATA, 'status');

export const ENABLING_DISABLING_COMPANY_MDATA = 'ENABLING_DISABLING_COMPANY_MDATA';
export const enablingDisablingCompanyMData = makeActionCreator(ENABLING_DISABLING_COMPANY_MDATA, 'status');

export const SAVING_COMPANIES_MDATA = 'SAVING_COMPANIES_MDATA';
export const savingCompaniesMData = makeActionCreator(SAVING_COMPANIES_MDATA, 'status');

export const RESYNCING_COMPANY_MDATA = 'RESYNCING_COMPANY_MDATA';
export const resyncingCompanyMdata = makeActionCreator(RESYNCING_COMPANY_MDATA, 'status');

export const SET_COMPANY_LOGO_IMAGE = 'SET_COMPANY_LOGO_IMAGE';
export const setCompanyLogoImage = makeActionCreator(SET_COMPANY_LOGO_IMAGE, 'image');

export const SET_COMPANY_BANNER_IMAGE = 'SET_COMPANY_BANNER_IMAGE';
export const setCompanyBannerImage = makeActionCreator(SET_COMPANY_BANNER_IMAGE, 'image');

export const SET_COMPANY_SEARCH_TEXT_MDATA = 'SET_COMPANY_SEARCH_TEXT_MDATA';
export const setCompanySearchTextMData = makeActionCreator(SET_COMPANY_SEARCH_TEXT_MDATA, 'text');

export const SET_COMPANY_TAPPED_ID_MDATA = 'SET_COMPANY_TAPPED_ID_MDATA';
export const setCompanyTappedIdMData = makeActionCreator(SET_COMPANY_TAPPED_ID_MDATA, 'id');

export const SET_COMPANY_SELECTED_PAGE_SIZE_MDATA = 'SET_COMPANY_SELECTED_PAGE_SIZE_MDATA';
export const setCompanySelectedPageSizeMData = makeActionCreator(SET_COMPANY_SELECTED_PAGE_SIZE_MDATA, 'size');

export const SET_COMPANY_SELECTED_ORDER_BY_MDATA = 'SET_COMPANY_SELECTED_ORDER_BY_MDATA';
export const setCompanySelectedOrderByMData = makeActionCreator(SET_COMPANY_SELECTED_ORDER_BY_MDATA, 'order');

export const SET_COMPANY_SELECTED_CITY_ID_MDATA = 'SET_COMPANY_SELECTED_CITY_ID_MDATA';
export const setCompanySelectedCityIdMData = makeActionCreator(SET_COMPANY_SELECTED_CITY_ID_MDATA, 'id');

export const SET_COMPANY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING_MDATA = 'SET_COMPANY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING_MDATA';
export const setCompanyAdvancedFilterDialogSelectedFilterStringMData = makeActionCreator(SET_COMPANY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING_MDATA, 'text');

export const SET_COMPANY_MAP_CENTER_MDATA = 'SET_COMPANY_MAP_CENTER_MDATA';
export const setCompanyMapCenterMData = makeActionCreator(SET_COMPANY_MAP_CENTER_MDATA, 'data');

export const SET_COMPANY_MAP_MARKER_MDATA = 'SET_COMPANY_MAP_MARKER_MDATA';
export const setCompanyMapMarkerMData = makeActionCreator(SET_COMPANY_MAP_MARKER_MDATA, 'data');

export const SET_COMPANY_CLEAR_LOGO_MDATA = 'SET_COMPANY_CLEAR_LOGO_MDATA';
export const setCompanyClearLogoMData = makeActionCreator(SET_COMPANY_CLEAR_LOGO_MDATA, 'status');


export const DOWNLOADING_DIVISIONS = 'DOWNLOADING_DIVISIONS';
export const downloadingDivisions = makeActionCreator(DOWNLOADING_DIVISIONS, 'status');

export const SET_DIVISIONS = 'SET_DIVISIONS';
export const setDivisions = makeActionCreator(SET_DIVISIONS, 'data');

export const CLEAR_DIVISIONS = 'CLEAR_DIVISIONS';
export const clearDivisions = makeActionCreator(CLEAR_DIVISIONS);

export const SET_DIVISION_SEARCH_TEXT = 'SET_DIVISION_SEARCH_TEXT';
export const setDivisionSearchText = makeActionCreator(SET_DIVISION_SEARCH_TEXT, 'text');


export const DOWNLOADING_DEPARTMENTS = 'DOWNLOADING_DEPARTMENTS';
export const downloadingDepartments = makeActionCreator(DOWNLOADING_DEPARTMENTS, 'status');

export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const setDepartments = makeActionCreator(SET_DEPARTMENTS, 'data');

export const CLEAR_DEPARTMENTS = 'CLEAR_DEPARTMENTS';
export const clearDepartments = makeActionCreator(CLEAR_DEPARTMENTS);

export const SET_DEPARTMENT_SEARCH_TEXT = 'SET_DEPARTMENT_SEARCH_TEXT';
export const setDepartmentSearchText = makeActionCreator(SET_DEPARTMENT_SEARCH_TEXT, 'text');


export const DOWNLOADING_TOTAL_PERSON_COUNT = 'DOWNLOADING_TOTAL_PERSON_COUNT';
export const downloadingTotalPersonCount = makeActionCreator(DOWNLOADING_TOTAL_PERSON_COUNT, 'status');

export const SET_TOTAL_PERSON_COUNT = 'SET_TOTAL_PERSON_COUNT';
export const setTotalPersonCount = makeActionCreator(SET_TOTAL_PERSON_COUNT, 'data');


export const DOWNLOADING_SUMMARY_APP_VERSION_DISTRIBUTION = 'DOWNLOADING_SUMMARY_APP_VERSION_DISTRIBUTION';
export const downloadingSummaryAppVersionDistribution = makeActionCreator(DOWNLOADING_SUMMARY_APP_VERSION_DISTRIBUTION, 'status');

export const SET_SUMMARY_APP_VERSION_DISTRIBUTION = 'SET_SUMMARY_APP_VERSION_DISTRIBUTION';
export const setSummaryAppVersionDistribution = makeActionCreator(SET_SUMMARY_APP_VERSION_DISTRIBUTION, 'data');

export const CLEAR_SUMMARY_APP_VERSION_DISTRIBUTION = 'CLEAR_SUMMARY_APP_VERSION_DISTRIBUTION';
export const clearSummaryAppVersionDistribution = makeActionCreator(
  CLEAR_SUMMARY_APP_VERSION_DISTRIBUTION,
);

export const DOWNLOADING_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS = 'DOWNLOADING_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS';
export const downloadingSummaryDeviceManufacturerDistributions = makeActionCreator(
  DOWNLOADING_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS, 'status',
);

export const SET_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS = 'SET_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS';
export const setSummaryDeviceManufacturerDistributions = makeActionCreator(
  SET_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS, 'data',
);

export const CLEAR_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS = 'CLEAR_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS';
export const clearSummaryDeviceManufacturerDistributions = makeActionCreator(
  CLEAR_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTIONS,
);


export const DOWNLOADING_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS = 'DOWNLOADING_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS';
export const downloadingSummaryAndroidDeviceModelDistributions = makeActionCreator(
  DOWNLOADING_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS, 'status',
);

export const SET_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS = 'SET_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS';
export const setSummaryAndroidDeviceModelDistributions = makeActionCreator(
  SET_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS, 'data',
);

export const CLEAR_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS = 'CLEAR_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS';
export const clearSummaryAndroidDeviceModelDistributions = makeActionCreator(
  CLEAR_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTIONS,
);


export const DOWNLOADING_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS = 'DOWNLOADING_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS';
export const downloadingSummaryIosDeviceModelDistributions = makeActionCreator(
  DOWNLOADING_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS, 'status',
);

export const SET_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS = 'SET_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS';
export const setSummaryIosDeviceModelDistributions = makeActionCreator(
  SET_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS, 'data',
);

export const CLEAR_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS = 'CLEAR_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS';
export const clearSummaryIosDeviceModelDistributions = makeActionCreator(
  CLEAR_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTIONS,
);


export const DOWNLOADING_SERVICES = 'DOWNLOADING_SERVICES';
export const downloadingServices = makeActionCreator(DOWNLOADING_SERVICES, 'status');

export const SET_SERVICES = 'SET_SERVICES';
export const setService = makeActionCreator(SET_SERVICES, 'data');

export const ADD_SERVICE = 'ADD_SERVICE';
export const addService = makeActionCreator(ADD_SERVICE, 'data');

export const CLEAR_SERVICES = 'CLEAR_SERVICES';
export const clearServices = makeActionCreator(CLEAR_SERVICES);

export const ADDING_EDITING_SERVICE = 'ADDING_EDITING_SERVICE';
export const addingEditingService = makeActionCreator(ADDING_EDITING_SERVICE, 'status');

export const DOWNLOADING_DELETING_SERVICE = 'DOWNLOADING_DELETING_SERVICE';
export const downloadingDeletingService = makeActionCreator(DOWNLOADING_DELETING_SERVICE, 'status');


export const SET_SERVICE_SELECTED_PAGE_SIZE = 'SET_SERVICE_SELECTED_PAGE_SIZE';
export const setServiceSelectedPageSize = makeActionCreator(SET_SERVICE_SELECTED_PAGE_SIZE, 'size');

export const SET_SERVICE_SELECTED_ORDER_BY = 'SET_SERVICE_SELECTED_ORDER_BY';
export const setServiceSelectedOrderBy = makeActionCreator(SET_SERVICE_SELECTED_ORDER_BY, 'order');

export const SET_SERVICE_TAPPED_ID = 'SET_SERVICE_TAPPED_ID';
export const setServiceTappedId = makeActionCreator(SET_SERVICE_TAPPED_ID, 'id');

export const SET_SERVICE_SEARCH_TEXT = 'SET_SERVICE_SEARCH_TEXT';
export const setServiceSearchText = makeActionCreator(SET_SERVICE_SEARCH_TEXT, 'text');


export const ADDING_POLYGON = 'ADDING_POLYGON';
export const addingPolygon = makeActionCreator(ADDING_POLYGON, 'status');

export const SET_MAP_DRAWER_RENDER_USER_SHAPE_AFTER_COMPLETE = 'SET_MAP_DRAWER_RENDER_USER_SHAPE_AFTER_COMPLETE';
export const setMapDrawerRenderUserShapeAfterComplete = makeActionCreator(SET_MAP_DRAWER_RENDER_USER_SHAPE_AFTER_COMPLETE, 'status');

export const SET_MAP_DRAWER_MARKER = 'SET_MAP_DRAWER_MARKER';
export const setMapDrawerMarker = makeActionCreator(SET_MAP_DRAWER_MARKER, 'data');

export const SET_MAP_DRAWER_POLYGON = 'SET_MAP_DRAWER_POLYGON';
export const setMapDrawerPolygon = makeActionCreator(SET_MAP_DRAWER_POLYGON, 'data');


export const DOWNLOADING_SCOPES = 'DOWNLOADING_SCOPES';
export const downloadingScopes = makeActionCreator(DOWNLOADING_SCOPES, 'status');

export const SET_SCOPES = 'SET_SCOPES';
export const setScopes = makeActionCreator(SET_SCOPES, 'data');

export const ADD_SCOPE = 'ADD_SCOPE';
export const addScope = makeActionCreator(ADD_SCOPE, 'data');

export const CLEAR_SCOPES = 'CLEAR_SCOPES';
export const clearScopes = makeActionCreator(CLEAR_SCOPES);

export const ADDING_EDITING_SCOPE = 'ADDING_EDITING_SCOPE';
export const addingEditingScope = makeActionCreator(ADDING_EDITING_SCOPE, 'status');

export const DOWNLOADING_DELETING_SCOPE = 'DOWNLOADING_DELETING_SCOPE';
export const downloadingDeletingScope = makeActionCreator(DOWNLOADING_DELETING_SCOPE, 'status');


export const SET_SCOPE_SELECTED_PAGE_SIZE = 'SET_SCOPE_SELECTED_PAGE_SIZE';
export const setScopeSelectedPageSize = makeActionCreator(SET_SCOPE_SELECTED_PAGE_SIZE, 'size');

export const SET_SCOPE_SELECTED_ORDER_BY = 'SET_SCOPE_SELECTED_ORDER_BY';
export const setScopeSelectedOrderBy = makeActionCreator(SET_SCOPE_SELECTED_ORDER_BY, 'order');

export const SET_SCOPE_TAPPED_ID = 'SET_SCOPE_TAPPED_ID';
export const setScopeTappedId = makeActionCreator(SET_SCOPE_TAPPED_ID, 'id');

export const SET_SCOPE_SEARCH_TEXT = 'SET_SCOPE_SEARCH_TEXT';
export const setScopeSearchText = makeActionCreator(SET_SCOPE_SEARCH_TEXT, 'text');

export const SET_SCOPE_SELECTED_SERVICE_ID = 'SET_SCOPE_SELECTED_SERVICE_ID';
export const setScopeSelectedServiceId = makeActionCreator(SET_SCOPE_SELECTED_SERVICE_ID, 'id');

export const SET_SCOPE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SCOPE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setScopeAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SCOPE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_BANNERS = 'DOWNLOADING_BANNERS';
export const downloadingBanners = makeActionCreator(DOWNLOADING_BANNERS, 'status');

export const SET_BANNERS = 'SET_BANNERS';
export const setBanners = makeActionCreator(SET_BANNERS, 'data');

export const ADD_BANNER = 'ADD_BANNER';
export const addBanner = makeActionCreator(ADD_BANNER, 'data');

export const CLEAR_BANNERS = 'CLEAR_BANNERS';
export const clearBanners = makeActionCreator(CLEAR_BANNERS);

export const ADDING_EDITING_BANNER = 'ADDING_EDITING_BANNER';
export const addingEditingBanner = makeActionCreator(ADDING_EDITING_BANNER, 'status');

export const DOWNLOADING_DELETING_BANNER = 'DOWNLOADING_DELETING_BANNER';
export const downloadingDeletingBanner = makeActionCreator(DOWNLOADING_DELETING_BANNER, 'status');


export const SET_BANNER_SELECTED_PAGE_SIZE = 'SET_BANNER_SELECTED_PAGE_SIZE';
export const setBannerSelectedPageSize = makeActionCreator(SET_BANNER_SELECTED_PAGE_SIZE, 'size');

export const SET_BANNER_SELECTED_ORDER_BY = 'SET_BANNER_SELECTED_ORDER_BY';
export const setBannerSelectedOrderBy = makeActionCreator(SET_BANNER_SELECTED_ORDER_BY, 'order');

export const SET_BANNER_TAPPED_ID = 'SET_BANNER_TAPPED_ID';
export const setBannerTappedId = makeActionCreator(SET_BANNER_TAPPED_ID, 'id');

export const SET_BANNER_SEARCH_TEXT = 'SET_BANNER_SEARCH_TEXT';
export const setBannerSearchText = makeActionCreator(SET_BANNER_SEARCH_TEXT, 'text');

export const SET_BANNER_SELECTED_CLICK_ACTION = 'SET_BANNER_SELECTED_CLICK_ACTION';
export const setBannerSelectedClickAction = makeActionCreator(SET_BANNER_SELECTED_CLICK_ACTION, 'option');

export const SET_BANNER_SELECTED_ADD_MEDIA_MENU = 'SET_BANNER_SELECTED_ADD_MEDIA_MENU';
export const setBannerSelectedAddMediaMenu = makeActionCreator(SET_BANNER_SELECTED_ADD_MEDIA_MENU, 'text');

export const SET_BANNER_SHORT_DESCRIPTION_LENGTH = 'SET_BANNER_SHORT_DESCRIPTION_LENGTH';
export const setBannerShortDescriptionLength = makeActionCreator(SET_BANNER_SHORT_DESCRIPTION_LENGTH, 'number');

export const SET_BANNER_SELECTED_COMPANY = 'SET_BANNER_SELECTED_COMPANY';
export const setBannerSelectedCompany = makeActionCreator(SET_BANNER_SELECTED_COMPANY, 'value');


export const DOWNLOADING_DEVICE_MANUFACTURERS = 'DOWNLOADING_DEVICE_MANUFACTURERS';
export const downloadingDeviceManufacturers = makeActionCreator(DOWNLOADING_DEVICE_MANUFACTURERS, 'status');

export const SET_DEVICE_MANUFACTURERS = 'SET_DEVICE_MANUFACTURERS';
export const setDeviceManufacturers = makeActionCreator(SET_DEVICE_MANUFACTURERS, 'data');

export const CLEAR_DEVICE_MANUFACTURERS = 'CLEAR_DEVICE_MANUFACTURERS';
export const clearDeviceManufacturers = makeActionCreator(CLEAR_DEVICE_MANUFACTURERS);

export const SET_DEVICE_MANUFACTURER_SEARCH_TEXT = 'SET_DEVICE_MANUFACTURER_SEARCH_TEXT';
export const setDeviceManufacturerSearchText = makeActionCreator(SET_DEVICE_MANUFACTURER_SEARCH_TEXT, 'text');


export const DOWNLOADING_DEVICE_MODELS = 'DOWNLOADING_DEVICE_MODELS';
export const downloadingDeviceModels = makeActionCreator(DOWNLOADING_DEVICE_MODELS, 'status');

export const SET_DEVICE_MODELS = 'SET_DEVICE_MODELS';
export const setDeviceModels = makeActionCreator(SET_DEVICE_MODELS, 'data');

export const CLEAR_DEVICE_MODELS = 'CLEAR_DEVICE_MODELS';
export const clearDeviceModels = makeActionCreator(CLEAR_DEVICE_MODELS);

export const SET_DEVICE_MODEL_SEARCH_TEXT = 'SET_DEVICE_MODEL_SEARCH_TEXT';
export const setDeviceModelSearchText = makeActionCreator(SET_DEVICE_MODEL_SEARCH_TEXT, 'text');


export const DOWNLOADING_OS_NAMES = 'DOWNLOADING_OS_NAMES';
export const downloadingOsNames = makeActionCreator(DOWNLOADING_OS_NAMES, 'status');

export const SET_OS_NAMES = 'SET_OS_NAMES';
export const setOsNames = makeActionCreator(SET_OS_NAMES, 'data');

export const CLEAR_OS_NAMES = 'CLEAR_OS_NAMES';
export const clearOsNames = makeActionCreator(CLEAR_OS_NAMES);

export const SET_OS_NAME_SEARCH_TEXT = 'SET_OS_NAME_SEARCH_TEXT';
export const setOsNameSearchText = makeActionCreator(SET_OS_NAME_SEARCH_TEXT, 'text');


export const DOWNLOADING_OS_VERSIONS = 'DOWNLOADING_OS_VERSIONS';
export const downloadingOsVersions = makeActionCreator(DOWNLOADING_OS_VERSIONS, 'status');

export const SET_OS_VERSIONS = 'SET_OS_VERSIONS';
export const setOsVersions = makeActionCreator(SET_OS_VERSIONS, 'data');

export const CLEAR_OS_VERSIONS = 'CLEAR_OS_VERSIONS';
export const clearOsVersions = makeActionCreator(CLEAR_OS_VERSIONS);

export const SET_OS_VERSION_SEARCH_TEXT = 'SET_OS_VERSION_SEARCH_TEXT';
export const setOsVersionSearchText = makeActionCreator(SET_OS_VERSION_SEARCH_TEXT, 'text');


export const DOWNLOADING_TIME_ZONES = 'DOWNLOADING_TIME_ZONES';
export const downloadingTimeZones = makeActionCreator(DOWNLOADING_TIME_ZONES, 'status');

export const SET_TIME_ZONES = 'SET_TIME_ZONES';
export const setTimeZones = makeActionCreator(SET_TIME_ZONES, 'data');

export const CLEAR_TIME_ZONES = 'CLEAR_TIME_ZONES';
export const clearTimeZones = makeActionCreator(CLEAR_TIME_ZONES);

export const SET_TIME_ZONE_SEARCH_TEXT = 'SET_TIME_ZONE_SEARCH_TEXT';
export const setTimeZoneSearchText = makeActionCreator(SET_TIME_ZONE_SEARCH_TEXT, 'text');


export const DOWNLOADING_APP_VERSIONS = 'DOWNLOADING_APP_VERSIONS';
export const downloadingAppVersions = makeActionCreator(DOWNLOADING_APP_VERSIONS, 'status');

export const SET_APP_VERSIONS = 'SET_APP_VERSIONS';
export const setAppVersions = makeActionCreator(SET_APP_VERSIONS, 'data');

export const CLEAR_APP_VERSIONS = 'CLEAR_APP_VERSIONS';
export const clearAppVersions = makeActionCreator(CLEAR_APP_VERSIONS);

export const SET_APP_VERSION_SEARCH_TEXT = 'SET_APP_VERSION_SEARCH_TEXT';
export const setAppVersionSearchText = makeActionCreator(SET_APP_VERSION_SEARCH_TEXT, 'text');


export const DOWNLOADING_DISTRICTS = 'DOWNLOADING_DISTRICTS';
export const downloadingDistricts = makeActionCreator(DOWNLOADING_DISTRICTS, 'status');

export const SET_DISTRICTS = 'SET_DISTRICTS';
export const setDistricts = makeActionCreator(SET_DISTRICTS, 'data');

export const ADD_DISTRICT = 'ADD_DISTRICT';
export const addDistrict = makeActionCreator(ADD_DISTRICT, 'data');

export const CLEAR_DISTRICTS = 'CLEAR_DISTRICTS';
export const clearDistricts = makeActionCreator(CLEAR_DISTRICTS);

export const ADDING_EDITING_DISTRICT = 'ADDING_EDITING_DISTRICT';
export const addingEditingDistrict = makeActionCreator(ADDING_EDITING_DISTRICT, 'status');

export const DOWNLOADING_DELETING_DISTRICT = 'DOWNLOADING_DELETING_DISTRICT';
export const downloadingDeletingDistrict = makeActionCreator(DOWNLOADING_DELETING_DISTRICT, 'status');

export const ENABLING_DISABLING_DISTRICT = 'ENABLING_DISABLING_DISTRICT';
export const enablingDisablingDistrict = makeActionCreator(ENABLING_DISABLING_DISTRICT, 'status');

export const SAVING_DISTRICTS = 'SAVING_DISTRICTS';
export const savingDistricts = makeActionCreator(SAVING_DISTRICTS, 'status');

export const RESYNCING_DISTRICT = 'RESYNCING_DISTRICT';
export const resyncingDistrict = makeActionCreator(RESYNCING_DISTRICT, 'status');


export const SET_DISTRICT_SELECTED_PAGE_SIZE = 'SET_DISTRICT_SELECTED_PAGE_SIZE';
export const setDistrictSelectedPageSize = makeActionCreator(SET_DISTRICT_SELECTED_PAGE_SIZE, 'size');

export const SET_DISTRICT_SELECTED_ORDER_BY = 'SET_DISTRICT_SELECTED_ORDER_BY';
export const setDistrictSelectedOrderBy = makeActionCreator(SET_DISTRICT_SELECTED_ORDER_BY, 'order');

export const SET_DISTRICT_TAPPED_ID = 'SET_DISTRICT_TAPPED_ID';
export const setDistrictTappedId = makeActionCreator(SET_DISTRICT_TAPPED_ID, 'id');

export const SET_DISTRICT_SEARCH_TEXT = 'SET_DISTRICT_SEARCH_TEXT';
export const setDistrictSearchText = makeActionCreator(SET_DISTRICT_SEARCH_TEXT, 'text');

export const SET_DISTRICT_SELECTED_CITY_ID = 'SET_DISTRICT_SELECTED_CITY_ID';
export const setDistrictSelectedCityId = makeActionCreator(SET_DISTRICT_SELECTED_CITY_ID, 'id');

export const SET_DISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_DISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setDistrictAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_DISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_SUBDISTRICTS = 'DOWNLOADING_SUBDISTRICTS';
export const downloadingSubdistricts = makeActionCreator(DOWNLOADING_SUBDISTRICTS, 'status');

export const SET_SUBDISTRICTS = 'SET_SUBDISTRICTS';
export const setSubdistricts = makeActionCreator(SET_SUBDISTRICTS, 'data');

export const ADD_SUBDISTRICT = 'ADD_SUBDISTRICT';
export const addSubdistrict = makeActionCreator(ADD_SUBDISTRICT, 'data');

export const CLEAR_SUBDISTRICTS = 'CLEAR_SUBDISTRICTS';
export const clearSubdistricts = makeActionCreator(CLEAR_SUBDISTRICTS);

export const ADDING_EDITING_SUBDISTRICT = 'ADDING_EDITING_SUBDISTRICT';
export const addingEditingSubdistrict = makeActionCreator(ADDING_EDITING_SUBDISTRICT, 'status');

export const DOWNLOADING_DELETING_SUBDISTRICT = 'DOWNLOADING_DELETING_SUBDISTRICT';
export const downloadingDeletingSubdistrict = makeActionCreator(DOWNLOADING_DELETING_SUBDISTRICT, 'status');

export const ENABLING_DISABLING_SUBDISTRICT = 'ENABLING_DISABLING_SUBDISTRICT';
export const enablingDisablingSubdistrict = makeActionCreator(ENABLING_DISABLING_SUBDISTRICT, 'status');

export const SAVING_SUBDISTRICTS = 'SAVING_SUBDISTRICTS';
export const savingSubdistricts = makeActionCreator(SAVING_SUBDISTRICTS, 'status');

export const RESYNCING_SUBDISTRICT = 'RESYNCING_SUBDISTRICT';
export const resyncingSubdistrict = makeActionCreator(RESYNCING_SUBDISTRICT, 'status');


export const SET_SUBDISTRICT_SELECTED_PAGE_SIZE = 'SET_SUBDISTRICT_SELECTED_PAGE_SIZE';
export const setSubdistrictSelectedPageSize = makeActionCreator(SET_SUBDISTRICT_SELECTED_PAGE_SIZE, 'size');

export const SET_SUBDISTRICT_SELECTED_ORDER_BY = 'SET_SUBDISTRICT_SELECTED_ORDER_BY';
export const setSubdistrictSelectedOrderBy = makeActionCreator(SET_SUBDISTRICT_SELECTED_ORDER_BY, 'order');

export const SET_SUBDISTRICT_TAPPED_ID = 'SET_SUBDISTRICT_TAPPED_ID';
export const setSubdistrictTappedId = makeActionCreator(SET_SUBDISTRICT_TAPPED_ID, 'id');

export const SET_SUBDISTRICT_SEARCH_TEXT = 'SET_SUBDISTRICT_SEARCH_TEXT';
export const setSubdistrictSearchText = makeActionCreator(SET_SUBDISTRICT_SEARCH_TEXT, 'text');

export const SET_SUBDISTRICT_SELECTED_DISTRICT_ID = 'SET_SUBDISTRICT_SELECTED_DISTRICT_ID';
export const setSubdistrictSelectedDistrictId = makeActionCreator(SET_SUBDISTRICT_SELECTED_DISTRICT_ID, 'id');

export const SET_SUBDISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_SUBDISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setSubdistrictAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_SUBDISTRICT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');
