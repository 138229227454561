import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_SCREEN = '/screen/api/v1/Screen?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';

export const REST_URL_EMAILS = '/messaging/api/v1/Email?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_EMAIL = '/messaging/api/v1/Email/{id}';
export const REST_URL_RESEND_EMAIL = '/messaging/api/v1/Email/Resend/{id}';
export const REST_URL_SMSES = '/messaging/api/v1/Sms?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_SMS = '/messaging/api/v1/Sms/{id}';
export const REST_URL_RESEND_SMS = '/messaging/api/v1/Sms/Resend/{id}';
export const REST_URL_WHATSAPPS = '/messaging/api/v1/Whatsapp?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_WHATSAPP = '/messaging/api/v1/Whatsapp/{id}';
export const REST_URL_RESEND_WHATSAPP = '/messaging/api/v1/Whatsapp/Resend/{id}';
export const REST_URL_PUSH_NOTIFICATIONS = '/messaging/api/v1/PushNotification?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_PUSH_NOTIFICATION = '/messaging/api/v1/PushNotification/{id}';
export const REST_URL_RESEND_PUSH_NOTIFICATION = '/messaging/api/v1/PushNotification/Resend/{id}';
export const REST_URL_NOTIFICATION_TASKS = '/core/api/v1/SimpleNotification?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString={filterString}';
export const REST_URL_VIEW_NOTIFICATION_TASK = '/core/api/v1/SimpleNotification/{id}';
export const REST_URL_CANCEL_NOTIFICATION_TASK = '/core/api/v1/SimpleNotification/Cancel/{id}';

export const ROUTE_NAME_NOTIFICATION = '/notification';
export const ROUTE_NAME_EMAIL = '/email';
export const ROUTE_NAME_SMS = '/sms';
export const ROUTE_NAME_WHATSAPP = '/whatsapp';
export const ROUTE_NAME_PUSH_NOTIFICATION = '/push-notification';
export const ROUTE_NAME_NOTIFICATION_TASK = '/notification-task';

export const RXFORM_EMAIL = 'emailPage';
export const RXFORM_SMS = 'smsPage';
export const RXFORM_WHATSAPP = 'smsPage';
export const RXFORM_PUSH_NOTIFICATION = 'pushNotificationPage';
export const RXFORM_NOTIFICATION_TASK = 'notificationTaskPage';

export const RXFIELD_EMAIL_TO = 'to';
export const RXFIELD_EMAIL_CC = 'cc';
export const RXFIELD_EMAIL_BCC = 'bcc';
export const RXFIELD_EMAIL_SUBJECT = 'subject';
export const RXFIELD_EMAIL_BODY = 'body';
export const RXFIELD_EMAIL_FORMAT = 'format';
export const RXFIELD_EMAIL_PROCESSING_STATUS = 'processingStatus';
export const RXFIELD_EMAIL_NOTES = 'notes';
export const RXFIELD_SMS_TO = 'to';
export const RXFIELD_SMS_MESSAGE = 'message';
export const RXFIELD_SMS_PROCESSING_STATUS = 'processingStatus';
export const RXFIELD_SMS_NOTES = 'notes';
export const RXFIELD_WHATSAPP_TO = 'to';
export const RXFIELD_WHATSAPP_MESSAGE = 'message';
export const RXFIELD_WHATSAPP_PROCESSING_STATUS = 'processingStatus';
export const RXFIELD_WHATSAPP_NOTES = 'notes';
export const RXFIELD_PUSH_NOTIFICATION_TO = 'to';
export const RXFIELD_PUSH_NOTIFICATION_TOS = 'tos';
export const RXFIELD_PUSH_NOTIFICATION_TITLE = 'title';
export const RXFIELD_PUSH_NOTIFICATION_BODY = 'body';
export const RXFIELD_PUSH_NOTIFICATION_FLAG = 'flag';
export const RXFIELD_PUSH_NOTIFICATION_DATA = 'data';
export const RXFIELD_PUSH_NOTIFICATION_PROCESSING_STATUS = 'processingStatus';
export const RXFIELD_PUSH_NOTIFICATION_NOTES = 'notes';
export const RXFIELD_NOTIFICATION_TASK_FILTERED_RECIPIENTS = 'filteredRecipients';
export const RXFIELD_NOTIFICATION_TASK_TITLE = 'title';
export const RXFIELD_NOTIFICATION_TASK_BODY = 'body';
export const RXFIELD_NOTIFICATION_TASK_URL = 'url';
export const RXFIELD_NOTIFICATION_TASK_TOTAL = 'total';
export const RXFIELD_NOTIFICATION_TASK_PROCESSED = 'processed';
export const RXFIELD_NOTIFICATION_TASK_SENT = 'sent';
export const RXFIELD_NOTIFICATION_TASK_ERROR = 'error';
export const RXFIELD_NOTIFICATION_TASK_NOTES = 'notes';
export const RXFIELD_NOTIFICATION_TASK_TASK_STATUS = 'taskStatus';
export const RXFIELD_NOTIFICATION_TASK_PROGRESS = 'progress';
export const RXFIELD_NOTIFICATION_TASK_PROGRESS_NOTES = 'progressNotes';
export const RXFIELD_NOTIFICATION_TASK_START_TIME = 'startTime';
export const RXFIELD_NOTIFICATION_TASK_END_TIME = 'endTime';
export const RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE = 'recipientType';

export const RXSTATE_EMAILS = 'emails';
export const RXSTATE_EMAIL_PAGE = 'uiEmail';
export const RXSTATE_SMSES = 'smses';
export const RXSTATE_SMS_PAGE = 'uiSms';
export const RXSTATE_WHATSAPPS = 'whatsapps';
export const RXSTATE_WHATSAPP_PAGE = 'uiWhatsapp';
export const RXSTATE_PUSH_NOTIFICATIONS = 'pushNotifications';
export const RXSTATE_PUSH_NOTIFICATION_PAGE = 'uiPushNotification';
export const RXSTATE_NOTIFICATION_TASKS = 'notificationTasks';
export const RXSTATE_NOTIFICATION_TASK_PAGE = 'uiNotificationTask';

export const INITIAL_ORDER_BY_EMAILS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_SMSES = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WHATSAPPS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_PUSH_NOTIFICATIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_NOTIFICATION_TASKS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_NOTIFICATION = 'MENUID_NOTIFICATION';
export const MENUID_NOTIFICATION_EMAIL = 'MENUID_NOTIFICATION_EMAIL';
export const MENUID_NOTIFICATION_SMS = 'MENUID_NOTIFICATION_SMS';
export const MENUID_NOTIFICATION_WHATSAPP = 'MENUID_NOTIFICATION_WHATSAPP';
export const MENUID_NOTIFICATION_PUSH_NOTIFICATION = 'MENUID_NOTIFICATION_PUSH_NOTIFICATION';
export const MENUID_NOTIFICATION_NOTIFICATION_TASK = 'MENUID_NOTIFICATION_NOTIFICATION_TASK';

export const NOTIFICATION_TASK_STATUS_QUEUING = 'Queuing';
export const NOTIFICATION_TASK_STATUS_PROCESSING = 'Processing';

export const NOTIFICATION_TASK_TITLE_MAX_LENGTH = 30;

export const PROCESSING_STATUSES = ['Pending', 'Processing', 'Sent', 'Error'];
export const NOTIFICATION_TASK_STATUS = [
  'Queuing', 'Processing', 'Cancelling',
  'Cancelled', 'Finished', 'Error',
];
