import {
  sendPostRequest, buildQueryParams, sendGetRequest, transformQueryResult, sendPutRequest,
  sendDeleteRequest,
} from '../../helper';
import {
  maxChar, CONFIG_ITEM_NAME_DEEP_LINK_SCHEME, CONFIG_ITEM_CONFIG_GROUP_NAME_GLOBAL,
  REST_URL_CONFIG_GROUPS, REST_URL_ADD_EDIT_CONFIG_GROUP, REST_URL_VIEW_DELETE_CONFIG_GROUP,
  REST_URL_CONFIG_ITEMS, REST_URL_ADD_EDIT_CONFIG_ITEM, REST_URL_VIEW_DELETE_CONFIG_ITEM,
  REST_URL_FILTERED_CONFIG_ITEMS, REST_URL_MY_CONFIG_ITEMS, REST_URL_CONFIG_ITEM_BULK_EDIT,
  REST_URL_CONFIG_ACTION_RESYNC_CONFIGS, REST_URL_CONFIG_ACTION_RESYNC_DB_USERS,
  REST_URL_CONFIG_ACTION_RESYNC_MASTER_DATA, REST_URL_CONFIG_ACTION_RESYNC_USERS,
} from './constant';

export * from '../../helper';

export const downloadConfigGroups = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_CONFIG_GROUPS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addConfigGroup = async (order, name, tag, description, token) => {
  const body = {
    order, name, tag, description,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_CONFIG_GROUP, body, token);
};

export const editConfigGroup = async (id, order, name, tag, description, token) => {
  const body = {
    id, order, name, tag, description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_CONFIG_GROUP, body, token);
};

export const downloadConfigGroup = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CONFIG_GROUP.replace(/\{id\}/, id), token);
  return response;
};

export const deleteConfigGroup = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CONFIG_GROUP.replace(/\{id\}/, id), token);
};

const transformShortValue = (value, encrypted) => {
  let tempValue = value;
  if (encrypted) { tempValue = value.replace(/./g, '*'); }
  const shortenVal = tempValue.length > maxChar.number ? `${tempValue.slice(maxChar.start, maxChar.end)}...` : tempValue;
  return shortenVal;
};

export const downloadConfigItems = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_CONFIG_ITEMS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_CONFIG_ITEMS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = response.data.map((item) => ({
    ...item,
    shortValue: transformShortValue(item.value, item.encrypted),
  }));
  return transformQueryResult(response, transformData);
};

export const addConfigItem = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_CONFIG_ITEM, body, token);
};

export const editConfigItem = async (body, token) => {
  await sendPutRequest(REST_URL_ADD_EDIT_CONFIG_ITEM, body, token);
};

export const downloadConfigItem = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CONFIG_ITEM.replace(/\{id\}/, id), token);
  return response;
};

export const deleteConfigItem = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CONFIG_ITEM.replace(/\{id\}/, id), token);
};

export const downloadFilteredConfigItems = async (
  pageNumber, pageSize, orderBy, searchText, token, filter) => {
  const urlWithFilter = REST_URL_FILTERED_CONFIG_ITEMS.replace(/\{name\}/, filter);
  const url = buildQueryParams(urlWithFilter, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = response.data.map((item) => ({
    ...item,
    shortValue: transformShortValue(item.value, item.encrypted),
  }));
  return transformQueryResult(response, transformData);
};

export const editConfigItems = async (body, token) => {
  await sendPutRequest(REST_URL_CONFIG_ITEM_BULK_EDIT, body, token);
};

export const downloadMyConfigItems = async (token) => {
  const response = await sendGetRequest(REST_URL_MY_CONFIG_ITEMS, token);
  return response;
};

export const getDeepLinkScheme = async (data, deepLinkPath, id, token) => {
  const configItems = await downloadMyConfigItems(token);

  const deepLinkScheme = configItems.length > 0 ? configItems.find((x) => x.name === CONFIG_ITEM_NAME_DEEP_LINK_SCHEME && x.configGroupName === CONFIG_ITEM_CONFIG_GROUP_NAME_GLOBAL) : '';

  const result = {
    ...data,
    deepLink: deepLinkScheme ? `${deepLinkScheme.value}${deepLinkPath}${id}` : '',
  };
  return result;
};

export const resyncConfigs = async (token) => {
  const response = await sendPostRequest(REST_URL_CONFIG_ACTION_RESYNC_CONFIGS, null, token);
  return response;
};

export const resyncDBUsers = async (token) => {
  const response = await sendPostRequest(REST_URL_CONFIG_ACTION_RESYNC_DB_USERS, null, token);
  return response;
};

export const resyncMasterData = async (token, selectedMasterData) => {
  const body = selectedMasterData.map((x) => x.value);
  const response = await sendPostRequest(REST_URL_CONFIG_ACTION_RESYNC_MASTER_DATA, body, token);
  return response;
};

export const resyncUsers = async (token) => {
  const response = await sendPostRequest(REST_URL_CONFIG_ACTION_RESYNC_USERS, null, token);
  return response;
};
