import { setResyncMasterData, resyncingMasterData } from '../simple-action';
import { resyncMasterData } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resyncingMasterData(true));

    const { token } = getState().authentication;
    const { selectedMasterData } = getState().uiConfigAction;

    const data = await resyncMasterData(token, selectedMasterData);

    dispatch(setResyncMasterData({}));
    dispatch(setResyncMasterData(data));
  } finally {
    dispatch(resyncingMasterData(false));
  }
};
