import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import {
  INITIAL_ORDER_BY_COVID_DATAS, MENUID_COVID_DATA, PAGE_MODE_TABLE,
  RXFORM_COVID_DATA, RXFIELD_COVID_DATA_EMPLOYEE_STATUS,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import {
  clearCovidDatas, downloadDeleteCovidDataAsync,
  downloadCovidDatasAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setCovidDataSearchText, setCovidDataSelectedOrderBy,
  setCovidDataSelectedPageSize, setCovidDataTappedId,
  setAdvancedFilterDialogSelectedFilterString,
  addEditCovidDataAsync, setAlertConfirmationMessage,
  setCovidDataSelectedEmployeeStatus,
  saveCovidDataAsync,
} from '../../redux/action';
import CovidDataPage from './covid-data.presentation';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiCovidData, covidDatas } = state;
  const { data } = covidDatas;
  const { downloadingDeleting, tappedId } = uiCovidData;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0
    ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiCovidData.downloading,
  downloadingDeleting: state.uiCovidData.downloadingDeleting,
  addingEditing: state.uiCovidData.addingEditing,
  tappedId: state.uiFunctionalPage.tappedId || '',
  initialValues: getInitialValues(state),
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_COVID_DATA));
    dispatch(setCovidDataSearchText(''));
    dispatch(clearCovidDatas());
    dispatch(setCovidDataSelectedPageSize(20));
    dispatch(setCovidDataSelectedOrderBy(INITIAL_ORDER_BY_COVID_DATAS));
    dispatch(downloadCovidDatasAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCovidDatasAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCovidDataSelectedPageSize(pageSize));
    dispatch(downloadCovidDatasAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onRefresh: (pageSize) => {
    dispatch(setCovidDataSelectedPageSize(pageSize));
    dispatch(clearCovidDatas());
    dispatch(downloadCovidDatasAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setCovidDataSearchText(text));
    dispatch(clearCovidDatas());
    dispatch(downloadCovidDatasAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setCovidDataSelectedOrderBy(orderBy));
    dispatch(clearCovidDatas());
    dispatch(downloadCovidDatasAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setCovidDataTappedId(id));
    dispatch(downloadDeleteCovidDataAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearCovidDatas());
    dispatch(downloadCovidDatasAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearCovidDatas());
    dispatch(setCovidDataSelectedOrderBy(INITIAL_ORDER_BY_COVID_DATAS));
    dispatch(downloadCovidDatasAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    date, hospitalized,
    selfIsolation, death, recovered,
    vaccinated, rtAntibody, rtAntigen,
    genose, serologi, pcr,
  }) => {
    try {
      await dispatch(addEditCovidDataAsync(
        date, hospitalized,
        selfIsolation, death, recovered,
        vaccinated, rtAntibody, rtAntigen,
        genose, serologi, pcr,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: async ({
    date, hospitalized,
    selfIsolation, death, recovered,
    vaccinated, rtAntibody, rtAntigen,
    genose, serologi, pcr,
  }) => {
    await dispatch(addEditCovidDataAsync(
      date, hospitalized,
      selfIsolation, death, recovered,
      vaccinated, rtAntibody, rtAntigen,
      genose, serologi, pcr,
    ));
  },
  onEditPressed: (id) => {
    dispatch(setCovidDataTappedId(id));
    dispatch(downloadDeleteCovidDataAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_COVID_DATA));
  },
  onDeletePressed: (id) => {
    dispatch(setCovidDataTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCovidDataAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setCovidDataTappedId(''));
  },
  onEmployeeStatusSelected: (option) => {
    if (option) {
      dispatch(setCovidDataSelectedEmployeeStatus(option));
      dispatch(change(RXFORM_COVID_DATA, RXFIELD_COVID_DATA_EMPLOYEE_STATUS, option));
    } else {
      dispatch(setCovidDataSelectedEmployeeStatus(''));
      dispatch(change(RXFORM_COVID_DATA, RXFIELD_COVID_DATA_EMPLOYEE_STATUS, ''));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveCovidDataAsync())
      .catch((err) => setAlertErrorMessage(err));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidDataPage);
