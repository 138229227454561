import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearBranches, clearCompanies, clearDepartments, clearDivisions, clearArticles,
  clearArticleCategories, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText, setCompanySearchText,
  setDepartmentSearchText, setDivisionSearchText, setArticleSelectedAddMediaMenu,
  setArticleCategorySearchText, setArticlesSearchText, setArticlesSelectedPageSize,
  setArticlesSelectedOrderBy, setArticleTappedId, setArticleSelectedCategory,
  setArticleSelectedVisibilityStatus,
  setNotificationDialogVisibility, setNotificationSelectedBranch,
  setNotificationSelectedCompany, setNotificationSelectedDepartment,
  setNotificationSelectedDivision, downloadArticlesAsync,
  addEditArticleAsync, downloadDeleteArticleAsync, downloadArticleCategoriesAsync,
  sendArticleNotificationAsync, copyingArticleDeepLink, saveArticlesAsync,
} from '../../redux/action';
import {
  downloadBranchesAsync, downloadCompaniesAsync, downloadDepartmentsAsync, downloadDivisionsAsync,
} from '../../../../redux/action';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_ARTICLES,
  PAGE_MODE_TABLE, RXFIELD_ARTICLES_MEDIA, RXFIELD_ARTICLES_CONTENT,
  RXFIELD_ARTICLES_VISIBILITY_STATUS, RXFORM_ARTICLES,
  MENUID_KNOWLEDGE_MANAGEMENT_ARTICLES,
} from '../../constant';
import {
  localDateToUtc, transformDropdownData, transformInitialValues, toMoment,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import ArticlesPage from './article.presentation';

const getInitialValues = (state) => {
  const { articles, uiArticle, uiFunctionalPage } = state;
  const { data } = articles;
  const { downloadingDeleting, tappedId } = uiArticle;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      category: { label: found.category.name, value: found.category.id },
      publishedDate: toMoment(found.publishedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
      notification: {
        title: found.title,
        body: LocalizedString.articlesScreen.labelClickForMoreInfo,
        url: found.deepLink,
      },
      author: found.author?.fullName || null,
    }) : {
      title: '',
      publishedDate: null,
      category: { label: '', value: '' },
      visibilityStatus: '',
    };
  return initVal;
};

const getDiscusId = (state) => {
  const { articles, uiArticle, uiFunctionalPage } = state;
  const { data } = articles;
  const { downloadingDeleting, tappedId } = uiArticle;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId].discusId : '';
};

const mapStateToProps = (state) => ({
  categories: transformDropdownData(state.articleCategories.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiArticle.addingEditing,
  copying: state.uiArticle.copying,
  downloading: state.uiArticle.downloading,
  downloadingDeleting: state.uiArticle.downloadingDeleting,
  selectedAddMediaMenu: state.uiArticle.selectedAddMediaMenu,
  sendingNotification: state.uiArticle.sendingNotification,
  loadingCategory: state.uiArticleCategory.downloading,
  sendingNotifications: state.notification.sendingNotifications,
  initialValues: getInitialValues(state),
  discusId: getDiscusId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_ARTICLES, RXFIELD_ARTICLES_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setArticleSelectedAddMediaMenu(menu));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setArticleSelectedCategory(''));
    dispatch(setArticleCategorySearchText(''));
    dispatch(clearArticleCategories());
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_KNOWLEDGE_MANAGEMENT_ARTICLES));
    dispatch(setArticlesSearchText(''));
    dispatch(clearArticles());
    dispatch(setArticlesSelectedPageSize(20));
    dispatch(setArticlesSelectedOrderBy(INITIAL_ORDER_BY_ARTICLES));
    dispatch(downloadArticlesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/categoryName/, 'category.name');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearArticles());
    dispatch(downloadArticlesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setArticleSelectedCategory(''));
    dispatch(reset(RXFORM_ARTICLES));
  },
  onCancelPressed: () => {
    dispatch(setArticleSelectedCategory(''));
    dispatch(setArticleSelectedVisibilityStatus(''));
    dispatch(reset(RXFORM_ARTICLES));
  },
  onCategoryOptionSelected: (option) => {
    if (option) {
      dispatch(setArticleSelectedCategory(option.value));
    } else {
      dispatch(setArticleSelectedCategory(''));
    }
  },
  onChangeCategoryText: async (text) => {
    try {
      dispatch(setArticleCategorySearchText(text));
      dispatch(clearArticleCategories());
      await dispatch(downloadArticleCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeContentText: (text) => {
    dispatch(change(RXFORM_ARTICLES, RXFIELD_ARTICLES_CONTENT, text));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadArticlesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setArticlesSelectedPageSize(pageSize));
    dispatch(downloadArticlesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteArticleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCopyPressed: (status) => {
    dispatch(copyingArticleDeepLink(!status));
  },
  onCreatePressed: () => {
    dispatch(setArticleTappedId(''));
    dispatch(setArticleCategorySearchText(''));
    dispatch(clearArticleCategories());
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_ARTICLES, RXFIELD_ARTICLES_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setArticleTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setArticleCategorySearchText(''));
    dispatch(clearArticleCategories());
    dispatch(downloadArticleCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setArticleTappedId(id));
    dispatch(downloadDeleteArticleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onOpenSendNotificationDialog: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setCompanySearchText(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setDivisionSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setNotificationSelectedDepartment(''));
    dispatch(setNotificationSelectedDivision(''));
    dispatch(clearBranches());
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setArticlesSelectedPageSize(pageSize));
    dispatch(clearArticles());
    dispatch(downloadArticlesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearArticles());
    dispatch(setArticlesSelectedOrderBy(INITIAL_ORDER_BY_ARTICLES));
    dispatch(downloadArticlesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    title, content, publishedDate, media,
  }) => {
    try {
      const publishedDateUtc = localDateToUtc(publishedDate);
      await dispatch(addEditArticleAsync(title, content, publishedDateUtc, media));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setArticlesSearchText(text));
      dispatch(clearArticles());
      await dispatch(downloadArticlesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationsPressed: () => {
    dispatch(setNotificationDialogVisibility(true));
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(setCompanySearchText(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setDivisionSearchText(''));
    dispatch(setNotificationSelectedBranch(''));
    dispatch(setNotificationSelectedCompany(''));
    dispatch(setNotificationSelectedDepartment(''));
    dispatch(setNotificationSelectedDivision(''));
    dispatch(clearBranches());
    dispatch(clearCompanies());
    dispatch(clearDepartments());
    dispatch(clearDivisions());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDivisionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSendToMePressed: () => {
    dispatch(sendArticleNotificationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setArticlesSelectedOrderBy(orderBy));
    dispatch(clearArticles());
    dispatch(downloadArticlesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    title, content, publishedDate, media,
  }) => {
    const publishedDateUtc = localDateToUtc(publishedDate);
    await dispatch(addEditArticleAsync(title, content, publishedDateUtc, media));
  },
  onViewPressed: (id) => {
    dispatch(setArticleTappedId(id));
    dispatch(downloadDeleteArticleAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setArticleSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_ARTICLES, RXFIELD_ARTICLES_VISIBILITY_STATUS, option));
    } else {
      dispatch(setArticleSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_ARTICLES, RXFIELD_ARTICLES_VISIBILITY_STATUS, ''));
    }
  },
  onDownloadPressed: () => {
    dispatch(saveArticlesAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPage);
