import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import {
  FormControl, FormHelperText, IconButton, makeStyles, Menu, MenuItem, Paper,
} from '@material-ui/core';
import { AddCircleOutlineRounded, ArrowDropDown } from '@material-ui/icons';
import { CircleEditOutline, Delete } from 'mdi-material-ui';
import {
  COLOR_ACCENT, COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_PRIMARY,
} from '../constant';
import LocalizedString from '../localization';
import { TableColumnShape } from '../type';
import AccentButton from './accent-button';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
  greenButton: {
    color: COLOR_PRIMARY,
  },
  redButton: {
    color: COLOR_DANGEROUS,
  },
}));

const addedData = (customLookUpField, newData) => (customLookUpField
  ? newData[customLookUpField] : newData);

const isDuplicatedData = (data, newData, customValidationField, oldData) => {
  const isValidField = (customValidationField
    ? !!Object.prototype.hasOwnProperty.call(newData, customValidationField)
    : !!(data.every((x) => Object.prototype.hasOwnProperty.call(x, 'label') || Object.prototype.hasOwnProperty.call(x, 'value')) && Object.prototype.hasOwnProperty.call(newData, 'value')));

  if (isValidField) {
    if (customValidationField) {
      if (oldData) {
        const oldDataId = data.findIndex((x) => x[customValidationField]
        === oldData[customValidationField]);
        const newDataId = data.findIndex((x) => x[customValidationField]
        === newData[customValidationField]);

        if (oldDataId === newDataId) { return false; }
        return !!data.find((x) => x[customValidationField] === newData[customValidationField]);
      }
      return !!data.find((x) => x[customValidationField] === newData[customValidationField]);
    }
    return !!data.find((x) => x.value.toUpperCase() === newData.value.toUpperCase());
  }
  return false;
};

const isEmptyRequiredField = (newData, requiredTableColumnField, optionalTableColumnField) => {
  if (requiredTableColumnField.length > 0) {
    if (Object.keys(newData).length > 0) {
      const checkRequired = requiredTableColumnField
        .every((x) => Object.prototype.hasOwnProperty.call(newData, x) && newData[x] !== '');

      if (optionalTableColumnField.length > 0) {
        const checkOptional = optionalTableColumnField
          .some((x) => Object.prototype.hasOwnProperty.call(newData, x) && newData[x] !== '');

        return !(checkOptional && checkRequired);
      }
      return !checkRequired;
    }
    return true;
  }
  return false;
};

const isErrorLink = (newData) => {
  const matchLink = (url) => {
    const regexYoutubeWatch = /^https?:\/\/(www\.)?youtube\.com\/watch\?(.+&)?v=([a-zA-Z0-9_-]+)/;
    const regexSimpleYoutube = /^https?:\/\/(www\.)?youtu\.be\/([a-zA-Z0-9_-]+)$/;
    const regexYoutubeEmbed = /^https?:\/\/(www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]+)$/;

    return !(url.match(regexYoutubeWatch) || url.match(regexSimpleYoutube)
    || url.match(regexYoutubeEmbed));
  };

  if (Object.prototype.hasOwnProperty.call(newData, 'link')) {
    if (typeof newData.link === 'string') { return matchLink(newData.link); }
    return matchLink(newData.link.path);
  }
  return false;
};

const EditableTableField = ({
  addMenuList, defaultValue, optionalTableColumnField, requiredTableColumnField, tableColumns,
  disabled, disableDelete, disableEdit, disableToolbar, error, hidden, loading,
  onAddPressed, onDeletePressed,
  currentPage, totalCount, rowStyle,
  addButtonCaption, customDeleteButtonCaption, customDeleteConfirmationMessage, customLookUpField,
  customValidationField, label, helperText, tableLayout,
  ...props
}) => {
  const classes = useStyles();

  const initialState = {
    mouseX: null,
    mouseY: null,
  };
  const [state, setState] = useState(initialState);
  const [data, setData] = useState(defaultValue);
  const [errorUrl, setErrorUrl] = useState(false);
  const [errorDuplicateData, setErrorDuplicateData] = useState(false);
  const [errorRequiredField, setErrorRequiredField] = useState(false);

  useEffect(() => {
    if (!loading && totalCount > 0 && data.length === 0) {
      setData(defaultValue);
    }
  }, [loading, defaultValue, totalCount, data.length]);

  const onCloseMoreMenu = () => {
    setState(initialState);
  };

  const onOpenMoreMenu = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const errorLinkField = errorUrl ? LocalizedString.common.errMsgInvalidYoutubeUrl
    : helperText;
  const errorReqField = errorRequiredField ? LocalizedString.common.errMsgEmptyRequiredFields
    : errorLinkField;
  const errorText = errorDuplicateData ? LocalizedString.common.errMsgDuplicatedData
    : errorReqField;

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={errorDuplicateData || error}>
          {!disabled ? (
            <MaterialTable
              title={label}
              columns={tableColumns}
              data={data}
              isLoading={loading}
              totalCount={totalCount}
              page={currentPage > 0 ? currentPage - 1 : currentPage}
              editable={{
                onRowAdd: (newData) => new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setErrorUrl(false);
                    setErrorRequiredField(false);
                    setErrorDuplicateData(false);
                    resolve();

                    const isDuplicated = isDuplicatedData(data,
                      addedData(customLookUpField, newData),
                      customValidationField);
                    const isEmpty = isEmptyRequiredField(newData, requiredTableColumnField,
                      optionalTableColumnField);

                    if (!isDuplicated) {
                      if (isEmpty) {
                        setErrorRequiredField(true);
                        reject();
                      }
                      if (isErrorLink(newData)) {
                        setErrorUrl(true);
                        reject();
                      }
                      if (!isEmpty && !isErrorLink(newData)) {
                        setErrorUrl(false);
                        setErrorRequiredField(false);
                        setErrorDuplicateData(false);
                        const newArr = [...data, addedData(customLookUpField, newData)];
                        setData(newArr);
                        onAddPressed(newArr, newData);
                      }
                    } else {
                      setErrorDuplicateData(true);
                      reject();
                    }
                  }, 600);
                }),
                onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setErrorUrl(false);
                    setErrorRequiredField(false);
                    setErrorDuplicateData(false);
                    resolve();

                    const isDuplicated = isDuplicatedData(data,
                      addedData(customLookUpField, newData),
                      customValidationField,
                      oldData);
                    const isEmpty = isEmptyRequiredField(newData, requiredTableColumnField,
                      optionalTableColumnField);

                    if (oldData && !isDuplicated) {
                      if (isEmpty) {
                        setErrorRequiredField(true);
                        reject();
                      }
                      if (isErrorLink(newData)) {
                        setErrorUrl(true);
                        reject();
                      }
                      if (!isEmpty && !isErrorLink(newData)) {
                        setErrorUrl(false);
                        setErrorRequiredField(false);
                        setErrorDuplicateData(false);
                        setData(() => {
                          const newArr = [...data];
                          newArr[newArr.indexOf(oldData)] = addedData(customLookUpField, newData);
                          setData(newArr);
                          onAddPressed(newArr);
                        });
                      }
                    }
                    if (isDuplicated) {
                      setErrorDuplicateData(true);
                      reject();
                    }
                  }, 600);
                }),
                onRowDelete: (oldData) => new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    const newArr = [...data];
                    newArr.splice(newArr.indexOf(oldData), 1);
                    setData([...newArr]);
                    onDeletePressed([...newArr], oldData);
                  }, 600);
                }),
              }}
              options={{
                search: false,
                doubleHorizontalScroll: true,
                draggable: false,
                headerStyle: {
                  padding: 15, textAlign: 'left', backgroundColor: COLOR_ACCENT, zIndex: 0,
                },
                cellStyle: { textAlign: 'left' },
                actionsColumnIndex: tableColumns.length,
                paging: false,
                showTitle: !disableToolbar,
                toolbar: !disableToolbar,
                rowStyle: rowStyle || undefined,
                tableLayout: tableLayout || undefined,
              }}
              components={{
                Container: (prop) => <Paper elevation={0} {...prop} />,
                Action: (prop) => {
                  const {
                    action, data: actionData, size, disabled: disableButton,
                  } = prop;
                  const { tooltip, onClick } = action;
                  if (tooltip === 'Add') {
                    if (addButtonCaption) {
                      if (addMenuList.length > 0) {
                        return (
                          <div>
                            <AccentButton
                              startIcon={<ArrowDropDown />}
                              caption={addButtonCaption}
                              onClick={onOpenMoreMenu}
                            />

                            <Menu
                              keepMounted
                              open={state.mouseY !== null}
                              onClose={onCloseMoreMenu}
                              anchorReference="anchorPosition"
                              anchorPosition={
                              state.mouseY !== null && state.mouseX !== null
                                ? { top: state.mouseY, left: state.mouseX }
                                : undefined
                            }
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: 200,
                                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
                                },
                              }}
                            >
                              {addMenuList.map((item) => (
                                <MenuItem
                                  key={item.caption}
                                  onClick={(event) => {
                                    item.onPress();
                                    onCloseMoreMenu();
                                    onClick(event, actionData);
                                    event.stopPropagation();
                                  }}
                                >
                                  {item.caption}
                                </MenuItem>
                              ))}
                            </Menu>
                          </div>
                        );
                      }
                      return (
                        <AccentButton
                          startIcon={<AddCircleOutlineRounded />}
                          caption={addButtonCaption}
                          onClick={(event) => onClick(event, actionData)}
                        />
                      );
                    }
                    return null;
                  }
                  if (tooltip === 'Save') {
                    return (
                      <AccentButton
                        variant="outlined"
                        caption={LocalizedString.common.buttonCaptionApply}
                        onClick={(event) => onClick(event, actionData)}
                      />
                    );
                  }
                  if (tooltip === 'Cancel') {
                    return (
                      <AccentButton
                        variant="text"
                        caption={LocalizedString.common.buttonCaptionCancel}
                        className={classes.redButton}
                        onClick={(event) => onClick(event, actionData)}
                      />
                    );
                  }
                  let newAction = action;
                  const newActionData = newAction(actionData);

                  if (disableEdit && newActionData.tooltip === 'Delete') {
                    if (customDeleteButtonCaption) {
                      return (
                        <AccentButton
                          variant="text"
                          caption={customDeleteButtonCaption}
                          onClick={(event) => {
                            if (typeof newAction === 'function') { newAction = newActionData; }
                            newAction.onClick(event, actionData);
                          }}
                          disabled={disableDelete || disableButton}
                        />
                      );
                    }
                    return (
                      <IconButton
                        onClick={(event) => {
                          if (typeof newAction === 'function') { newAction = newActionData; }
                          newAction.onClick(event, actionData);
                        }}
                        disabled={disableDelete || disableButton}
                        size={size}
                      >
                        <Delete
                          className={classes.redButton}
                          disabled={disableDelete || disableButton}
                        />
                      </IconButton>
                    );
                  }
                  if (disableEdit && newActionData.tooltip === 'Edit') { return null; }
                  return (
                    <IconButton
                      onClick={(event) => {
                        if (typeof newAction === 'function') { newAction = newActionData; }
                        newAction.onClick(event, actionData);
                      }}
                      disabled={disableDelete || disableButton}
                      size={size}
                    >
                      {newActionData.tooltip === 'Edit'
                        ? <CircleEditOutline className={classes.greenButton} />
                        : (
                          <Delete
                            className={classes.redButton}
                            disabled={disableDelete || disableButton}
                          />
                        )}
                    </IconButton>
                  );
                },
              }}
              localization={customDeleteConfirmationMessage ? {
                body: { editRow: { deleteText: customDeleteConfirmationMessage } },
              } : undefined}
              {...props}
            />
          ) : (
            <MaterialTable
              title={label}
              columns={tableColumns}
              data={data}
              isLoading={loading}
              totalCount={totalCount}
              page={currentPage > 0 ? currentPage - 1 : currentPage}
              options={{
                search: false,
                doubleHorizontalScroll: true,
                draggable: false,
                headerStyle: {
                  padding: 15, textAlign: 'left', backgroundColor: COLOR_ACCENT, zIndex: 0,
                },
                cellStyle: { textAlign: 'left' },
                actionsColumnIndex: tableColumns.length,
                paging: false,
                showTitle: !disableToolbar,
                toolbar: !disableToolbar,
                rowStyle: rowStyle || undefined,
                tableLayout: tableLayout || undefined,
              }}
              components={{
                Container: (prop) => <Paper elevation={0} {...prop} />,
              }}
              {...props}
            />
          )}
          <FormHelperText className={classes.helperText}>{errorText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default EditableTableField;

EditableTableField.propTypes = {
  addMenuList: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  optionalTableColumnField: PropTypes.arrayOf(PropTypes.string),
  requiredTableColumnField: PropTypes.arrayOf(PropTypes.string),
  tableColumns: PropTypes.arrayOf(TableColumnShape).isRequired,
  disabled: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  onAddPressed: PropTypes.func,
  onDeletePressed: PropTypes.func,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  addButtonCaption: PropTypes.string,
  customDeleteButtonCaption: PropTypes.string,
  customDeleteConfirmationMessage: PropTypes.string,
  customLookUpField: PropTypes.string,
  customValidationField: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  tableLayout: PropTypes.string,
};

EditableTableField.defaultProps = {
  addMenuList: [],
  optionalTableColumnField: [],
  requiredTableColumnField: [],
  defaultValue: [],
  disabled: false,
  disableDelete: false,
  disableEdit: false,
  disableToolbar: false,
  error: false,
  hidden: false,
  loading: false,
  rowStyle: null,
  onAddPressed: () => {},
  onDeletePressed: () => {},
  currentPage: 0,
  totalCount: 0,
  addButtonCaption: LocalizedString.common.buttonCaptionAdd,
  customDeleteButtonCaption: null,
  customDeleteConfirmationMessage: null,
  customLookUpField: null,
  customValidationField: null,
  helperText: null,
  tableLayout: 'auto',
};
