import { ROUTE_NAME_DASHBOARD, ROUTE_NAME_LOGIN } from '../../../constant';

export default (navigateTo) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  if (token) {
    const { currentUser } = getState();
    const user = currentUser ? getState().users[currentUser.id] : undefined;
    if (user) {
      navigateTo(ROUTE_NAME_DASHBOARD);
    } else {
      navigateTo(ROUTE_NAME_LOGIN);
    }
  } else {
    navigateTo(ROUTE_NAME_LOGIN);
  }
};
