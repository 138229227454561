import {
  osName, osVersion, mobileModel, mobileVendor, browserName, fullBrowserVersion,
} from 'react-device-detect';
import uuid from 'uuid/v4';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  HTTP_HEADER_VALUE_JSON, DEBOUNCED_SEARCH_DURATION,
  IMAGE_SOURCE_URI_PREFIX, IMAGE_SOURCE_URI_PNG_PREFIX_LENGTH, IMAGE_SOURCE_URI_JPEG_PREFIX_LENGTH,
  DATE_TIME_FORMAT_WITHOUT_PIPE, REVERSED_ISO_DATE_FORMAT, REVERSED_ISO_DATE_TIME_FORMAT,
  TIME_FORMAT,
  TIMEZONE_WIB, TIMEZONE_WIT, TIMEZONE_WITA,
  TIME_MODE_CLOCK_IN, TIME_MODE_CLOCK_OUT, URL_ID_TAG,
  REST_METHOD_GET, REST_METHOD_POST, REST_METHOD_DELETE, REST_METHOD_PUT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, REST_URL_SAVE_FILE,
  REST_URL_LOGIN, REST_URL_LOGOUT,
  REST_URL_PROVINCES, REST_URL_ADD_EDIT_PROVINCE, REST_URL_VIEW_DELETE_PROVINCE,
  REST_URL_ENABLE_DISABLE_PROVINCE,
  REST_URL_CITIES, REST_URL_ADD_EDIT_CITY, REST_URL_VIEW_DELETE_CITY, REST_URL_ENABLE_DISABLE_CITY,
  REST_URL_BRANCHES, REST_URL_ADD_EDIT_BRANCH, REST_URL_VIEW_DELETE_BRANCH,
  REST_URL_ENABLE_DISABLE_BRANCH,
  REST_URL_PERMISSIONS, REST_URL_ADD_EDIT_PERMISSION, REST_URL_VIEW_DELETE_PERMISSION,
  REST_URL_ROLES, REST_URL_ADD_EDIT_ROLE, REST_URL_VIEW_DELETE_ROLE,
  REST_URL_API_KEYS, REST_URL_ADD_EDIT_API_KEY, REST_URL_VIEW_DELETE_API_KEY,
  REST_URL_TOKENS, REST_URL_FILTERED_TOKENS, REST_URL_VIEW_DELETE_TOKEN,
  REST_URL_USERS, REST_URL_ADD_EDIT_USER, REST_URL_VIEW_DELETE_USER,
  REST_URL_APPLICATIONS, REST_URL_ADD_EDIT_APPLICATION, REST_URL_VIEW_DELETE_APPLICATION,
  REST_URL_PROFILES, REST_URL_ADD_EDIT_PROFILE, REST_URL_VIEW_DELETE_PROFILE,
  REST_URL_ENABLE_DISABLE_PROFILE,
  REST_URL_SUMMARY_TOTAL_USERS, REST_URL_SUMMARY_ACTIVE_USERS, REST_URL_SUMMARY_ACTIVE_DEVICES,
  REST_URL_SUMMARY_OS_DISTRIBUTION, REST_URL_SUMMARY_LOGIN_USING,
  REST_URL_SUMMARY_ANDROID_VERSION_DISTRIBUTION,
  REST_URL_SUMMARY_IOS_VERSION_DISTRIBUTION,
  REST_URL_SEND_NOTIFICATION, REST_URL_COMPANIES, REST_URL_DIVISIONS,
  REST_URL_DEPARTMENTS, REST_URL_SUMMARY_BROWSER_DISTRIBUTION,
  REST_URL_SUMMARY_APP_VERSION_DISTRIBUTION,
  REST_URL_DISABLE_TOKEN,
  REST_URL_SERVICES, REST_URL_ADD_EDIT_SERVICE, REST_URL_VIEW_DELETE_SERVICE,
  REST_URL_SCOPES, REST_URL_ADD_EDIT_SCOPE, REST_URL_VIEW_DELETE_SCOPE,
  REST_URL_BANNERS, REST_URL_ADD_EDIT_BANNER, REST_URL_VIEW_DELETE_BANNER, BASE_64_PREFIX,
  REST_URL_APP_VERSIONS, REST_URL_DEVICE_MODELS, REST_URL_DEVICE_MANUFACTURERS, REST_URL_OS_NAMES,
  REST_URL_OS_VERSIONS, REST_URL_TIME_ZONES,
  REST_URL_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTION,
  REST_URL_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTION,
  REST_URL_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTION, REST_URL_SUMMARY_TIMEZONE_DISTRIBUTION,
  REST_URL_TOKEN_TOTAL_PERSON_COUNT, REST_URL_PROFILE_TOTAL_PERSON_COUNT,
  REST_URL_SUMMARY_ONLINE_USERS, REST_URL_DOWNLOAD_PROFILE_PICTURES,
  REST_URL_ADD_EDIT_COMPANY_MDATA, REST_URL_VIEW_DELETE_COMPANY_MDATA, REST_URL_MDATA_COMPANIES,
  REST_URL_ENABLE_DISABLE_COMPANY_MDATA,
  REST_URL_DISTRICTS, REST_URL_ADD_EDIT_DISTRICT, REST_URL_VIEW_DELETE_DISTRICT,
  REST_URL_ENABLE_DISABLE_DISTRICT,
  REST_URL_SUBDISTRICTS, REST_URL_ADD_EDIT_SUBDISTRICT, REST_URL_VIEW_DELETE_SUBDISTRICT,
  REST_URL_ENABLE_DISABLE_SUBDISTRICT,
  REST_URL_RESYNC_MASTER_DATA_INDIVIDUALLY, REST_URL_RESYNC_USER_INDIVIDUALLY,
} from '../constant';

export const buildQueryParams = (templateUrl, pageNumber, pageSize, orderBy,
  searchText) => templateUrl
  .replace(/\{pageNumber\}/, pageNumber)
  .replace(/\{pageSize\}/, pageSize)
  .replace(/\{orderBy\}/, orderBy)
  .replace(/\{searchText\}/, searchText);

export const convertArrToObj = (array, objKey) => (!array ? {} : array.reduce(
  (obj, item) => ({ ...obj, [item[objKey]]: item }), {},
));

export const getTimeRegion = (gmt) => {
  switch (gmt) {
    case TIMEZONE_WIB.offset:
      return TIMEZONE_WIB.symbol;
    case TIMEZONE_WITA.offset:
      return TIMEZONE_WITA.symbol;
    case TIMEZONE_WIT.offset:
      return TIMEZONE_WIT.symbol;
    default: return null;
  }
};

export const getTimezone = (offset) => {
  switch (offset) {
    case TIMEZONE_WIB.offset:
      return TIMEZONE_WIB.timezone;
    case TIMEZONE_WITA.offset:
      return TIMEZONE_WITA.timezone;
    case TIMEZONE_WIT.offset:
      return TIMEZONE_WIT.timezone;
    default: return null;
  }
};

export const isDecimal = (num) => (num % 1) !== 0;

export const readFile = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => resolve(reader.result), false);
  reader.readAsDataURL(file);
});

export const sortAsc = (a, b) => (a > b ? 1 : -1);

export const sortDesc = (a, b) => (a > b ? -1 : 1);

export const toDecimal = (num) => parseFloat(num);

export const toMoment = (dateTime, offset) => (offset ? moment(dateTime).tz(getTimezone(offset))
  : moment(dateTime).tz(moment.tz.guess(true)));

export const toUtcMoment = (dateTime, offset) => {
  const transformDate = moment.utc(dateTime).format(REVERSED_ISO_DATE_TIME_FORMAT);
  const result = moment.tz(transformDate, getTimezone(offset))
    .utc().format(REVERSED_ISO_DATE_TIME_FORMAT);
  return result;
};

export const localDateToUtc = (localDate) => {
  const utcOffset = toMoment(localDate).utcOffset();
  const utcDate = toMoment(localDate)
    .subtract(utcOffset, 'minutes')
    .format(REVERSED_ISO_DATE_TIME_FORMAT);
  return utcDate;
};

export const toNumber = (text) => text && parseInt(text, 10);

export const transformImageUrl = (url) => (url.includes(IMAGE_SOURCE_URI_PREFIX)
  ? url.slice(IMAGE_SOURCE_URI_PNG_PREFIX_LENGTH) : url.slice(IMAGE_SOURCE_URI_JPEG_PREFIX_LENGTH));

export const transformInitialValues = (item, renamedField) => ({
  ...item,
  ...renamedField,
  createdDate: toMoment(item.createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
  lastModifiedDate: toMoment(item.lastModifiedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
});

export const transformLocalImagePath = (imagePath) => (imagePath && imagePath.match(/File\/(.*)/i) ? null : imagePath);

export const transformMediaData = (response) => {
  if (response instanceof Array && response.length > 0) {
    return response.map((data) => ({
      ...data,
      media: data.media.map((item) => ({
        ...item,
        path: item.type === 'Image' ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${item.path}` : item.path,
      })).sort((a, b) => sortAsc(a.order, b.order)),
    }));
  }
  if (response instanceof Array && response.length === 0) { return []; }
  if (typeof response === 'object' && Object.keys(response).length > 0) {
    return {
      ...response,
      media: response.media.length > 0 ? response.media.map((item) => ({
        ...item,
        path: item.type === 'Image' ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${item.path}` : item.path,
      })).sort((a, b) => sortAsc(a.order, b.order)) : [],
    };
  }
  return {};
};

export const transformQueryResult = (response, transformData) => ({
  data: convertArrToObj(transformData || response.data, 'id'),
  meta: {
    pageSize: response.pageSize || 0,
    currentPage: response.currentPage || 0,
    totalCount: response.totalCount || 0,
    totalPages: response.totalPages || 0,
  },
});

const getEncodedUriComponentList = () => {
  const arr = [];
  const ASCII_CHARACTERS_TOTAL = 256;
  for (let i = 0; i < ASCII_CHARACTERS_TOTAL; i += 1) {
    const char = String.fromCharCode(i);
    const buf = Buffer.from(char, 'utf8');
    arr.push(`%${buf.toString('hex')}`);
  }
  return arr;
};

export const transformSearchText = (text) => {
  if (text) {
    if (text.match(/^[0-9]*$/gm)) { return `%25${text}%25`; }
    if (new RegExp(getEncodedUriComponentList().join('|')).test(`%${text.toLowerCase()}`)) {
      return `${text}%`;
    }
    return `%${text}%`;
  }
  return '%';
};

export const toCurrency = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const toKFormat = (num) => (Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num));

const getHttpHeaders = (authenticationToken) => {
  let headers = {
    'Content-Type': HTTP_HEADER_VALUE_JSON,
    'X-DeviceId': 'WebApp',
    'X-DeviceManufacturer': mobileVendor,
    'X-DeviceModel': mobileModel,
    'X-TimeZone': moment.tz.guess(),
    'X-OSName': osName,
    'X-OSVersion': osVersion,
    'X-AppVersion': 'WebApp',
    'X-Notes': `Browser: ${browserName} v${fullBrowserVersion}`,
  };

  if (authenticationToken) {
    headers = { ...headers, Authorization: authenticationToken };
  }

  return headers;
};

const handleResponse = async (response) => {
  const responseText = await response.text();
  if (response.status >= 200 && response.status <= 299) {
    if (responseText) {
      return JSON.parse(responseText);
    }
    return undefined;
  }

  let err = '';
  try {
    err = JSON.parse(responseText);
  } catch {
    throw new Error(responseText);
  }
  throw new Error(err ? err.message : err);
};

const handleFileResponse = async (response) => {
  if (response.status >= 200 && response.status <= 299) {
    return response;
  }

  let err = '';
  try {
    const responseText = await response.text();
    err = JSON.parse(responseText);
  } catch {
    const responseText = await response.text();
    throw new Error(responseText);
  }
  throw new Error(err ? err.message : err);
};

export const sendGetRequest = async (apiPath, authenticationToken) => {
  const url = `${REST_BASE_URL}${apiPath}`;
  const method = REST_METHOD_GET;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers });
  return handleResponse(response);
};

export const sendGetFileRequest = async (apiPath, authenticationToken) => {
  const url = `${REST_BASE_URL}${apiPath}`;
  const method = REST_METHOD_GET;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers });
  return handleFileResponse(response);
};

export const sendPostRequest = async (apiPath, body, authenticationToken) => {
  const bodyStr = JSON.stringify(body);
  const url = encodeURI(`${REST_BASE_URL}${apiPath}`);
  const method = REST_METHOD_POST;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers, body: bodyStr });
  return handleResponse(response);
};

export const sendDeleteRequest = async (apiPath, authenticationToken) => {
  const url = encodeURI(`${REST_BASE_URL}${apiPath}`);
  const method = REST_METHOD_DELETE;
  const headers = getHttpHeaders(authenticationToken);
  const response = await fetch(url, { method, headers });
  return handleResponse(response);
};

export const sendPutRequest = async (apiPath, body, authenticationToken) => {
  const url = encodeURI(`${REST_BASE_URL}${apiPath}`);
  const method = REST_METHOD_PUT;
  const headers = getHttpHeaders(authenticationToken);

  if (body) {
    const bodyStr = JSON.stringify(body);
    const response = await fetch(url, { method, headers, body: bodyStr });
    return handleResponse(response);
  }
  const response = await fetch(url, { method, headers });
  return handleResponse(response);
};

export const removeAllStorage = async () => {
  const keys = _.keys(localStorage);

  keys.forEach((x) => {
    try {
      localStorage.removeItem(x);
    } catch (error) {
      // ignore errors
    }
  });
};

const getPermissions = (roles) => {
  const join = roles
    .reduce((prev, curr) => [...prev, ...curr.permissions], [])
    .filter((item, index, arr) => !(arr.indexOf(item) !== index))
    .reduce((prev, x) => ({ ...prev, [x]: x }), {});

  return join;
};

export const login = async (username, password) => {
  const body = { username, password };
  const auth = await sendPostRequest(REST_URL_LOGIN, body);

  const authModif = {
    ...auth,
    user: { ...auth.user, permissions: getPermissions(auth.user.roles) },
  };
  return authModif;
};

export const logout = async (token) => sendPostRequest(REST_URL_LOGOUT, null, token);

export const downloadProvinces = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_PROVINCES.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addProvince = async (name, timeZone, code, token) => {
  const body = { name, timeZone, code };
  await sendPostRequest(REST_URL_ADD_EDIT_PROVINCE, body, token);
};

export const editProvince = async (id, name, timeZone, code, token) => {
  const body = {
    id, name, timeZone, code,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PROVINCE, body, token);
};

export const downloadProvince = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PROVINCE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteProvince = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PROVINCE.replace(/\{id\}/, id), token);
};

export const enableDisableProvince = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_PROVINCE, body, token);
};

export const saveProvinces = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_PROVINCES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadCities = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_CITIES.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCity = async (name, provinceId, code, token) => {
  const body = { name, provinceId, code };
  await sendPostRequest(REST_URL_ADD_EDIT_CITY, body, token);
};

export const editCity = async (id, name, provinceId, code, token) => {
  const body = {
    id, name, provinceId, code,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_CITY, body, token);
};

export const downloadCity = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CITY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteCity = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CITY.replace(/\{id\}/, id), token);
};

export const enableDisableCity = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_CITY, body, token);
};


export const saveCities = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_CITIES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadBranches = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_BRANCHES.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const sortedArea = {
    ...response,
    data: response.data.map((branch) => ({
      ...branch,
      area: branch.area.sort((a, b) => sortAsc(a.order, b.order)),
    })),
  };
  return transformQueryResult(sortedArea);
};

export const addBranch = async (name, description, latitude, longitude, googleMapUrl, address,
  postalCode, phone, email, cityId, startWorkingHour, startWorkingHourMarginBeforeMinutes,
  startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
  endWorkingHourMarginAfterMinutes, area, earliestClockInDriftTimeMinutes,
  autoClockOutAfterMinutes, companyId, token) => {
  const body = {
    name,
    description,
    latitude,
    longitude,
    googleMapUrl,
    address,
    postalCode,
    phone,
    email,
    cityId,
    companyId,
    startWorkingHour,
    startWorkingHourMarginBeforeMinutes,
    startWorkingHourMarginAfterMinutes,
    endWorkingHour,
    endWorkingHourMarginBeforeMinutes,
    endWorkingHourMarginAfterMinutes,
    area,
    earliestClockInDriftTimeMinutes,
    autoClockOutAfterMinutes,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_BRANCH, body, token);
};

export const editBranch = async (id, name, description, latitude, longitude, googleMapUrl, address,
  postalCode, phone, email, cityId, startWorkingHour, startWorkingHourMarginBeforeMinutes,
  startWorkingHourMarginAfterMinutes, endWorkingHour, endWorkingHourMarginBeforeMinutes,
  endWorkingHourMarginAfterMinutes, area, earliestClockInDriftTimeMinutes,
  autoClockOutAfterMinutes, companyId, token) => {
  const body = {
    id,
    name,
    description,
    latitude,
    longitude,
    googleMapUrl,
    address,
    postalCode,
    phone,
    email,
    cityId,
    companyId,
    startWorkingHour,
    startWorkingHourMarginBeforeMinutes,
    startWorkingHourMarginAfterMinutes,
    endWorkingHour,
    endWorkingHourMarginBeforeMinutes,
    endWorkingHourMarginAfterMinutes,
    area,
    earliestClockInDriftTimeMinutes,
    autoClockOutAfterMinutes,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_BRANCH, body, token);
};

export const downloadBranch = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_BRANCH.replace(/\{id\}/, id), token);
  return {
    ...response,
    area: response.area.sort((a, b) => sortAsc(a.order, b.order)),
  };
};

export const deleteBranch = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_BRANCH.replace(/\{id\}/, id), token);
};

export const enableDisableBranch = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_BRANCH, body, token);
};

export const saveBranches = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_BRANCHES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadPermissions = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_PERMISSIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addPermission = async (name, description, token) => {
  const body = { name, description };
  await sendPostRequest(REST_URL_ADD_EDIT_PERMISSION, body, token);
};

export const editPermission = async (id, name, description, token) => {
  const body = { id, name, description };
  await sendPutRequest(REST_URL_ADD_EDIT_PERMISSION, body, token);
};

export const downloadPermission = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PERMISSION.replace(/\{id\}/, id), token);
  return response;
};

export const deletePermission = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_PERMISSION.replace(/\{id\}/, id), token);
};

export const downloadUsers = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_USERS.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_USERS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const editUser = async (id, username, fullName, email, phone, info1, info2, info3,
  status, origin, roles, token) => {
  const body = {
    id, username, fullName, email, phone, info1, info2, info3, status, origin, roles,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_USER, body, token);
};

export const downloadUser = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_USER.replace(/\{id\}/, id), token);
  return response;
};

export const resyncUserIndividually = async (id, token) => {
  const body = [id];
  await sendPostRequest(REST_URL_RESYNC_USER_INDIVIDUALLY, body, token);
};

export const downloadRoles = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_ROLES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addRole = async (name, permissions, description, token) => {
  const body = { name, permissions, description };
  await sendPostRequest(REST_URL_ADD_EDIT_ROLE, body, token);
};

export const editRole = async (id, name, permissions, description, token) => {
  const body = {
    id, name, permissions, description,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_ROLE, body, token);
};

export const downloadRole = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ROLE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteRole = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ROLE.replace(/\{id\}/, id), token);
};

export const downloadApiKeys = async (pageNumber, pageSize, orderBy, searchText,
  filterString = '', token) => {
  const url = buildQueryParams(REST_URL_API_KEYS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addApiKey = async (scope, consumerAppName, ownerAppName, token) => {
  const body = { scope, consumerAppName, ownerAppName };
  await sendPostRequest(REST_URL_ADD_EDIT_API_KEY, body, token);
};

export const editApiKey = async (id, scope, consumerAppName, ownerAppName, apiToken, token) => {
  const body = {
    id, scope, consumerAppName, ownerAppName, token: apiToken,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_API_KEY, body, token);
};

export const downloadApiKey = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_API_KEY.replace(/\{id\}/, id), token);
  return response;
};

export const deleteApiKey = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_API_KEY.replace(/\{id\}/, id), token);
};

export const downloadTokens = async (pageNumber, pageSize, orderBy, searchText, userId,
  filterString, token) => {
  const tokenUrl = userId ? REST_URL_FILTERED_TOKENS.replace(/\{userId\}/, userId).replace(/\{validUntil\}/, toUtcMoment(toMoment(), moment.tz.guess())) : REST_URL_TOKENS.replace(/\{filterString\}/, filterString);
  const url = buildQueryParams(tokenUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadToken = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_TOKEN.replace(/\{id\}/, id), token);
  return response;
};

export const deleteToken = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_TOKEN.replace(/\{id\}/, id), token);
};

export const disableToken = async (token, authenticationToken) => {
  const body = { token };
  await sendPutRequest(REST_URL_DISABLE_TOKEN, body, authenticationToken);
};

export const downloadTokenTotalPersonCount = async (filterString, token) => {
  const currentUtcTime = toUtcMoment(toMoment(), moment.tz.guess());
  const response = await sendGetRequest(REST_URL_TOKEN_TOTAL_PERSON_COUNT.replace(/\{endDate\}/, currentUtcTime)
    .replace(/\{filterString\}/, filterString), token);
  return response;
};

export const transformDropdownData = (data) => Object.values(data).map((item) => ({
  label: item.fullName || item.name || item.title,
  value: item.id,
}));

export const downloadApplications = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_APPLICATIONS, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = response.data.map((x) => ({
    ...x,
    iconPath: `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${x.iconPath}`,
  }));
  return transformQueryResult(response, transformData);
};

export const addApplication = async (name, description, url, androidAppId, iosAppId, downloadUrl,
  order, permission, minAppVersion, maxAppVersion, icon, token) => {
  const body = {
    name,
    description,
    url,
    androidAppId,
    iosAppId,
    downloadUrl,
    order,
    permission,
    minAppVersion,
    maxAppVersion,
    icon,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_APPLICATION, body, token);
};

export const editApplication = async (id, name, description, url, androidAppId, iosAppId,
  downloadUrl, order, permission, minAppVersion, maxAppVersion, icon, token) => {
  const body = {
    id,
    name,
    description,
    url,
    androidAppId,
    iosAppId,
    downloadUrl,
    order,
    permission,
    minAppVersion,
    maxAppVersion,
    icon,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_APPLICATION, body, token);
};

export const downloadApplication = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_APPLICATION.replace(/\{id\}/, id), token);
  return response;
};

export const deleteApplication = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_APPLICATION.replace(/\{id\}/, id), token);
};

export const downloadProfiles = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = filterString
    ? buildQueryParams(REST_URL_PROFILES.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(REST_URL_PROFILES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const editProfile = async (id, employeeId, dateOfBirth, gender, jobTitle, department,
  division, company, managerId, profilePicture, startWorkingHour,
  startWorkingHourMarginBeforeMinutes, startWorkingHourMarginAfterMinutes, endWorkingHour,
  endWorkingHourMarginBeforeMinutes, endWorkingHourMarginAfterMinutes, workingHourTimeZone,
  branchId, earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes, token) => {
  const body = {
    id,
    employeeId,
    dateOfBirth,
    gender,
    jobTitle,
    department,
    division,
    company,
    managerId,
    profilePicture,
    startWorkingHour,
    startWorkingHourMarginBeforeMinutes,
    startWorkingHourMarginAfterMinutes,
    endWorkingHour,
    endWorkingHourMarginBeforeMinutes,
    endWorkingHourMarginAfterMinutes,
    workingHourTimeZone,
    branchId,
    earliestClockInDriftTimeMinutes,
    autoClockOutAfterMinutes,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_PROFILE, body, token);
};

export const downloadProfile = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_PROFILE.replace(/\{id\}/, id), token);
  const transformData = {
    ...response,
    profilePicture: response.profilePicture ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${response.profilePicture}` : null,
    roles: response.roles.length > 0 ? response.roles.join(', ') : null,
  };
  return transformData;
};

export const enableDisableProfile = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_PROFILE, body, token);
};

export const saveProfiles = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_PROFILES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadSummaryTotalUsers = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_TOTAL_USERS, token);
  return response;
};

export const downloadSummaryActiveUsers = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_ACTIVE_USERS, token);
  return response;
};

export const downloadSummaryActiveDevices = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_ACTIVE_DEVICES, token);
  return response;
};

export const downloadSummaryOsDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_OS_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryLoginUsing = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_LOGIN_USING, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryAndroidVersionDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_ANDROID_VERSION_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryIosVersionDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_IOS_VERSION_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryDeviceManufacturerDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_DEVICE_MANUFACTURER_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryAndroidDeviceModelDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_ANDROID_DEVICE_MODEL_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryIosDeviceModelDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_IOS_DEVICE_MODEL_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryOnlineUsers = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_ONLINE_USERS, token);
  return response;
};

export const downloadCompanies = async (searchText, token) => {
  const response = await sendGetRequest(REST_URL_COMPANIES.replace(/\{searchText\}/, searchText), token);
  const transformResponse = response.map((x) => ({ id: uuid(), name: x }));
  return transformQueryResult({}, transformResponse);
};

export const downloadMDataCompanies = async (
  pageNumber, pageSize, orderBy, searchText, filterString, token,
) => {
  const url = buildQueryParams(REST_URL_MDATA_COMPANIES.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCompanyMData = async (name, description, latitude, longitude, googleMapUrl, address,
  postalCode, phone, tags, email, website, cityId, logo, banner, appStoreUrl, playStoreUrl,
  token) => {
  const body = {
    name,
    description,
    latitude,
    longitude,
    googleMapUrl,
    address,
    postalCode,
    phone,
    tags,
    email,
    website,
    cityId,
    logo,
    banner,
    appStoreUrl,
    playStoreUrl,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_COMPANY_MDATA, body, token);
};

export const editCompanyMData = async (id, name, description, latitude, longitude, googleMapUrl,
  address, postalCode, phone, tags, email, website, cityId, logo, banner, clearlogo, appStoreUrl,
  playStoreUrl, token) => {
  const body = {
    id,
    name,
    description,
    latitude,
    longitude,
    googleMapUrl,
    address,
    postalCode,
    phone,
    tags,
    email,
    website,
    cityId,
    logo,
    banner,
    clearlogo,
    appStoreUrl,
    playStoreUrl,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_COMPANY_MDATA, body, token);
};

export const downloadCompanyMData = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_COMPANY_MDATA.replace(/\{id\}/, id), token);
  return response;
};

export const saveCompaniesMData = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_MDATA_COMPANIES.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const enableDisableCompanyMData = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_COMPANY_MDATA, body, token);
};

export const downloadDivisions = async (searchText, token) => {
  const response = await sendGetRequest(REST_URL_DIVISIONS.replace(/\{searchText\}/, searchText), token);
  const transformResponse = response.map((x) => ({ id: uuid(), name: x }));
  return transformQueryResult({}, transformResponse);
};

export const downloadDepartments = async (searchText, token) => {
  const response = await sendGetRequest(REST_URL_DEPARTMENTS.replace(/\{searchText\}/, searchText), token);
  const transformResponse = response.map((x) => ({ id: uuid(), name: x }));
  return transformQueryResult({}, transformResponse);
};

export const downloadProfileTotalPersonCount = async (filterString, token) => {
  const response = await sendGetRequest(REST_URL_PROFILE_TOTAL_PERSON_COUNT.replace(/\{filterString\}/, filterString), token);
  return response;
};

export const downloadSummaryBrowserDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_BROWSER_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryTimezoneDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_TIMEZONE_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadSummaryAppVersionDistribution = async (token) => {
  const response = await sendGetRequest(REST_URL_SUMMARY_APP_VERSION_DISTRIBUTION, token);
  return convertArrToObj(response, 'name');
};

export const downloadServices = async (pageNumber, pageSize, orderBy, searchText, token) => {
  const url = buildQueryParams(REST_URL_SERVICES, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addService = async (name, description, token) => {
  const body = { name, description };
  await sendPostRequest(REST_URL_ADD_EDIT_SERVICE, body, token);
};

export const editService = async (id, name, description, token) => {
  const body = { id, name, description };
  await sendPutRequest(REST_URL_ADD_EDIT_SERVICE, body, token);
};

export const downloadService = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SERVICE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteService = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SERVICE.replace(/\{id\}/, id), token);
};

export const downloadScopes = async (pageNumber, pageSize, orderBy, searchText, filterString = '', token) => {
  const url = buildQueryParams(REST_URL_SCOPES.concat(filterString), pageNumber, pageSize, orderBy,
    searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addScope = async (name, description, serviceId, token) => {
  const body = { name, description, serviceId };
  await sendPostRequest(REST_URL_ADD_EDIT_SCOPE, body, token);
};

export const editScope = async (id, name, description, serviceId, token) => {
  const body = {
    id, name, description, serviceId,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SCOPE, body, token);
};

export const downloadScope = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SCOPE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteScope = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SCOPE.replace(/\{id\}/, id), token);
};

export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};

export const debounceSearch = (func) => _.debounce(func, DEBOUNCED_SEARCH_DURATION);

export const downloadBanners = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_BANNERS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = transformMediaData(response.data);
  return transformQueryResult(response, transformData);
};

export const addBanner = async (body, token) => {
  await sendPostRequest(REST_URL_ADD_EDIT_BANNER, body, token);
};

export const editBanner = async (id, bodyRequest, token) => {
  const body = { id, ...bodyRequest };
  await sendPutRequest(REST_URL_ADD_EDIT_BANNER, body, token);
};

export const downloadBanner = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_BANNER.replace(/\{id\}/, id), token);
  const transformData = transformMediaData(response);
  return transformData;
};

export const deleteBanner = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_BANNER.replace(/\{id\}/, id), token);
};

export const getChartPercentage = (data, argumentField, valueField) => {
  const totalCount = Object.values(data).reduce((prev, current) => prev + current[valueField], 0);
  const result = Object.values(data).map((item) => ({
    ...item,
    [argumentField]: `${item[argumentField]}: ${Math.round((item[valueField] / totalCount) * 100)}%`,
  }));
  return result;
};

export const removeBase64StringPrefix = (base64String) => base64String.split(',')[1];

const getImageWithPrefix = (image) => {
  const isImageStartsWithPrefix = image.startsWith(BASE_64_PREFIX);
  const imageWithPrefix = !isImageStartsWithPrefix ? `${BASE_64_PREFIX}${image}` : image;
  return imageWithPrefix;
};

export const getImageValue = (value) => {
  const isImageTypeString = typeof value === 'string';
  if (isImageTypeString) {
    const isValueExternalLink = value.startsWith('https://');
    const newValue = isValueExternalLink ? value : getImageWithPrefix(value);
    return newValue;
  }
  return value;
};

export const downloadAppVersions = async (searchText, token) => {
  const url = REST_URL_APP_VERSIONS.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadDeviceManufacturers = async (searchText, token) => {
  const url = REST_URL_DEVICE_MANUFACTURERS.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadDeviceModels = async (searchText, token) => {
  const url = REST_URL_DEVICE_MODELS.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadOsNames = async (searchText, token) => {
  const url = REST_URL_OS_NAMES.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadOsVersions = async (searchText, token) => {
  const url = REST_URL_OS_VERSIONS.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return response;
};

export const downloadTimeZones = async (searchText, token) => {
  const url = REST_URL_TIME_ZONES.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return response;
};

export const sendNotification = async (recipientType, filteredRecipients, title,
  notificationBody, Url, token) => {
  const body = {
    recipientType,
    filteredRecipients,
    title,
    body: notificationBody,
    Url,
  };
  await sendPostRequest(REST_URL_SEND_NOTIFICATION, body, token);
};

export const convertClockInOutHoursToMinutes = (offset, initialTime, mode) => {
  if (offset) {
    const today = `${toMoment().format(REVERSED_ISO_DATE_FORMAT)}T${initialTime}`;

    switch (mode) {
      case TIME_MODE_CLOCK_IN:
        return moment(today).subtract(Math.abs(offset), 'minutes').format(TIME_FORMAT);
      case TIME_MODE_CLOCK_OUT:
        return moment(today).add(Math.abs(offset), 'minutes').format(TIME_FORMAT);
      default: return null;
    }
  } else {
    return null;
  }
};

export const convertClockInOutMinutesToHours = (offset, startWorkingHour, endWorkingHour, mode) => {
  if (offset) {
    const today = moment().format(REVERSED_ISO_DATE_FORMAT);
    const yesterday = moment().subtract(1, 'days').format(REVERSED_ISO_DATE_FORMAT);
    const tomorrow = moment().add(1, 'days').format(REVERSED_ISO_DATE_FORMAT);

    let clockInTime;
    let clockOutTime;
    let earliestClockInTime;
    let autoClockOutTime;

    if (moment(startWorkingHour, TIME_FORMAT).isBefore(moment(endWorkingHour, TIME_FORMAT))) {
      clockInTime = `${today}T${startWorkingHour}`;
      clockOutTime = `${today}T${endWorkingHour}`;

      if (mode === TIME_MODE_CLOCK_IN) {
        earliestClockInTime = moment(offset, TIME_FORMAT).isAfter(moment(startWorkingHour,
          TIME_FORMAT)) ? `${yesterday}${offset}` : `${today}${offset}`;
      } else {
        autoClockOutTime = moment(offset, TIME_FORMAT).isAfter(moment(endWorkingHour,
          TIME_FORMAT)) ? `${today}${offset}` : `${tomorrow}${offset}`;
      }
    } else {
      clockInTime = `${today}T${startWorkingHour}`;
      clockOutTime = `${tomorrow}T${endWorkingHour}`;

      if (mode === TIME_MODE_CLOCK_IN) {
        earliestClockInTime = `${today}${offset}`;
      } else {
        autoClockOutTime = `${tomorrow}${offset}`;
      }
    }

    const initialTime = mode === TIME_MODE_CLOCK_IN ? clockInTime : clockOutTime;
    const offsetTime = mode === TIME_MODE_CLOCK_IN ? earliestClockInTime : autoClockOutTime;

    return moment.duration(moment(offsetTime, REVERSED_ISO_DATE_TIME_FORMAT)
      .diff(moment(initialTime, REVERSED_ISO_DATE_TIME_FORMAT))).asMinutes();
  }
  return null;
};

export const downloadProfilePictures = async (list, token) => {
  const idParameters = list.reduce((prev, current) => (prev ? `${prev}&${URL_ID_TAG}${current}`
    : `${URL_ID_TAG}${current}`), '');
  const url = `${REST_URL_DOWNLOAD_PROFILE_PICTURES}${idParameters}`;
  const profilePictures = await sendGetRequest(url, token);
  const profilePicturesWithPath = profilePictures.map((item) => ({
    ...item,
    profilePicture: item.profilePicture ? REST_BASE_URL + REST_URL_IMAGE_STORAGE + item.profilePicture : '',
  }));
  return convertArrToObj(profilePicturesWithPath, 'id');
};


export const downloadDistricts = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_DISTRICTS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addDistrict = async (name, cityId, code, token) => {
  const body = { name, cityId, code };
  await sendPostRequest(REST_URL_ADD_EDIT_DISTRICT, body, token);
};

export const editDistrict = async (id, name, cityId, code, token) => {
  const body = {
    id, name, cityId, code,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_DISTRICT, body, token);
};

export const downloadDistrict = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_DISTRICT.replace(/\{id\}/, id), token);
  return response;
};

export const deleteDistrict = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_DISTRICT.replace(/\{id\}/, id), token);
};

export const enableDisableDistrict = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_DISTRICT, body, token);
};

export const saveDistricts = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_DISTRICTS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const downloadSubdistricts = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_SUBDISTRICTS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addSubdistrict = async (name, districtId, code, token) => {
  const body = { name, districtId, code };
  await sendPostRequest(REST_URL_ADD_EDIT_SUBDISTRICT, body, token);
};

export const editSubdistrict = async (id, name, districtId, code, token) => {
  const body = {
    id, name, districtId, code,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_SUBDISTRICT, body, token);
};

export const downloadSubdistrict = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_SUBDISTRICT.replace(/\{id\}/, id), token);
  return response;
};

export const deleteSubdistrict = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_SUBDISTRICT.replace(/\{id\}/, id), token);
};

export const enableDisableSubdistrict = async (id, status, token) => {
  const body = { id, status };
  await sendPutRequest(REST_URL_ENABLE_DISABLE_SUBDISTRICT, body, token);
};

export const saveSubdistricts = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const downloadUrl = REST_URL_SUBDISTRICTS.replace(/\?/, REST_URL_SAVE_FILE);
  const url = filterString
    ? buildQueryParams(downloadUrl.concat(filterString), pageNumber, pageSize, orderBy,
      searchText)
    : buildQueryParams(downloadUrl, pageNumber, pageSize, orderBy, searchText);
  const response = await sendGetFileRequest(url, token);
  return response;
};

export const transformMedia = (data) => data.map((x) => {
  if (Object.prototype.hasOwnProperty.call(x, 'type')) {
    return ({
      id: x.id || '',
      order: toNumber(x.order),
      type: x.type,
      url: x.type === 'Youtube' ? x.path : '',
    });
  }
  return ({
    order: toNumber(x.order),
    type: Object.prototype.hasOwnProperty.call(x, 'content') ? x.content.type : x.link.type,
    url: Object.prototype.hasOwnProperty.call(x, 'link') && x.link.type === 'Youtube' ? x.link.path : '',
    image: Object.prototype.hasOwnProperty.call(x, 'content') && x.content.type === 'Image' ? x.content.path : '',
  });
});

export const resyncMasterDataIndividually = async (entityName, entityId, token) => {
  const body = [{ entityName, entityIds: [entityId] }];
  await sendPostRequest(REST_URL_RESYNC_MASTER_DATA_INDIVIDUALLY, body, token);
};

export const formatLabelToTitle = (label) => {
  const start = label.charAt(0).toUpperCase();
  const rest = label.substr(1).replace(/([A-Z])/gm, ' $1');
  return `${start}${rest}`;
};

export const formatTitleToLabel = (label) => {
  const start = label.charAt(0).toLowerCase();
  const rest = label.substr(1).replace(/\s/gm, '');
  return `${start}${rest}`;
};
