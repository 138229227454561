import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    configurationMenu: {
      title: 'Configuration',
    },
    configGroupScreen: {
      title: 'Config Group',

      labelNo: 'No',
      labelConfigGroupName: 'Config Group Name',
      labelOrder: 'Order',
      labelDescription: 'Description',

      placeholderConfigGroupName: 'Config Group Name',
      placeholderOrder: 'Order',
      placeholderTag: 'Tag',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewConfigGroup: 'Create New Config Group',
      buttonCaptionDeleteConfigGroup: 'Delete Config Group ',
      buttonCaptionEditConfigGroup: 'Edit Config Group ',
    },
    configItemScreen: {
      title: 'Config Item',

      labelNo: 'No',
      labelConfigItemName: 'Name',
      labelGroup: 'Group',
      labelValue: 'Value',
      labelOrder: 'Order',
      labelDescription: 'Description',
      labelDataType: 'Data Type',
      labelConfigGroup: 'Config Group',
      labelEnumInputTips: 'Write each value separated by |',
      labelUsedOn: 'Used On',
      labelNeedRestart: 'Need Restart',

      placeholderName: 'Name',
      placeholderOrder: 'Order',
      placeholderLabel: 'Label',
      placeholderValue: 'Value',
      placeholderDescription: 'Description',
      placeholderAllowOverride: 'Allow Override',
      placeholderEncrypted: 'Encrypted',
      placeholderNeedRestart: 'Need Restart',
      placeholderDataType: 'Data Type',
      placeholderEnumOption: 'Enum Option',
      placeholderUsedOn: 'Used On',
      placeholderConfigGroup: 'Config Group',
      placeholderConfigSection: 'Config Section',
      placeholderMultiline: 'Multiline',

      buttonCaptionCreateNewConfigItem: 'Create New Config Item',
      buttonCaptionDeleteConfigItem: 'Delete Config Item ',
      buttonCaptionEditConfigItem: 'Edit Config Item ',
      buttonCaptionDuplicateConfigItem: 'Duplicate Config Item',
      buttonCaptionDuplicate: 'Duplicate',
    },
    configValueScreen: {
      title: 'Config Value',

      labelFilterConfigGroup: 'Filter Config Group',
      labelFilterBy: 'Filter By',
      labelCopied: 'Copied!',

      helperLabelNeedRestart: 'Need Restart',
    },
    configActionScreen: {
      title: 'Config Action',

      labelResyncConfigs: 'Resync config values from \'config\' service to to all services',
      labelResyncUsers: 'Resync user data from \'auth\' service to other services that subscribe to user events',
      labelResyncMasterData: 'Resync master data from \'mdata\' service to other services that subscribe to master data events',
      labelResyncDBUsers: 'Reread user info from integration table on the database',
      labelCity: 'City',
      labelProvince: 'Province',
      labelBranch: 'Branch',
      labelDistrict: 'District',
      labelSubDistrict: 'SubDistrict',
      labelCompany: 'Company',
      labelProfile: 'Profile',
      labelAddress: 'Address',

      buttonCaptionResyncConfigs: 'Resync Configs',
      buttonCaptionResyncUsers: 'Resync Users',
      buttonCaptionResyncMasterData: 'Resync Master Data',
      buttonCaptionResync: 'Resync',
      buttonCaptionResyncDBUsers: 'Resync DB Users',

      placebolderMasterData: 'Master Data',

      msgResyncConfirmation: 'Are you sure want to resync this item?',
    },
  },
  id: {
    configurationMenu: {
      title: 'Konfigurasi',
    },
    configGroupScreen: {
      title: 'Konfigurasi Grup',

      labelNo: 'No',
      labelConfigGroupName: 'Nama Konfigurasi Grup',
      labelOrder: 'Urutan',
      labelDescription: 'Deskripsi',

      placeholderConfigGroupName: 'Nama Konfigurasi Grup',
      placeholderOrder: 'Urutan',
      placeholderTag: 'Tag',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNewConfigGroup: 'Buat Konfigurasi Grup Baru',
      buttonCaptionDeleteConfigGroup: 'Hapus Konfigurasi Grup',
      buttonCaptionEditConfigGroup: 'Ubah Konfigurasi Grup',
    },
    configItemScreen: {
      title: 'Konfigurasi Item',

      labelNo: 'No',
      labelConfigItemName: 'Nama',
      labelGroup: 'Grup',
      labelValue: 'Nilai',
      labelOrder: 'Urutan',
      labelDescription: 'Deskripsi',
      labelDataType: 'Tipe Data',
      labelConfigGroup: 'Konfigurasi Grup',
      labelEnumInputTips: 'Tulis setiap nilai dipisahkan dengan |',
      labelUsedOn: 'Digunakan Pada',
      labelNeedRestart: 'Perlu Mulai Ulang',

      placeholderName: 'Nama',
      placeholderOrder: 'Urutan',
      placeholderLabel: 'Label',
      placeholderValue: 'Nilai',
      placeholderDescription: 'Deskripsi',
      placeholderAllowOverride: 'Izinkan Timpa',
      placeholderEncrypted: 'Dienkripsi',
      placeholderNeedRestart: 'Perlu Mulai Ulang',
      placeholderDataType: 'Tipe Data',
      placeholderEnumOption: 'Opsi Enum',
      placeholderUsedOn: 'Digunakan pada',
      placeholderConfigGroup: 'Konfigurasi Grup',
      placeholderConfigSection: 'Bagian Konfigurasi',
      placeholderMultiline: 'Multiline',

      buttonCaptionCreateNewConfigItem: 'Buat Konfigurasi Item Baru',
      buttonCaptionDeleteConfigItem: 'Hapus Konfigurasi Item',
      buttonCaptionEditConfigItem: 'Ubah Konfigurasi Item',
      buttonCaptionDuplicateConfigItem: 'Duplikasi Konfigurasi Item',
      buttonCaptionDuplicate: 'Duplikasi',
    },
    configValueScreen: {
      title: 'Konfigurasi Nilai',

      labelFilterConfigGroup: 'Saring Konfigurasi Grup',
      labelFilterBy: 'Saring Dengan',
      labelCopied: 'Tersalin!',

      helperLabelNeedRestart: 'Butuh Mulai Ulang',
    },
    configActionScreen: {
      title: 'Konfigurasi Aksi',

      labelResyncConfigs: 'Sikronisasi ulang nilai konfigurasi dari layanan \'Konfigurasi\' untuk semua layanan',
      labelResyncUsers: 'Sikronisasi ulang data pengguna dari layanan \'Autentikasi\' untuk layanan lain yang menggunakan data pengguna',
      labelResyncMasterData: 'Sikronisasi ulang data master dari layanan \'Mdata\' untuk layanan lain yang menggunakan data master',
      labelResyncDBUsers: 'Membaca ulang info pengguna dari tabel integrasi dalam basis data',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelBranch: 'Cabang',
      labelDistrict: 'Kecamatan',
      labelSubDistrict: 'Kelurahan',
      labelCompany: 'Perusahaan',
      labelProfile: 'Profil',
      labelAddress: 'Alamat',

      buttonCaptionResyncConfigs: 'Sinkronisasi Ulang Konfigurasi',
      buttonCaptionResyncUsers: 'Sinkronisasi Ulang Pengguna',
      buttonCaptionResyncMasterData: 'Sinkronisasi Ulang Data Master',
      buttonCaptionResync: 'Sinkronisasi Ulang',
      buttonCaptionResyncDBUsers: 'Sinkronisasi Ulang DB Pengguna',

      placebolderMasterData: 'Data Master',

      msgResyncConfirmation: 'Apa Anda yakin ingin mensinkronisasi ulang pilihan ini?',
    },
  },
});

export default LocalizedString;
