import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, makeStyles, TextField, InputAdornment, IconButton,
} from '@material-ui/core';
import { Lightbulb } from 'mdi-material-ui';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  COLOR_BODY_TEXT, COLOR_TEXT_03, COLOR_PRIMARY, COLOR_TERTIARY,
} from '../constant';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  helperContainer: {
    margin: '10px 0px 10px 0px',
  },
  rowHelperContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  goldText: {
    fontWeight: 'bold',
    color: COLOR_TERTIARY,
  },
  blackText: {
    color: COLOR_BODY_TEXT,
    marginLeft: 38,
  },
  greenText: {
    fontStyle: 'italic',
    color: COLOR_TEXT_03,
  },
}));

const OutlinedTextField = ({
  editable, error, hidden, secureTextEntry, useHelperIcon,
  onChangeText,
  label, helperText, placeholder, secondaryHelperText, subHelperText, value,
  ...props
}) => {
  const classes = useStyles();
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderTextFieldWithPassIcon = (
    <TextField
      label={label}
      disabled={editable}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={(text) => onChangeText(text)}
      fullWidth
      type={passwordVisibility ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {passwordVisibility ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          {secureTextEntry ? renderTextFieldWithPassIcon : (
            <TextField
              label={label}
              disabled={editable}
              placeholder={placeholder}
              variant="outlined"
              value={value}
              onChange={(text) => onChangeText(text)}
              fullWidth
              {...props}
            />
          )}

          {useHelperIcon ? (
            <div className={classes.helperContainer}>
              <FormHelperText className={classes.greenText}>{helperText}</FormHelperText>
              <div className={classes.rowHelperContainer}>
                <Lightbulb style={{ color: COLOR_TERTIARY }} />
                <FormHelperText className={classes.goldText}>{secondaryHelperText}</FormHelperText>
              </div>
              <FormHelperText className={classes.blackText}>{subHelperText}</FormHelperText>
            </div>
          )
            : (<FormHelperText className={classes.greenText}>{helperText}</FormHelperText>)}
        </FormControl>
      </div>
    );
  }
  return null;
};

export default OutlinedTextField;

OutlinedTextField.propTypes = {
  editable: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  secureTextEntry: PropTypes.bool,
  useHelperIcon: PropTypes.bool,
  onChangeText: PropTypes.func,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryHelperText: PropTypes.string,
  subHelperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

OutlinedTextField.defaultProps = {
  editable: false,
  error: false,
  hidden: false,
  secureTextEntry: false,
  useHelperIcon: false,
  onChangeText: () => {},
  helperText: null,
  placeholder: null,
  secondaryHelperText: null,
  subHelperText: null,
  value: null,
};
